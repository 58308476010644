import React from 'react';
import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import Select from '@mui/material/Select';
import { TiArrowSortedDown } from 'react-icons/ti';
import { COLORS } from '../../../theme';
import { FormFieldContainer } from '../form-field-container';

const ArrowIcon: React.FC<any> = React.forwardRef((props, ref) => (
	<TiArrowSortedDown
		size={24}
		color={COLORS.deepViolet}
		style={{ marginRight: '9px', top: '14px' }}
		{...props}
		ref={ref}
	/>
));

const StandardSelect = React.forwardRef((props, ref) => (
	<Select variant="standard" disableUnderline IconComponent={ArrowIcon} {...props} ref={ref} />
));

export const SelectContainer = styled(FormFieldContainer)<{
	disabled?: boolean | undefined;
	error?: boolean;
	theme?: Theme;
}>`
	position: relative;
	min-height: 50px;
	margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const SelectField = styled(StandardSelect)<{ theme?: Theme }>`
	background-color: transparent;
	min-width: 398px;
	margin-left: 1px;
	align-items: center;
	cursor: pointer;
	font-size: 17px;
	font-weight: normal;
	font-family: 'IBM Plex Sans', sans-serif;
	& .MuiSelect-select {
		margin-top: ${({ theme }) => theme.spacing(2)};
		padding-left: ${({ theme }) => theme.spacing(1.5)};
		margin-bottom: ${({ theme }) => theme.spacing(0.75)};
		min-height: 30px !important;
		align-items: center;
	}
`;

export const SelectWrapper = styled(Grid)<{ theme?: Theme }>`
	bottom: ${({ theme }) => theme.spacing(5)};
`;

export const Styled = {
	SelectWrapper,
	SelectContainer,
	Select,
	Option,
	SelectField
};
