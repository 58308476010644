import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { Grid } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { HexagonBtn, FormInput } from '../../../common/components';
import { EmailValidationSchema } from '../utils/validation.util';
import { toastSuccess } from '../../../common/components/toasts/toasts.component';
import StyledPreloader from '../../../common/components/preloader/preloader';
import { firebaseAuthService } from '../../../common/services/firebase-auth.service';
import { customErrorHandler } from '../../../common/utils/custom-error-handler.util';

export const EnterEmail: React.FC = () => {
	const { mutate, isLoading } = useMutation<any, AxiosError, string>(
		(req) => firebaseAuthService.sendEmailToResetPassword(req),
		{
			onSuccess: () => {
				toastSuccess(
					'An email has been sent to the address specified, if it belongs to a valid user.'
				);
			},
			onError: () => {
				toastSuccess(
					'An email has been sent to the address specified, if it belongs to a valid user.'
				);
			}
		}
	);

	const formik = useFormik({
		initialValues: { email: '' },
		onSubmit: ({ email }) => {
			mutate(email);
		},
		validationSchema: EmailValidationSchema
	});

	const isValidButton = !Object.keys(formik.errors).length && !!formik.values.email;

	return (
		<FormikProvider value={formik}>
			{isLoading ? (
				<StyledPreloader />
			) : (
				<Form onSubmit={formik.handleSubmit}>
					<FormInput
						name="email"
						type="email"
						passedValue=""
						placeholder="Enter your email"
						label="Email"
					/>
					<Grid container alignItems="center" justifyContent="center" mt="1.25rem" mb="0.75rem">
						<HexagonBtn
							title="Send a link"
							variant="filled"
							width="14rem"
							disabled={!isValidButton}
						/>
					</Grid>
				</Form>
			)}
		</FormikProvider>
	);
};
