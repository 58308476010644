import React, {
	createContext,
	ReactChild,
	ReactChildren,
	useContext,
	useMemo,
	useState
} from 'react';
import { localStorageService } from '../common/services/local-storage.service';
import { ETheme } from '../common/types/main-container.types';

interface IContextValue {
	theme: ETheme;
	onSetTheme: (isDark: boolean) => void;
}

const ThemeContext = createContext<IContextValue>({} as IContextValue);

interface IThemeContext {
	children: ReactChild | ReactChildren;
}

export const ThemeContextProvider: React.FC<IThemeContext> = ({ children }) => {
	const [theme, setTheme] = useState(localStorageService.getThemeFromStorage() || ETheme.LIGHT);
	const onSetTheme = (isDark: boolean) => {
		if (isDark) {
			setTheme(ETheme.DARK);
			localStorageService.setThemeToStorage(ETheme.DARK);
		} else {
			setTheme(ETheme.LIGHT);
			localStorageService.setThemeToStorage(ETheme.LIGHT);
		}
	};

	const value = useMemo(
		() => ({
			theme,
			onSetTheme
		}),
		[theme, onSetTheme]
	);

	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useThemeData = () => useContext(ThemeContext);
