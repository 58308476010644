export const CopyIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17.953"
		height="17.377"
		viewBox="0 0 17.953 17.377"
	>
		<defs>
			<clipPath id="clip-path">
				<rect
					id="Rectangle_1070"
					data-name="Rectangle 1070"
					width="17.953"
					height="17.376"
					transform="translate(0 0)"
					fill="#6c0c8b"
					stroke="#6c0c8b"
					strokeWidth="1"
				/>
			</clipPath>
		</defs>
		<g id="ic-copy" transform="translate(0 0.001)">
			<g id="Group_2271" data-name="Group 2271" transform="translate(0 0)">
				<g id="Group_2270" data-name="Group 2270" clipPath="url(#clip-path)">
					<rect
						id="Rectangle_1069"
						data-name="Rectangle 1069"
						width="12"
						height="12"
						transform="translate(5.453 4.876)"
						fill="none"
						stroke="#6c0c8b"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</g>
			</g>
			<path
				id="Path_1332"
				data-name="Path 1332"
				d="M.5.5v12H5.453V4.877H12.5V.5Z"
				transform="translate(0 0)"
				fill="#6c0c8b"
				stroke="#6c0c8b"
				strokeWidth="1"
			/>
			<g id="Group_2273" data-name="Group 2273" transform="translate(0 0)">
				<g id="Group_2272" data-name="Group 2272" clipPath="url(#clip-path)">
					<path
						id="Path_1333"
						data-name="Path 1333"
						d="M5.453,12.5H.5V.5h12V4.876H5.453Z"
						fill="#6c0c8b"
						stroke="#6c0c8b"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
				</g>
			</g>
		</g>
	</svg>
);
