import React, {
	createContext,
	Dispatch,
	ReactChild,
	ReactChildren,
	useContext,
	useMemo,
	useState
} from 'react';
import { EType } from '../../services/trusted-list.types';

export interface ITrustedData {
	type: EType | null;
	url: string;
}

interface IContextValue {
	scamToast: string;
	trustedToast: ITrustedData;
	setScamToast: Dispatch<React.SetStateAction<string>>;
	setTrustedToast: Dispatch<React.SetStateAction<ITrustedData>>;
}

const ToastContext = createContext<IContextValue>({} as IContextValue);

interface IThemeContext {
	children: ReactChild | ReactChildren;
}

export const ToastContextProvider: React.FC<IThemeContext> = ({ children }) => {
	const [scamToast, setScamToast] = useState('');
	const [trustedToast, setTrustedToast] = useState<ITrustedData>({
		type: null,
		url: ''
	});

	const value = useMemo(
		() => ({
			scamToast,
			trustedToast,
			setScamToast,
			setTrustedToast
		}),
		[scamToast, trustedToast, setScamToast, setTrustedToast]
	);

	return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export const useToastData = () => useContext(ToastContext);
