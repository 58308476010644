export const ConfirmIcon = () => (
	<svg
		id="ic-check-in-circle"
		xmlns="http://www.w3.org/2000/svg"
		width="23"
		height="23"
		viewBox="0 0 23 23"
	>
		<circle id="Ellipse_16" data-name="Ellipse 16" cx="11.5" cy="11.5" r="11.5" fill="#20c064" />
		<path
			id="Path_1341"
			data-name="Path 1341"
			d="M7725.119-22236.67l3.5,2.93,5.775-6.187"
			transform="translate(-7718.257 22248.334)"
			fill="none"
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
		/>
	</svg>
);
