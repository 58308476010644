export enum EType {
	WEBSITE = 'domain',
	TWITTER = 'twitter',
	FACEBOOK = 'facebook',
	YOUTUBE = 'youtube',
	LINKEDIN = 'linkedin'
}

export const options = [
	{ value: EType.WEBSITE, label: 'Website' },
	{ value: EType.TWITTER, label: 'Twitter Account' },
	{ value: EType.FACEBOOK, label: 'Facebook Account, Page, or Group' },
	{ value: EType.YOUTUBE, label: 'Youtube Account' },
	{ value: EType.LINKEDIN, label: 'LinkedIn Account' }
];

export interface ITrustedList {
	id: string;
	url: string;
	createdAt: Date;
	type: EType;
	owner: string;
	comment?: string;
}

export interface Iuuid {
	id: string;
}

export interface ICreateTrustedItem {
	url: string;
	type: EType;
	comment?: string;
}

export interface IWhiteList {
	type: EType;
	url: string;
}
