import React from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { IconContainer, ListItem, ListTitle } from './welcome.styled';
import { ConfirmIcon } from '../../common/components/icons/confirm-icon';
import { XIcon } from '../../common/components/icons/x-icon';
import { HexagonBtn } from '../../common/components';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import { WelcomeBottomLinks } from '../../common/components/auth-wrapper/components/bottom-links/welcome-bottom-links.component';
import { APP_KEYS } from '../../common/consts';
import { localStorageService } from '../../common/services/local-storage.service';
import { profileService } from '../../profile/services/profile.service';
import { IUserInfo } from '../../profile/services/profile.types';
import { customErrorHandler } from '../../common/utils/custom-error-handler.util';

const Welcome = () => {
	const { push } = useHistory();

	const { mutate: updateUser } = useMutation<any, AxiosError, boolean>(
		(req: boolean) => profileService.updateUserProfile({ isSharingData: req } as IUserInfo),
		{
			onSuccess: () => {},
			onError: customErrorHandler
		}
	);

	const handleClick = (isSharingData: boolean) => {
		const token = localStorageService.getTokenFromStorage();
		if (token) {
			updateUser(isSharingData);
		} else {
			localStorageService.setSharingDataToStorage(isSharingData);
		}
		push(APP_KEYS.ROUTER_KEYS.USER_GUIDE + '?popover=true');
	};

	return (
		<AuthPageWrapper footerContent={<WelcomeBottomLinks />} filled>
			<AuthPageActionCardWrapper
				title="Help improve Nighthawk"
				subText="Share your usage data with us to help us better serve you and other users.
            Your data will be used to continuously improve the user experience of Nighthawk
            and develop more solutions to keep users safe online."
			>
				<>
					<Grid container direction="column">
						<Grid item container spacing={4} p="1.75rem 0rem">
							<Grid item xs={6}>
								<ListTitle>Nighthawk will:</ListTitle>
								<ListItem>
									<IconContainer>
										<ConfirmIcon />
									</IconContainer>
									Always allow you to opt-out of sharing usage data
								</ListItem>
								<ListItem>
									<IconContainer>
										<ConfirmIcon />
									</IconContainer>
									Send anonymized click & pageview events
								</ListItem>
							</Grid>
							<Grid item xs={6}>
								<ListTitle>Nighthawk will never:</ListTitle>
								<ListItem>
									<IconContainer>
										<XIcon />
									</IconContainer>
									Collect keys, addresses, transactions, balances, hashes, or any personal
									information
								</ListItem>
								<ListItem>
									<IconContainer>
										<XIcon />
									</IconContainer>
									Collect your full IP address
								</ListItem>
								<ListItem>
									<IconContainer>
										<XIcon />
									</IconContainer>
									Sell data for profit
								</ListItem>
							</Grid>
						</Grid>
						<Grid item container direction="column" alignItems="center">
							<HexagonBtn
								title="Happy to Help! I Agree."
								width="276px"
								variant="filled"
								click={() => handleClick(true)}
							/>
							<HexagonBtn
								title="No Thanks"
								width="276px"
								variant="outlined"
								click={() => handleClick(false)}
							/>
						</Grid>
					</Grid>
				</>
			</AuthPageActionCardWrapper>
		</AuthPageWrapper>
	);
};

export default Welcome;
