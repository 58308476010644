import toast from 'react-hot-toast';
import { StyledContainer, StyledClose, StyledText } from './toast.styled';

export const toastSuccess = (text: string) => {
	toast.success(text, {
		position: 'top-right'
	});
};

export const toastError = (text: string) => {
	toast.error(
		(t) => (
			<StyledContainer>
				<StyledClose onClick={() => toast.dismiss(t.id)} />
				<StyledText>{text}</StyledText>
			</StyledContainer>
		),
		{
			position: 'top-right',
			duration: 10000
		}
	);
};
