import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { TiArrowSortedDown } from 'react-icons/ti';
import { COLORS } from '../../../theme';
import { Panel1 } from './panel1';
import { Panel5 } from './panel5';
import { useThemeData } from '../../../context/theme.context';
import { ETheme } from '../../../common/types/main-container.types';
import * as GlobalTypography from '../../../common/components/global-typography';

export const ControlledAccordions = () => {
	const { theme } = useThemeData();
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const isDark = theme === ETheme.DARK;

	return (
		<div>
			<Accordion
				expanded={expanded === 'panel1'}
				onChange={handleChange('panel1')}
				sx={{ background: 'transparent' }}
				square
			>
				<AccordionSummary
					expandIcon={
						<TiArrowSortedDown size={28} color={isDark ? COLORS.white : COLORS.deepViolet} />
					}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						How does NightHawk work?
					</GlobalTypography.Text>
				</AccordionSummary>
				<AccordionDetails>
					<Panel1 />
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel2'}
				onChange={handleChange('panel2')}
				sx={{ background: 'transparent' }}
			>
				<AccordionSummary
					expandIcon={
						<TiArrowSortedDown size={28} color={isDark ? COLORS.white : COLORS.deepViolet} />
					}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
				>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						Does NightHawk collect my data?
					</GlobalTypography.Text>
				</AccordionSummary>
				<AccordionDetails>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" pr="3rem">
						NightHawk does NOT collect any of your cookies or browsing data. We respect the privacy
						of our users and give them a choice to share their usage data with us to help improve
						the user experience of our tools and help keep more people safe online. If you want to
						adjust your sharing preferences, navigate to Your Account and click on the toggle.
					</GlobalTypography.Text>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel3'}
				onChange={handleChange('panel3')}
				sx={{ background: 'transparent' }}
			>
				<AccordionSummary
					expandIcon={
						<TiArrowSortedDown size={28} color={isDark ? COLORS.white : COLORS.deepViolet} />
					}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
				>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						What are Guardians?
					</GlobalTypography.Text>
				</AccordionSummary>
				<AccordionDetails>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" pr="3rem">
						NightHawk strives to make online browsing safer for the crypto community. Our
						proprietary AI constantly scans thousands of links, but frankly, there are thousands of
						new phishing URLs created daily. By reporting a scam, malicious link, or suspicious
						social media account - you become a Guardian. For each report, we allocate one Guardian
						Point, which you can track in Your Account. If you feel you are on a suspicious URL or
						Social Media account, please, report it to our analyst team through the Report a Scam
						form.
					</GlobalTypography.Text>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel4'}
				onChange={handleChange('panel4')}
				sx={{ background: 'transparent' }}
			>
				<AccordionSummary
					expandIcon={
						<TiArrowSortedDown size={28} color={isDark ? COLORS.white : COLORS.deepViolet} />
					}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
				>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						How and why are sites and accounts marked as safe or unsafe?
					</GlobalTypography.Text>
				</AccordionSummary>
				<AccordionDetails>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" pr="3rem">
						With the help of NightHawk’s real-time phishing intelligence engine, our analyst’s team
						can update the Trusted List and Blacklist. If malicious activity is detected, the
						website or social media account is marked as DANGEROUS and added to Blacklist(Full list
						you can see here: &nbsp;
						<a href="https://github.com/phishfort/phishfort-lists/tree/master/blacklists">
							https://github.com/phishfort/phishfort-lists/tree/master/blacklists
						</a>
						). If a link or social media account is verified, the item gets added to Trusted List
						(Full list you can see here: &nbsp;
						<a href="https://github.com/phishfort/phishfort-lists/tree/master/whitelists">
							https://github.com/phishfort/phishfort-lists/tree/master/whitelists
						</a>
						).
					</GlobalTypography.Text>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === 'panel5'}
				onChange={handleChange('panel5')}
				sx={{ background: 'transparent' }}
			>
				<AccordionSummary
					expandIcon={
						<TiArrowSortedDown size={28} color={isDark ? COLORS.white : COLORS.deepViolet} />
					}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
				>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						What are NightHawk’s mission and values?
					</GlobalTypography.Text>
				</AccordionSummary>
				<AccordionDetails>
					<Panel5 />
				</AccordionDetails>
			</Accordion>
		</div>
	);
};
