import { APP_KEYS } from '../common/consts';

export interface ILinks {
	label: string;
	title: string;
	link?: string;
	clickHandler?: string;
}

export const links = [
	{ label: 'Password', title: 'Update password', link: APP_KEYS.ROUTER_KEYS.RESET }
];

export const guestLinks = [
	{
		label: 'Username',
		title: 'Create an account to claim a Username',
		clickHandler: 'handleSignUp'
	},
	{
		label: 'Email address',
		title: 'Create an account to add an email address',
		clickHandler: 'handleSignUp'
	},
	...links
];
