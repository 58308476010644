import styled from '@emotion/styled';
import { Field } from 'formik';
import { Theme } from '@mui/material';

export const Textarea = styled(Field)`
	outline: none;
	border: 0;
	width: 380px;
	height: auto;
	z-index: 0;
	font-size: 17px;
	font-weight: normal;
	margin-left: 10px;
	padding-top: 13px;
	border: none;
	overflow: hidden;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	resize: none;
	background-color: transparent;
	font-family: 'IBM Plex Sans', sans-serif;

	&::placeholder {
		color: ${({ theme }) => theme.palette.secondary.contrastText};
		opacity: 0.5;
	}

	&:-webkit-autofill {
		-webkit-background-clip: text;
	}
`;

export const ErrorInfoContainer = styled.div<{ errorJustifyContent?: string; theme?: Theme }>`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 420px;
	color: ${({ theme }) => theme.palette.error.main};
	position: relative;
`;

export const ErrorInfoText = styled.div<{ theme?: Theme }>`
	box-sizing: border-box;
	font-size: ${({ theme }) => theme.typography.body2.fontSize};
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	margin-right: ${({ theme }) => theme.spacing(1.25)};
	margin-left: ${({ theme }) => theme.spacing(1.25)};
	font-family: 'IBM Plex Sans', sans-serif;
`;
