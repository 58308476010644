import React from 'react';
import { Grid } from '@mui/material';
import { CopyIcon } from '../common/components/icons/copy-icon';
import qrImg from '../../assets/images/qr-code.png';
import * as Styled from './support.styled';
import { LINK_TEXT } from '../common/consts/app-keys.const';
import { MainWrapper } from '../common/components';
import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import * as GlobalTypography from '../common/components/global-typography';

const Support = () => {
	const handleCopyLink = (text: string) => {
		navigator.clipboard.writeText(text);
	};

	return (
		<MainWrapper>
			<PageWrapper title="Support Nighthawk">
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<GlobalTypography.Text
						variant="subtitle1"
						colorVariant="primary"
						fontWeight="fontWeightBold"
					>
						Send ETH To
					</GlobalTypography.Text>
					<Styled.Image src={qrImg} alt="QR code should be here" />
					<GlobalTypography.Text
						variant="body1"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						0x8717B8d39307929971c19FE9C63f2A4C3998C808
					</GlobalTypography.Text>
					<Styled.CopyLink onClick={() => handleCopyLink(LINK_TEXT)} container>
						<Grid item>
							<CopyIcon />
						</Grid>
						<Grid item>
							<GlobalTypography.Text
								variant="body2"
								colorVariant="primary.dark"
								fontWeight="fontWeightBold"
							>
								&nbsp; Copy ETH Address
							</GlobalTypography.Text>
						</Grid>
					</Styled.CopyLink>
				</Grid>
			</PageWrapper>
		</MainWrapper>
	);
};

export default guestProtectedPage(Support);
