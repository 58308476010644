import styled from 'styled-components';
import { SPACES } from '../../../../theme';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: ${SPACES.l};
`;
