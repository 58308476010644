/* eslint-disable no-useless-escape */
// Local storage keys
import { EType } from '../../../services/trusted-list.types';

export const STORAGE_KEYS = {
	ADDRESS: 'ADDRESS',
	TOKEN: 'TOKEN',
	THEME: 'THEME',
	GUEST_TOKEN: 'GUEST_TOKEN',
	SHARING_DATA: 'SHARING_DATA',
	AUTH_REDIRECT_URL: 'AUTH_REDIRECT_URL',
	GUARDIAN_POINTS: 'GUARDIAN_POINTS'
};

// soc media keys
export const SOC_MEDIA = {
	[EType.YOUTUBE]: 'youtube.com',
	[EType.TWITTER]: 'twitter.com',
	[EType.FACEBOOK]: 'facebook.com',
	[EType.LINKEDIN]: 'linkedin.com'
};

// React-query keys
export const QUERY_KEYS = {
	EXAMPLE: 'EXAMPLE',
	TOKEN: 'TOKEN',
	STATISTIC: 'statistic',
	TRENDING: 'trending'
};

// Backend Routes
export const BACKEND_KEYS = {
	EXAMPLE: 'example',
	COURSES: 'courses',
	ARTICLES: 'articles',
	STATISTIC: 'statistic',
	TRENDING: 'trending',
	FEATURED_ARTICLES: 'featured_articles'
};

export const ROUTER_KEYS = {
	USER_GUIDE: '/',
	SIGN_UP: '/signup',
	SIGN_IN: '/login',
	SIGN_UP_WITH_EMAIL: '/register-email',
	PROFILE: '/profile',
	SUPPORT: '/support',
	SCAM_REPORT: '/scam-report',
	TRUSTED_LIST: '/trusted-list',
	ADD_TRUSTED: '/add-trusted',
	CREATE_PROFILE: '/create-profile',
	RESET: '/reset-password',
	CONNECT_US: '/connect-us',
	WELCOME: '/welcome',
	NIGHTHAWK_TRUSTED_LIST: '/nighthawk-trusted-list',
	TERMS_AND_CONDITIONS: '/terms-and-conditions',
	PRIVACY_POLICY: '/privacy-policy',
	NIGHTHAWK_WARNING: '/warning'
};

export const PASSWORD_MATCH = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&*])(?=.{8,})/;
export const USERNAME_MATCH = /^([a-z0-9!@#\$%\^&*])/;

export const LINK_TEXT = '0xAFA573F3D14862c3D4Eedd4cef38097271950000';
