import { EnhancedWithAuthHttpService, HttpFactoryService } from '../modules/common/services';
import { ReactQueryFactory } from '../modules/common/utils/react-query.util';
import { EType, Iuuid } from './trusted-list.types';
import { INighthawkList } from './nighthawk-list.types';
import axios from 'axios';

class NighthawkListService extends ReactQueryFactory<{}> {
	constructor(private httpService: EnhancedWithAuthHttpService) {
		super('nighthawk-list');
	}

	async onDetailedRequest(): Promise<Iuuid | void | undefined> {
		throw new Error('onDetailedRequest not implemented!');
	}

	async onAllRequest() {
		return await this.httpService.get<INighthawkList[]>('user/greylist');
	}

	async onListRequest({ type }: { type: EType }) {
		const queryString = type ? `?type=${type}` : '';
		const response = await axios.get(process.env.REACT_APP_CDN_URL + queryString, {
			headers: {
				'x-api-key': process.env.REACT_APP_NIGHTHAWK_API_KEY!
			}
		});
		let data: INighthawkList[] = [];

		// format blacklist data as INighthawkList[]
		Object.entries(response.data.blacklist).forEach(([key, value]: any) => {
			value.forEach((item: any) =>
				data.push({
					type: key as EType,
					url: item
				})
			);
		});

		return data;
	}
}

const factory = new HttpFactoryService();
export const nighthawkListService = new NighthawkListService(factory.createAuthHttpService());
