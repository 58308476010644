import React from 'react';
import { Styled } from './form-field.styled';

export interface IFormSelectProps {
	children: React.ReactNode;
	error?: boolean;
	height?: string;
	rows?: number;
	isBig?: boolean;
}

export const FormFieldContainer: React.FC<IFormSelectProps> = ({
	children,
	error,
	height,
	rows,
	isBig
}) => (
	<Styled.HexagonBorder error={error} height={height} rows={rows} isBig={isBig}>
		<span>{children}</span>
	</Styled.HexagonBorder>
);
