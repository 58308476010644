import React from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { HexagonBtn, RedirectButtons } from '../../common/components';
import { firebaseAuthService } from '../../common/services/firebase-auth.service';
import { APP_KEYS } from '../../common/consts';
import userPublicPage from '../../common/hocs/user-public.hoc';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import { BottomLinks } from '../../common/components/auth-wrapper/components/bottom-links';
import { profileService } from '../../profile/services/profile.service';
import { IUserInfo } from '../../profile/services/profile.types';
import { localStorageService } from '../../common/services/local-storage.service';
import { useAuthSyncData } from '../../context/auth-sync.context';
import { GoogleAuthIcon } from '../../common/components/icons/google-auth-icon';
import { TwitterAuthIcon } from '../../common/components/icons/twitter-auth-icon';
import { customErrorHandler } from '../../common/utils/custom-error-handler.util';
import { IUpdateRequest } from '../../../services/user.types';

const SocialAuth = () => {
	const { push } = useHistory();
	const { onSetUserToken, removeGuardianPoints } = useAuthSyncData();

	const { mutate: updateUser } = useMutation<any, AxiosError, IUpdateRequest>(
		(userInfo) => profileService.updateUserProfile(userInfo as IUserInfo),
		{
			onSuccess: () => {
				localStorageService.removeSharingDataFromStorage();
				removeGuardianPoints();
			},
			onError: customErrorHandler
		}
	);

	const onSuccessAuth = (token: string, isNew: boolean) => {
		let args: IUpdateRequest = {};
		const guardianPoints = Number(localStorageService.getGuardianPointsFromStorage()) || 0;
		const isSharingData = localStorageService.getSharingDataFromStorage();
		if (guardianPoints) {
			args = { ...args, guardianPoints };
		}
		if (isSharingData !== null) {
			args = { ...args, isSharingData };
		}
		if (Object.keys(args).length) {
			updateUser(args);
		}
		onSetUserToken(token);
		const nextRoute = isNew
			? APP_KEYS.ROUTER_KEYS.CREATE_PROFILE
			: APP_KEYS.ROUTER_KEYS.TRUSTED_LIST;
		push(nextRoute);
	};

	const { mutate: handleGoogle } = useMutation<any, AxiosError>(
		() => firebaseAuthService.signUpWithGoogle(),
		{
			onSuccess: ({ token, isNew }) => {
				onSuccessAuth(token, isNew);
			},
			onError: customErrorHandler
		}
	);

	const { mutate: handleTwitter } = useMutation<any, AxiosError>(
		() => firebaseAuthService.signUpWithTwitter(),
		{
			onSuccess: ({ token, isNew }) => {
				onSuccessAuth(token, isNew);
			},
			onError: customErrorHandler
		}
	);

	return (
		<AuthPageWrapper footerContent={<BottomLinks />}>
			<AuthPageActionCardWrapper title="Create Account">
				<Grid item container direction="column" alignItems="center">
					<Grid item mt="0.5rem">
						<HexagonBtn
							title="Sign Up With Google"
							width="270px"
							variant="outlined"
							click={handleGoogle}
							icon={
								<Grid item maxHeight="2.75rem" pl="1.25rem" xs={1}>
									<GoogleAuthIcon />
								</Grid>
							}
						/>
					</Grid>
					<Grid item mt="0.5rem">
						<HexagonBtn
							title="Sign Up With Twitter"
							width="270px"
							variant="outlined"
							click={handleTwitter}
							icon={
								<Grid item maxHeight="1.875rem" pl="1rem" xs={1}>
									<TwitterAuthIcon />
								</Grid>
							}
						/>
					</Grid>
					<Grid item mt="0.5rem">
						<HexagonBtn
							title="Sign Up With Email"
							width="273px"
							variant="filled"
							click={() => push(APP_KEYS.ROUTER_KEYS.SIGN_UP_WITH_EMAIL)}
						/>
					</Grid>
					<RedirectButtons isSignUp />
				</Grid>
			</AuthPageActionCardWrapper>
		</AuthPageWrapper>
	);
};

export default userPublicPage(SocialAuth);
