export const lightOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#4d0365',
			dark: '#6C0C8B',
			light: '#BAA6C1',
			contrastText: '#4d0365'
		},
		secondary: {
			main: '#515557',
			dark: '#3C3D3E',
			light: '#989898',
			contrastText: '#515557'
		},
		background: {
			default: '#FFF'
		}
	}
};
