import { EnhancedWithAuthHttpService, HttpFactoryService } from '../modules/common/services';
import { LocalStorageService } from '../modules/common/services/local-storage.service';
import { IReportScam, Iuuid } from './scam.types';

class ScamReportService {
	constructor(
		private httpService: EnhancedWithAuthHttpService,
		private localStorageService: LocalStorageService
	) {}

	async addScamReport(data: IReportScam) {
		const token = this.localStorageService.getTokenFromStorage();

		if (!token) {
			return this.httpService.post<Iuuid, IReportScam>('user/report-list/guest', data);
		}

		return this.httpService.post<Iuuid, IReportScam>('user/report-list', data);
	}

	async addScamReportGuest(data: IReportScam) {
		return this.httpService.post<Iuuid, IReportScam>('user/report-list/guest', data);
	}
}

const factory = new HttpFactoryService();
export const scamReportService = new ScamReportService(
	factory.createAuthHttpService(),
	new LocalStorageService()
);
