import React, { FC } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import * as Styled from './form-textarea.styled';
import { FormFieldContainer } from '../form-field-container';
import * as GlobalTypography from '../global-typography';
import { EType } from '../../../../services/trusted-list.types';

const ROW_LENGTH = 36;
const INIT_FIELD_HEIGHT = 50;
const ROW_HEIGHT = 26;
const ALLOW_ROWS_COUNT = 5;

interface IFormikValue {
	type: EType;
	url: string;
	impersonatedUrl?: string;
	comment: string;
}

export interface IProps {
	name: string;
	type: string;
	placeholder?: string;
	disabled?: boolean | undefined;
	passedValue: string;
	label?: string;
	subLabel?: string;
}

const FormTextarea: FC<IProps> = ({
	name,
	type,
	placeholder,
	disabled,
	passedValue,
	label,
	subLabel
}) => {
	const { values, handleChange, errors, touched, setErrors } = useFormikContext<IFormikValue>();
	const { comment } = values;
	const getRowsCount = (text: string) => {
		const rowsByEnter = text.split('\n').length;
		const rowsByLength = Math.ceil(text.length / ROW_LENGTH);

		return Math.max(rowsByEnter, rowsByLength);
	};
	const rowCounts = getRowsCount(comment);
	const fieldHeight = `${INIT_FIELD_HEIGHT + (rowCounts - 1) * ROW_HEIGHT}px`;
	const maxLength = rowCounts > ALLOW_ROWS_COUNT ? comment.length : ALLOW_ROWS_COUNT * ROW_LENGTH;

	const isLengthErrors = getIn(values, name)?.length >= maxLength;
	const isErrorExists = (getIn(errors, name) && getIn(touched, name)) || isLengthErrors;

	if (isLengthErrors) {
		setErrors({
			...errors,
			[name]: 'Comment is too long'
		});
	}

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" mt="0.5rem">
			<Grid container alignItems="center" pl="0.75rem">
				{label && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary"
						fontWeight="fontWeightBold"
					>
						{label}
					</GlobalTypography.Text>
				)}
				{subLabel && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary"
						fontStyle="italic"
						pl="0.25rem"
					>
						{subLabel}
					</GlobalTypography.Text>
				)}
			</Grid>
			<FormFieldContainer error={isErrorExists} height={fieldHeight} rows={rowCounts} isBig>
				<Styled.Textarea
					as="textarea"
					id={name}
					name={name}
					type={type}
					onChange={handleChange(name)}
					value={getIn(values, name) || passedValue}
					placeholder={placeholder}
					maxLength={maxLength}
					disabled={disabled}
				/>
			</FormFieldContainer>
			{isErrorExists && (
				<Styled.ErrorInfoContainer>
					<Styled.ErrorInfoText>
						{getIn(errors, name) || 'Comment is too long'}
					</Styled.ErrorInfoText>
				</Styled.ErrorInfoContainer>
			)}
		</Grid>
	);
};

export default FormTextarea;
