export const darkOptions = {
	palette: {
		mode: 'dark',
		primary: {
			main: '#FFF',
			dark: '#FFF',
			light: '#BAA6C1',
			contrastText: '#4d0365'
		},
		secondary: {
			main: '#FFF',
			dark: '#FFF',
			light: '#FFF',
			contrastText: '#515557'
		},
		background: {
			default: '#211D22'
		}
	}
};
