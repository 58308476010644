import { GrayIconContainer } from './icon.styled';

export const LinkedinWhiteIcon = () => (
	<GrayIconContainer>
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<defs>
				<clipPath id="clip-path">
					<rect id="Rectangle_1161" data-name="Rectangle 1161" width="20" height="20" fill="#fff" />
				</clipPath>
			</defs>
			<g id="Group_2448" data-name="Group 2448" clipPath="url(#clip-path)">
				<path
					id="Path_1348"
					data-name="Path 1348"
					d="M20.209,1.486V18.723a1.486,1.486,0,0,1-1.486,1.486H1.486A1.486,1.486,0,0,1,0,18.723V1.486A1.486,1.486,0,0,1,1.486,0H18.723a1.486,1.486,0,0,1,1.486,1.486M5.944,7.727H2.972v9.51H5.944Zm.267-3.269a1.712,1.712,0,0,0-1.7-1.724H4.458A1.724,1.724,0,1,0,6.211,4.513h0Zm11.026,7c0-2.859-1.819-3.971-3.626-3.971A3.388,3.388,0,0,0,10.6,9.023h-.083v-1.3H7.727v9.51H10.7V12.179a1.974,1.974,0,0,1,1.783-2.128H12.6c.945,0,1.646.594,1.646,2.092v5.094h2.972Z"
					transform="translate(-0.115 0)"
					fill="#fff"
				/>
			</g>
		</svg>
	</GrayIconContainer>
);
