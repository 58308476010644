import React, { useEffect } from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Styled } from './form-select.styled';
import * as GlobalTypography from '../global-typography';

export interface IFormSelectProps {
	name: string;
	label?: string;
	disabled?: boolean | undefined;
	passedValue: string;
	options: { value: string; label: string }[];
	subLabel?: string;
	onChange?: Function;
}

export const FormSelect: React.FC<IFormSelectProps> = ({
	name,
	disabled,
	passedValue,
	label,
	options,
	subLabel,
	onChange
}) => {
	const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
	const isErrorExists = getIn(errors, name) && getIn(touched, name);

	useEffect(() => {
		setFieldValue(name, passedValue);
	}, [passedValue]);

	const handleChangeSelect = (e: any) => {
		handleChange(name)(e);
		if (onChange) onChange();
	};

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" mt="0.5rem">
			<Grid container alignItems="center" justifyContent="space-between" pl="0.75rem">
				{label && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary"
						fontWeight="fontWeightBold"
					>
						{label}
					</GlobalTypography.Text>
				)}
				{subLabel && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary.light"
						pl="0.5rem"
						pr="0.5rem"
						fontStyle="italic"
					>
						{subLabel}
					</GlobalTypography.Text>
				)}
			</Grid>

			<Styled.SelectContainer error={isErrorExists} disabled={disabled} isBig>
				<Field
					as={Styled.SelectField}
					id={name}
					name={name}
					onChange={handleChangeSelect}
					value={getIn(values, name) || passedValue}
					disabled={disabled}
				>
					{options.map(({ value, label }) => (
						<MenuItem value={value}>{label}</MenuItem>
					))}
				</Field>
			</Styled.SelectContainer>
		</Grid>
	);
};
