/* eslint-disable react/no-unused-prop-types */
import { getIn, useFormikContext } from 'formik';
import React, { Dispatch, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Styled } from './form-input.styled';
import * as GlobalTypography from '../global-typography';
import { AutocompleteInput } from './autocomplete-input';
import { INighthawkList } from '../../../../services/nighthawk-list.types';
import { getCustomErrorMsg } from './utils';

export interface IFormInputProps {
	text?: string;
	name: string;
	type: string;
	placeholder?: string;
	step?: number;
	min?: number;
	label?: string;
	isEdit?: boolean;
	maxLength?: number;
	disabled?: boolean | undefined;
	passedValue: string;
	isBig?: boolean;
	isOptional?: boolean;
	subLabel?: string;
	passedOptions?: any;
	greyList?: string[];
	blackList?: INighthawkList[];
	onSetCustomErrors?: Dispatch<React.SetStateAction<boolean>>;
	notTouchable?: boolean;
	page?: string;
}

export const FormInput: React.FC<IFormInputProps> = ({
	name,
	type,
	placeholder,
	step,
	min,
	maxLength,
	disabled,
	passedValue,
	label,
	isBig,
	subLabel,
	passedOptions,
	blackList,
	greyList,
	onSetCustomErrors,
	notTouchable,
	page
}) => {
	const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
	const RenderInput = isBig ? Styled.LargeInput : Styled.Input;
	const value = getIn(values, name) || passedValue;
	const isBlackListError = blackList ? blackList.some((el) => value.includes(el.url)) : false;
	const isScamReportError =
		greyList && page === 'scam-report' ? greyList.some((el) => value.includes(el)) : false;
	const isTrustListError =
		greyList && page === 'trust-list' ? greyList.some((el) => value.includes(el)) : false;

	const isErrorExists =
		(getIn(errors, name) && (getIn(touched, name) || notTouchable)) ||
		isBlackListError ||
		isScamReportError ||
		isTrustListError;

	useEffect(() => {
		if (onSetCustomErrors) {
			if (isBlackListError || isScamReportError || isTrustListError) {
				onSetCustomErrors(true);
			} else {
				onSetCustomErrors(false);
			}
		}
	}, [isBlackListError, isScamReportError, isTrustListError]);

	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" mt="0.5rem">
			<Grid container alignItems="center" pl="0.75rem">
				{label && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary"
						fontWeight="fontWeightBold"
					>
						{label}
					</GlobalTypography.Text>
				)}
				{subLabel && (
					<GlobalTypography.Text
						variant="body2"
						colorVariant="secondary"
						fontStyle="italic"
						pl="0.25rem"
					>
						{subLabel}
					</GlobalTypography.Text>
				)}
			</Grid>

			<Styled.InputContainer error={isErrorExists} isBig={isBig} disabled={disabled}>
				<Styled.InputPrefix />
				<RenderInput
					id={name}
					name={name}
					type={type}
					min={min || 0.01}
					step={step}
					onChange={handleChange(name)}
					value={value}
					placeholder={placeholder}
					maxLength={maxLength}
					disabled={disabled}
					passedOptions={passedOptions}
					setFieldValue={setFieldValue}
					as={passedOptions && AutocompleteInput}
				/>
			</Styled.InputContainer>
			{isErrorExists && (
				<Styled.ErrorInfoContainer isBig={isBig}>
					<Styled.ErrorInfoText isBig={isBig}>
						{getIn(errors, name) ||
							getCustomErrorMsg({
								isBlackListError,
								isScamReportError,
								isTrustListError
							})}
					</Styled.ErrorInfoText>
				</Styled.ErrorInfoContainer>
			)}
		</Grid>
	);
};
