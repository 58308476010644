import React, { useEffect } from 'react';
import { useAuthSyncData } from '../../context/auth-sync.context';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { authService } from '../../../services/user.service';
import { customErrorHandler } from '../utils/custom-error-handler.util';

const guestProtectedPage = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
	// Try to create a nice displayName for React Dev Tools.
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const Component = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
		const { userToken, guestToken, onSetGuestToken } = useAuthSyncData();

		const { mutate } = useMutation<any, AxiosError>(() => authService.userGuestAuth(), {
			onSuccess: (res) => {
				onSetGuestToken(res.token);
			},
			onError: customErrorHandler
		});

		useEffect(() => {
			if (!userToken && !guestToken) {
				mutate();
			}
		}, []);

		if (typeof window !== 'undefined') {
			// @ts-ignore
			return <WrappedComponent {...(props as T)} />;
		}

		return null;
	};

	Component.displayName = `${displayName}`;

	return Component;
};

export default guestProtectedPage;
