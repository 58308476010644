import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './modules/app';
import ContextProvider from './modules/context';

ReactDOM.render(
	<React.StrictMode>
		<ContextProvider>
			<AppContainer />
		</ContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
