import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import * as Styled from './toast.styled';
import HexagonPoints from '../hexagon-points';
import * as GlobalTypography from '../global-typography';
import { queryClient } from '../../../app';
import { useToastData } from '../../../context/toast.context';
import { useAuthSyncData } from '../../../context/auth-sync.context';
import { APP_KEYS } from '../../consts';
import { getValidUrl } from '../../../utils';

interface IProps {
	url?: string;
	points?: string;
	isLogin?: boolean;
}

export const ScamReportToast: FC<IProps> = ({ url, points, isLogin }) => {
	const { setScamToast } = useToastData();
	const { removeToken } = useAuthSyncData();
	const { push } = useHistory();

	const handleClose = () => {
		setScamToast('');
	};

	const handleCreateAccount = () => {
		removeToken();
		queryClient.clear();
		push(APP_KEYS.ROUTER_KEYS.SIGN_UP);
	};

	useEffect(() => {
		setTimeout(() => setScamToast(''), 3000);
	}, []);

	const shortUrl = url ? getValidUrl(url) : '';

	return (
		<Styled.ToastContainer container direction="row">
			<Styled.CloseIcon onClick={handleClose} />
			<Grid item xs={3} pt="1rem">
				<HexagonPoints title={points} />
			</Grid>
			<Grid item container xs={9} pl="1rem" pt="0.25rem">
				<GlobalTypography.Text
					variant="body1"
					colorVariant="common.white"
					fontWeight="fontWeightBold"
				>
					After the analyst review, a Guardian Point will be assigned. We appreciate your
					contribution!
				</GlobalTypography.Text>
				<GlobalTypography.Text variant="body1" colorVariant="common.white">
					<Styled.Url>{shortUrl}</Styled.Url>
					&nbsp; has been reported to Nighthawk
				</GlobalTypography.Text>

				{!isLogin && (
					<GlobalTypography.Text variant="body1" colorVariant="common.white" mt="0.75rem">
						<Styled.InlineLink onClick={handleCreateAccount}>Create an account</Styled.InlineLink>
						&nbsp; to keep track of your points.
					</GlobalTypography.Text>
				)}
			</Grid>
		</Styled.ToastContainer>
	);
};
