import {
	createUserWithEmailAndPassword,
	signInWithPopup,
	User,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	sendEmailVerification
} from 'firebase/auth';
import { auth, googleProvider, twitterProvider } from '../../../firebase';
import { Iuuid } from '../../../services/trusted-list.types';
import { authService } from '../../../services/user.service';
import { profileService } from '../../profile/services/profile.service';
import { toastSuccess } from '../components/toasts/toasts.component';
import { IAuthResponse } from '../types';

interface IDetails {
	email: string;
	displayName: string;
	screenName: string;
}

interface IUser extends User {
	reloadUserInfo: {
		providerUserInfo: IDetails[];
	};
}

export class FirebaseAuth {
	createErrorMsg(error: any) {
		const msg = error.message.split('(')[1].split('/').join(': ').split(')')[0];
		return `${error.name}: ${msg}`;
	}

	async signUpWithGoogle(id?: string): Promise<IAuthResponse | Iuuid> {
		try {
			const { user } = await signInWithPopup(auth, googleProvider);
			const res = user as IUser;

			if (!res) throw new Error('unable to authorize');
			const { email, displayName } = res.reloadUserInfo.providerUserInfo[0];

			if (id) {
				const response = await profileService.updateUserProfile({
					id,
					google: email,
					isEmailVerified: true
				});
				if (!response) throw new Error('unable to add google account');

				return response;
			}

			const response = await authService.googleUserAuth(email, displayName, email);
			if (!response) throw new Error('unable to authorize');

			return response;
		} catch (error: any) {
			throw new Error(this.createErrorMsg(error));
		}
	}

	async signUpWithTwitter(id?: string): Promise<IAuthResponse | Iuuid> {
		try {
			const { user } = await signInWithPopup(auth, twitterProvider);
			const res = user as IUser;

			if (!res) throw new Error('unable to authorize');
			const { email, displayName, screenName } = res.reloadUserInfo.providerUserInfo[0];

			if (id) {
				const response = await profileService.updateUserProfile({
					id,
					twitter: screenName,
					isEmailVerified: true
				});
				if (!response) throw new Error('unable to add twitter account');

				return response;
			}

			const response = await authService.twitterUserAuth(email, displayName, screenName);
			if (!response) throw new Error('unable to authorize');

			return response;
		} catch (error: any) {
			throw new Error(this.createErrorMsg(error));
		}
	}

	async signUpWithEmailAndPassword(email: string, password: string): Promise<IAuthResponse> {
		try {
			const { user } = await createUserWithEmailAndPassword(auth, email, password);
			if (!user) throw new Error('unable to authorize');

			const response = await authService.userSignUp(email, email);
			if (!response) throw new Error('unable to create account');

			await sendEmailVerification(user);
			toastSuccess('Verification email sent. Please check your inbox.');

			return response;
		} catch (error: any) {
			throw new Error('FirebaseError: Signup Error: Please try a different email address.');
		}
	}

	async signInWithEmailAndPassword(email: string, password: string): Promise<IAuthResponse> {
		try {
			const { user } = await signInWithEmailAndPassword(auth, email, password);
			if (!user) throw new Error('unable to authorize');

			const response = await authService.userSignIn(email);
			if (!response) throw new Error('unable to authorize');

			return response;
		} catch (error: any) {
			throw new Error('FirebaseError: Signin Error: Email or password incorrect');
		}
	}

	async sendEmailToResetPassword(email: string): Promise<void> {
		try {
			const user = await authService.userSendEmail(email);
			if (user) {
				await sendPasswordResetEmail(auth, email);
			}
		} catch (error: any) {
			throw new Error(this.createErrorMsg(error));
		}
	}
}

export const firebaseAuthService = new FirebaseAuth();
