import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { MainWrapper } from '../common/components';
import { AccountInfo } from './acc-info';
import { GuardPoints } from './guard-points';
import { profileService } from './services/profile.service';
import { IUserInfo } from './services/profile.types';
import StyledPreloader from '../common/components/preloader/preloader';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import { ScamReportToast } from '../common/components/toasts/scam-report-toast.component';
import { useToastData } from '../context/toast.context';
import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import { useAuthSyncData } from '../context/auth-sync.context';
import { customErrorHandler } from '../common/utils/custom-error-handler.util';

const Profile: React.FC = () => {
	const { isLoading, refetch, data } = profileService.useAllQuery<IUserInfo>();
	const { scamToast, setScamToast } = useToastData();
	const { userToken, guestGuardianPoints } = useAuthSyncData();
	const isLogin = !!userToken;

	const { mutate: updateUser } = useMutation<any, AxiosError, boolean>(
		(req: boolean) => profileService.updateUserProfile({ isSharingData: req } as IUserInfo),
		{
			onSuccess: refetch,
			onError: customErrorHandler
		}
	);

	useEffect(
		() => () => {
			setScamToast('');
		},
		[]
	);

	if (isLoading) {
		return (
			<MainWrapper>
				<StyledPreloader />
			</MainWrapper>
		);
	}

	const user: IUserInfo = data || ({} as IUserInfo);
	const userPoints = user.hasOwnProperty('guardianPoints') ? user.guardianPoints : 0;
	const guestPoints = Number(guestGuardianPoints) || 0;
	const points = isLogin ? userPoints : guestPoints;

	return (
		<>
			{scamToast && <ScamReportToast url={scamToast} points="1" isLogin={isLogin} />}
			<MainWrapper>
				<PageWrapper title="Account">
					<AccountInfo user={user} isLogin={isLogin} onUpdateUser={updateUser} />
					<GuardPoints points={String(points)} isLogin={isLogin} />
				</PageWrapper>
			</MainWrapper>
		</>
	);
};

export default guestProtectedPage(Profile);
