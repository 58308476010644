export const COLORS = {
	primary: '#141414',
	secondary: '#141414',
	white: '#ffffff',
	black: '#000000',
	grey: '#e9e9e9',
	green: '#00A40B',
	blue: '#0157FC',
	transparent: 'transparent',
	darkGrey: '#313131',
	red: '#c61825',
	orange: '#fed101',
	darkOrange: '#ef7300',
	purple: '#E40040',
	discord: '#7289da',
	semiTransparentWhite: 'rgba(0, 0, 0, 0.1)',
	semiTransparentGrey: 'rgba(0, 0, 0, 0.16)',
	halfTransparentGrey: 'rgba(145, 143, 145, 0.5)',
	darkBlue: '#102b6a',
	lightGrey: '#f5f5f5',
	darkerGrey: '#8e8e8e',
	lightBlue: '#455aeb',
	violetBlue: '#4344ce',
	deepViolet: '#4d0365',
	blackOlive: '#515557',
	ueRed: '#C30303',
	linkViolet: '#6C0C8B',
	paleViolet: '#BAA6C1',
	paleDarkGrey: '#989898',
	lightViolet: '#baa6c1',
	darkBg: '#211D22'
};
