import { GrayIconContainer } from './icon.styled';

export const YoutubeWhiteIcon = () => (
	<GrayIconContainer>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25.475"
			height="17.95"
			viewBox="0 0 25.475 17.95"
		>
			<path
				id="YouTube-Icon-Full-Color-Logo.wine"
				d="M246.443,136.1h0a3.206,3.206,0,0,0-2.251-2.268c-1.986-.535-9.951-.535-9.951-.535s-7.966,0-9.951.535a3.2,3.2,0,0,0-2.251,2.268,33.206,33.206,0,0,0-.538,6.172,33.618,33.618,0,0,0,.532,6.172,3.206,3.206,0,0,0,2.251,2.268c1.986.535,9.951.535,9.951.535s7.966,0,9.951-.535a3.2,3.2,0,0,0,2.251-2.268,33.206,33.206,0,0,0,.538-6.172A33.617,33.617,0,0,0,246.443,136.1Zm-14.811,9.961v-7.579l6.657,3.789Z"
				transform="translate(-221.5 -133.3)"
				fill="#fff"
			/>
		</svg>
	</GrayIconContainer>
);
