import * as Yup from 'yup';
import { getEmailValidator } from '../../../common/utils/getEmailValidator';
import { getLoginPasswordValidator } from '../../../common/utils/getPasswordValidator';

export const EmailValidationSchema = Yup.object().shape({
	email: getEmailValidator()
});

export const PasswordValidationSchema = Yup.object().shape({
	password: getLoginPasswordValidator()
});

export const CodeValidationSchema = Yup.object().shape({
	code: Yup.string()
		.strict(true)
		.min(6, 'Must be exactly 6 digits')
		.max(6, 'Must be exactly 6 digits')
		.required('Code is required.')
});
