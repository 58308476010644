import { Grid } from '@mui/material';
import React, { ReactChild, ReactChildren } from 'react';
import { NavBar } from '../nav-bar';
import { Footer } from './footer';
import * as Styled from './main-wrapper.styled';

interface IMainWrapperProps {
	children: ReactChild | ReactChildren;
}

export const MainWrapper: React.FC<IMainWrapperProps> = ({ children }) => (
	<Styled.MainContainer>
		<NavBar />
		<Styled.ContentLayout container direction="column">
			<Grid item container flexGrow={1}>
				{children}
			</Grid>
			<Footer />
		</Styled.ContentLayout>
	</Styled.MainContainer>
);
