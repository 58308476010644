import { EnhancedWithAuthHttpService } from '../../common/services';
import { HttpFactoryService } from '../../common/services/http-factory.service';
import { ReactQueryFactory } from '../../common/utils/react-query.util';
import { IUserInfo, IUserInfoMutate, Iuuid } from './profile.types';

class ProfileService extends ReactQueryFactory<{}> {
	constructor(private httpService: EnhancedWithAuthHttpService) {
		super(process.env.REACT_APP_BACKEND_URL!);
	}

	async onAllRequest() {
		return this.httpService.get<IUserInfo>('user/info');
	}

	async updateUserProfile(data: IUserInfoMutate) {
		return this.httpService.put<Iuuid, IUserInfoMutate>('user/update', data);
	}

	async onDetailedRequest(): Promise<Iuuid | void | undefined> {
		throw new Error('onDetailedRequest not implemented');
	}

	async onListRequest(): Promise<IUserInfo | void | undefined> {
		throw new Error('onListRequest not implemented');
	}
}

const factory = new HttpFactoryService();
export const profileService = new ProfileService(factory.createAuthHttpService());
