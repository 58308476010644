import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';

export const CopyLink = styled(Grid)<{ theme?: Theme }>`
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const Image = styled.img<{ theme?: Theme }>`
	margin: ${({ theme }) => theme.spacing(3)};
	border: solid 4px ${({ theme }) => theme.palette.primary.contrastText};
	border-radius: 4px;
	width: 200px;
`;
