import styled from '@emotion/styled';
import { Theme } from '@mui/material';

const isMozilla = navigator.userAgent.includes('Firefox');
const perspectiveValues: { [key: string | number]: string } = {
	2: '1.5rem',
	3: '2rem',
	4: '2.5rem',
	5: '3rem',
	6: '3.5rem'
};

const HexagonBorder = styled.div<{
	theme?: Theme;
	disabled?: boolean | undefined;
	error?: boolean;
	height?: string;
	rows?: number;
	isBig?: boolean;
}>`
	width: ${({ isBig }) => (isBig ? '420px' : '300px')};

	& > span:nth-child(1) {
		position: relative;
		display: flex;
		padding-left: ${({ theme }) => theme.spacing(1.25)};
		cursor: pointer;
		height: ${({ height }) => height || '49px'};
		font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
		margin-block: ${({ theme }) => theme.spacing(0.625)};
		font-weight: 500;
		color: ${({ theme }) => theme.palette.common.black};
		z-index: 100;
	}

	& > span:nth-child(1):before,
	& > span:nth-child(1):after {
		width: 100%;
		content: '';
		position: absolute;
		border: 2px solid
			${({ theme, disabled, error }) => {
				if (disabled) {
					return theme.palette.secondary.main;
				}
				if (error) {
					return theme.palette.error.main;
				}
				return theme.palette.secondary.light;
			}};
		height: 50%;
		left: 0;
		z-index: -1;
		background-color: white;
	}

	& > span:nth-child(1):before {
		border-width: 1px 1px 0 1px;
		top: ${isMozilla ? '1px' : 0};
		transform-origin: center bottom;
		transform: perspective(
				${({ rows, isBig }) => (rows && perspectiveValues[rows]) || (isBig ? '1rem' : '0.75rem')}
			)
			rotateX(2deg);
	}

	& > span:nth-child(1):after {
		position: absolute;
		top: 50%;
		content: '';
		border-width: 0 1px 1px 1px;
		bottom: 0;
		transform-origin: center top;
		transform: perspective(
				${({ rows, isBig }) => (rows && perspectiveValues[rows]) || (isBig ? '1rem' : '0.75rem')}
			)
			rotateX(-2deg);
	}
`;

export const Styled = {
	HexagonBorder
};
