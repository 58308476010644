import React from 'react';
import { MainWrapper } from '../common/components/main-wrapper';
import userProtectedPage from '../common/hocs/user-protected.hoc';
import { AddTrustedForm } from './components/add-trusted-form';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';

const AddTrusted = () => (
	<MainWrapper>
		<PageWrapper title="Add a Trusted Item" plTitle="0.75rem">
			<AddTrustedForm />
		</PageWrapper>
	</MainWrapper>
);

export default userProtectedPage(AddTrusted);
