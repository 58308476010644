/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import * as GlobalTypography from '../../common/components/global-typography';
import { APP_KEYS } from '../../common/consts';
import TrustedTable from './trusted-table.component';
import { HexagonBtn } from '../../common/components';

interface IProps {
	searchValue?: string;
}

const TrustedListBody: React.FC<IProps> = ({ searchValue }) => {
	const { push } = useHistory();

	function addTrustedItem() {
		// navigate to the create trusted item page
		push(APP_KEYS.ROUTER_KEYS.ADD_TRUSTED);
	}

	function goToNighthawkTrustedList() {
		push(APP_KEYS.ROUTER_KEYS.NIGHTHAWK_TRUSTED_LIST);
	}

	return (
		<>
			<Grid item container mt="2rem" mb="1rem">
				<Grid item flexGrow={1} mt="1rem">
					<GlobalTypography.Text
						variant="subtitle1"
						colorVariant="primary"
						fontWeight="fontWeightMedium"
					>
						Your Personal Trust List
					</GlobalTypography.Text>
				</Grid>
				<Grid item>
					<HexagonBtn
						title="Add A Trusted Item"
						variant="filled"
						click={addTrustedItem}
						isSmallButton
						width="200px"
					/>
				</Grid>
			</Grid>

			<Grid item flexGrow={1}>
				<TrustedTable filter={searchValue} />
			</Grid>

			<Grid item container spacing={1} mt="2rem">
				<Grid item xs={12}>
					<GlobalTypography.Text
						variant="subtitle1"
						colorVariant="primary"
						fontWeight="fontWeightMedium"
					>
						Nighthawk's Trusted List
					</GlobalTypography.Text>
				</Grid>
				<Grid item xs={6}>
					<GlobalTypography.Text variant="body1" colorVariant="secondary">
						Nighthawk maintains the most comprehensive trusted list of sites and accounts which
						includes the most visited sites on the web
					</GlobalTypography.Text>
				</Grid>
				<Grid item container xs={6} justifyContent="center">
					<HexagonBtn
						width="17rem"
						title="View Nighthawk`s Trust List"
						variant="filled"
						click={goToNighthawkTrustedList}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default TrustedListBody;
