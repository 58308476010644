import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import Close from '@mui/icons-material/Close';

export const StyledContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const StyledText = styled.div`
	display: inline-flex;
	flex: 1;
`;
export const StyledClose = styled.div`
	position: absolute;
	width: 25px;
	height: 25px;
	top: 0;
	left: -24%;
	cursor: pointer;
`;

export const ToastContainer = styled(Grid)<{ theme?: Theme }>`
	position: fixed;
	top: 25px;
	right: 50px;
	padding: ${({ theme }) =>
		`${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)}`};
	background-color: ${({ theme }) => theme.palette.primary.contrastText};
	color: ${({ theme }) => theme.palette.common.white};
	border-radius: 6px;
	min-height: 90px;
	max-width: 360px;
	z-index: 9999;
`;

export const CloseIcon = styled(Close)<{ theme?: Theme }>`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	font-size: 2.25rem;
	border: 2px solid ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.secondary.light};
	border-radius: 50%;
	cursor: pointer;
`;

export const InlineLink = styled.span`
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
`;

export const Url = styled.b`
	word-break: break-all;
`;
