import { GrayIconContainer } from './icon.styled';

export const TwitterWhiteIcon = () => (
	<GrayIconContainer>
		<svg xmlns="http://www.w3.org/2000/svg" width="22.151" height="18" viewBox="0 0 22.151 18">
			<path
				id="Path_1349"
				data-name="Path 1349"
				d="M23.691,6.118a9.072,9.072,0,0,1-2.6.731,4.551,4.551,0,0,0,1.991-2.52A9.149,9.149,0,0,1,20.2,5.44,4.473,4.473,0,0,0,16.851,4a4.537,4.537,0,0,0-4.521,4.542,4.721,4.721,0,0,0,.116,1.038,12.912,12.912,0,0,1-9.36-4.744,4.525,4.525,0,0,0,1.408,6.046,4.492,4.492,0,0,1-2.065-.529v.032a4.546,4.546,0,0,0,3.642,4.458,4.474,4.474,0,0,1-2.044.074,4.531,4.531,0,0,0,4.235,3.155A9.026,9.026,0,0,1,2.62,20.02a9.236,9.236,0,0,1-1.08-.064A12.859,12.859,0,0,0,8.507,22,12.85,12.85,0,0,0,21.435,9.072c0-.2,0-.392-.011-.593A9.076,9.076,0,0,0,23.691,6.118Z"
				transform="translate(-1.54 -4)"
				fill="#fff"
			/>
		</svg>
	</GrayIconContainer>
);
