import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { FormInput, FormSelect, HexagonBtn } from '../../../common/components';
import {
	EType,
	ICreateTrustedItem,
	ITrustedList,
	options
} from '../../../../services/trusted-list.types';

import { validationSchema } from '../../utils/add-trusted-validator-schema.util';
import { trustedListService } from '../../../../services/trusted-list.service';
import { APP_KEYS } from '../../../common/consts';
import { ITrustedData, useToastData } from '../../../context/toast.context';
import FormTextarea from '../../../common/components/form-texarea/form-textarea.component';
import { handleFormType, handleTrustPlaceholder } from '../../../utils';
import { nighthawkListService } from '../../../../services/nighthawk-list.service';
import { INighthawkList } from '../../../../services/nighthawk-list.types';
import { IPagination } from '../../../common/utils/react-query.util';
import { customErrorHandler } from '../../../common/utils/custom-error-handler.util';

const initialValues = {
	type: EType.WEBSITE,
	url: '',
	comment: ''
};

export const AddTrustedForm = () => {
	const { push } = useHistory();
	const { refetch } = trustedListService.useAllQuery<ITrustedList[]>();
	const { setTrustedToast } = useToastData();
	const [currentType, setCurrentType] = useState(EType.WEBSITE);
	const [customErrors, setCustomErrors] = useState<boolean>(false);

	const [trustedData, setTrustedData] = useState<ITrustedData>({
		type: null,
		url: ''
	});

	const { data: blackListData } = nighthawkListService.useListQuery<INighthawkList[]>(
		{ type: currentType },
		{} as IPagination
	);

	const { data: greyListData } = nighthawkListService.useAllQuery<string[]>();

	const { mutate: addTrustedItem } = useMutation<any, AxiosError, ICreateTrustedItem>(
		(req: ICreateTrustedItem) => trustedListService.addNewTrustedItem(req),
		{
			onSuccess: () => {
				refetch();
				setTrustedToast(trustedData);
				push(APP_KEYS.ROUTER_KEYS.TRUSTED_LIST);
			},
			onError: customErrorHandler
		}
	);

	const formik = useFormik({
		initialValues,
		onSubmit: ({ type, url, comment }, { resetForm }) => {
			setTrustedData({
				type: type as EType,
				url
			});
			addTrustedItem({ type, url, comment });
			window.postMessage(
				{ type: process.env.REACT_APP_TRUSTED_LIST_WEB, shouldUpdateCache: true },
				'*'
			);
			resetForm();
		},
		validationSchema: validationSchema()
	});
	const { type, url } = formik.values;

	useEffect(() => {
		handleFormType(currentType, type, url, setCurrentType);
	}, [type, url]);

	const handleSelectChange = () => {
		formik.setFieldValue('url', '');
	};

	const isValidButton = !Object.keys(formik.errors).length && !!type && !!url && !customErrors;
	const blackList = blackListData || [];
	const greyList = greyListData || [];
	const greyListOptions = currentType === EType.WEBSITE ? greyList : [];

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<FormSelect
					name="type"
					passedValue={currentType}
					label="What type of item are you adding?"
					options={options}
					onChange={handleSelectChange}
				/>
				<FormInput
					isBig
					name="url"
					type="text"
					passedValue=""
					placeholder={handleTrustPlaceholder(currentType)}
					label="URL"
					blackList={blackList}
					greyList={greyListOptions}
					onSetCustomErrors={setCustomErrors}
					page="trust-list"
				/>
				<FormTextarea
					name="comment"
					type="comment"
					passedValue=""
					placeholder="Note on why you added this website to your list"
					label="Comment"
					subLabel="(Optional)"
				/>
				<Grid container justifyContent="start" mt="0.75rem">
					<HexagonBtn disabled={!isValidButton} variant="filled" width="220px" title="Add Item" />
				</Grid>
			</form>
		</FormikProvider>
	);
};
