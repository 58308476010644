import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { IReportScam } from '../../../../services/scam.types';
import { FormInput, FormSelect, HexagonBtn } from '../../../common/components';
import { validationSchema } from '../../../add-trusted/utils/add-scam-report-validator-schema.util';
import { scamReportService } from '../../../../services/scam.service';
import { EType, IWhiteList, options } from '../../../../services/trusted-list.types';
import { toastError } from '../../../common/components/toasts/toasts.component';
import { profileService } from '../../../profile/services/profile.service';
import { IUserInfo } from '../../../profile/services/profile.types';
import FormTextarea from '../../../common/components/form-texarea/form-textarea.component';
import { APP_KEYS } from '../../../common/consts';
import { useToastData } from '../../../context/toast.context';
import { useAuthSyncData } from '../../../context/auth-sync.context';
import { handleFormType, handleScamPlaceholder } from '../../../utils';
import { trustedListService } from '../../../../services/trusted-list.service';
import { IPagination } from '../../../common/utils/react-query.util';
import { nighthawkListService } from '../../../../services/nighthawk-list.service';
import { INighthawkList } from '../../../../services/nighthawk-list.types';

const initialValues = {
	type: EType.WEBSITE,
	url: '',
	impersonatedUrl: '',
	comment: ''
};

export const ScamReportForm = () => {
	const { data: userInfo, refetch } = profileService.useAllQuery<IUserInfo>();
	const { push } = useHistory();
	const { setScamToast } = useToastData();
	const { userToken, onSetGuardianPoints } = useAuthSyncData();
	const isLogin = !!userToken;

	const [currentType, setCurrentType] = useState(EType.WEBSITE);
	const [reportedUrl, setReportedUrl] = useState('');
	const [customErrors, setCustomErrors] = useState<boolean>(false);

	const { data } = trustedListService.useListQuery<IWhiteList[]>(
		{ type: currentType },
		{} as IPagination
	);

	const { data: blackListData } = nighthawkListService.useListQuery<INighthawkList[]>(
		{ type: currentType },
		{} as IPagination
	);

	const { data: greyListData } = nighthawkListService.useAllQuery<string[]>();

	const { mutate: addNewScamReport } = useMutation<any, AxiosError, IReportScam>(
		(req: IReportScam) => scamReportService.addScamReport(req),
		{
			onSuccess: () => {
				refetch();
				setScamToast(reportedUrl);
				if (!isLogin) {
					const currentPoints = Number(userInfo?.guardianPoints) || 0; // fetched from backend
					onSetGuardianPoints(String(currentPoints));
				}
				push(APP_KEYS.ROUTER_KEYS.PROFILE);
			},
			onError: (error) => {
				let errorMessage = 'Unexpected error occurred. Please try again later.';
				if (error.response?.status === 429) {
					const { message } = error.response?.data as unknown as { message: string };
					errorMessage = message;
				}
				toastError(errorMessage);
			}
		}
	);

	const formik = useFormik({
		initialValues,
		onSubmit: ({ type, url, impersonatedUrl, comment }, { resetForm }) => {
			setReportedUrl(url);
			addNewScamReport({ type, url, impersonatedUrl, comment, userId: userInfo?.id });
			resetForm();
		},
		validationSchema: validationSchema(true)
	});

	const { type, url } = formik.values;

	useEffect(() => {
		handleFormType(currentType, type, url, setCurrentType);
	}, [type, url]);

	const handleSelectChange = () => {
		formik.setFieldValue('url', '');
	};

	const isValidButton = !Object.keys(formik.errors).length && !!type && !!url && !customErrors;
	const whiteList = data || [];
	const whiteListOptions = whiteList.map((el) => el.url);

	const blackList = blackListData || [];
	const greyList = greyListData || [];
	const greyListOptions = currentType === EType.WEBSITE ? greyList : [];

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction="column" alignItems="center">
					<FormSelect
						name="type"
						passedValue={currentType}
						label="What are you reporting?"
						options={options}
						subLabel="You can report URLs and accounts"
						onChange={handleSelectChange}
					/>
					<FormInput
						isBig
						name="url"
						type="text"
						passedValue=""
						placeholder={handleScamPlaceholder(currentType)}
						label="Phishing URL"
						blackList={blackList}
						greyList={greyListOptions}
						onSetCustomErrors={setCustomErrors}
						page="scam-report"
					/>
					<FormInput
						isBig
						name="impersonatedUrl"
						type="text"
						passedValue=""
						placeholder="realsite.com"
						label="Impersonated URL"
						subLabel="(Optional)"
						passedOptions={whiteListOptions}
						notTouchable
					/>
					<FormTextarea
						name="comment"
						type="comment"
						passedValue=""
						placeholder="How did you come across this URL?"
						label="Comment"
						subLabel="(Optional)"
					/>
					<Grid container justifyContent="start" mt="0.75rem">
						<HexagonBtn
							disabled={!isValidButton}
							variant="filled"
							width="220px"
							title="Send Report"
						/>
					</Grid>
				</Grid>
			</form>
		</FormikProvider>
	);
};
