import styled from 'styled-components';
import { FONTS, COLORS, SPACES } from '../../theme';

export const ListTitle = styled.div`
	font-size: ${FONTS.SIZES.xl};
	font-weight: ${FONTS.WEIGHTS.medium};
	color: ${COLORS.deepViolet};
	padding-bottom: ${SPACES.xs};
`;

export const IconContainer = styled.div`
	margin-right: ${SPACES.m};
`;

export const ListItem = styled.div`
	display: flex;
	align-items: start;
	font-size: ${FONTS.SIZES.l};
	line-height: ${FONTS.SIZES.xxl};
	font-weight: ${FONTS.WEIGHTS.normal};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.blackOlive};
	padding: ${SPACES.xxxs} 0;
`;
