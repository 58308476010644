import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';

export const MainContainer = styled(Grid)`
	min-height: 100vh;
	min-width: 100vw;
`;
export const ContentLayout = styled(Grid)<{ theme?: Theme }>`
	min-height: 100vh;
	background-color: ${({ theme }) => theme.palette.background.default};
	padding-left: ${({ theme }) => theme.spacing(36)};
`;
