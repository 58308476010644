import React from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Grid } from '@mui/material';
import { DiscordIcon } from '../common/components/icons/discord-icon';
import { TwitterIcon } from '../common/components/icons/twitter-icon';
import { MainWrapper } from '../common/components';
import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import { ControlledAccordions } from './components/accordion';
import * as Styled from './user-guide.styled';
import * as GlobalTypography from '../common/components/global-typography';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import Popover from './components/popover';

const UserGuide = () => {
	const { search } = useLocation();
	const showPopover = search.includes('popover=true');

	return (
		<>
			{showPopover && <Popover />}
			<MainWrapper>
				<PageWrapper title="User Guide" plTitle="1rem">
					<Grid container direction="column" alignItems="center" mt="0.5rem">
						<Grid item flexGrow={1}>
							<GlobalTypography.Text
								variant="body2"
								colorVariant="secondary.light"
								fontWeight="fontWeightMedium"
								ml="1rem"
								mb="0.5rem"
							>
								Question
							</GlobalTypography.Text>
							<Divider sx={{ marginBottom: '0.5rem' }} />
							<ControlledAccordions />
						</Grid>
						<Grid
							item
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							mt="4rem"
						>
							<GlobalTypography.Text
								variant="subtitle1"
								colorVariant="primary"
								fontWeight="fontWeightBold"
							>
								Have a Question?
							</GlobalTypography.Text>
							<GlobalTypography.Text variant="body2" colorVariant="primary">
								Ask us in our official Discord or Twitter accounts?
							</GlobalTypography.Text>
							<Grid item>
								<Grid container>
									<Styled.LinkBlock href="https://discord.gg/psb9b2FGu9">
										<DiscordIcon />
									</Styled.LinkBlock>
									<Styled.LinkBlock href="https://twitter.com/NighthawkPlugin">
										<TwitterIcon />
									</Styled.LinkBlock>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</PageWrapper>
			</MainWrapper>
		</>
	);
};

export default guestProtectedPage(UserGuide);
