import React from 'react';
import * as Styled from './link.styled';

interface IFooterLinkProps {
	to: string;
	text: string;
}

export const FooterLink: React.FC<IFooterLinkProps> = ({ to, text }) => (
	<Styled.Link to={to}>{text}</Styled.Link>
);
