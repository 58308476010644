import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../theme';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: ${SPACES.xxl};
`;

export const SignInBox = styled.div<{ marginTop?: string }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${(props) => (props.marginTop ? props.marginTop : SPACES.xxl)};
	margin-bottom: ${SPACES.xs};
`;

export const Title = styled.div`
	text-align: center;
	font-size: ${FONTS.SIZES.l};
	font-weight: ${FONTS.WEIGHTS.light};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.blackOlive};
`;

export const Link = styled(NavLink)`
	text-align: center;
	text-decoration: none;
	font-size: ${FONTS.SIZES.l};
	font-weight: ${FONTS.WEIGHTS.bold};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.linkViolet};
	margin-left: ${SPACES.xxxs};
`;

export const GuestLogin = styled.p`
	text-align: center;
	text-decoration: none;
	font-size: ${FONTS.SIZES.l};
	font-weight: ${FONTS.WEIGHTS.medium};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.deepViolet};
	margin-left: ${SPACES.xxxs};
	cursor: pointer;
`;
