import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';
import { SIZES as fontSizes, WEIGHTS, FAMILIES } from './fonts.const';
import { MEDIA } from './media.const';
import { SIZES } from './sizes.const';
import { SPACES } from './spaces.const';
import { lightOptions } from './light-theme-palette.const';
import { darkOptions } from './dark-theme-palette.const';

const defaultTheme = createTheme(
	deepmerge(
		{
			palette: {
				error: {
					main: '#C30303'
				},
				text: {
					primary: '#3C3D3E'
				}
			},
			typography: {
				fontFamily: ['Roboto', '"IBM Plex Sans"', 'Arial'].join(','),
				h4: {
					fontSize: '1.875rem',
					fontWeight: 500
				},
				subtitle1: {
					fontSize: '1.375rem'
				},
				subtitle2: {
					fontSize: '1.125rem',
					fontWeight: 400
				}
			}
		},
		{
			fonts: {
				size: fontSizes,
				family: FAMILIES,
				weight: WEIGHTS
			},
			media: MEDIA,
			size: SIZES,
			space: SPACES
		}
	)
);

const lightTheme = createTheme(defaultTheme, lightOptions);
const darkTheme = createTheme(defaultTheme, darkOptions);

export { lightTheme, darkTheme };
