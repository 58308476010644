import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as GlobalTypography from '../../common/components/global-typography';

export const SwitchBlock = styled(Grid)`
	width: 24rem;
`;

export const OpacityText = styled(GlobalTypography.Text)`
	opacity: 0.5;
`;

export const PseudoLink = styled(GlobalTypography.Text)`
	cursor: pointer;
`;
