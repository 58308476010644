import { CreateProfileForm } from './components/create-profile-form';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import * as GlobalTypography from '../../common/components/global-typography';
import { Grid } from '@mui/material';
import { profileService } from '../../profile/services/profile.service';
import { IUserInfo } from '../../profile/services/profile.types';
import StyledPreloader from '../../common/components/preloader/preloader';
import userProtectedPage from '../../common/hocs/user-protected.hoc';

const CreateProfile = () => {
	const { data, isLoading } = profileService.useAllQuery<IUserInfo>();

	if (isLoading) {
		return (
			<AuthPageWrapper>
				<AuthPageActionCardWrapper>
					<StyledPreloader />
				</AuthPageActionCardWrapper>
			</AuthPageWrapper>
		);
	}

	return (
		<AuthPageWrapper>
			{data?.google || data?.isEmailVerified || data?.twitter ? (
				<AuthPageActionCardWrapper title="Create a Username">
					<CreateProfileForm />
				</AuthPageActionCardWrapper>
			) : (
				<AuthPageActionCardWrapper title="Verify your email">
					<Grid container direction="column" alignItems="center" justifyContent="space-between">
						<GlobalTypography.Text variant="body1" mt={'0.5rem'} colorVariant="secondary">
							Check your email for a link to verify your email address. Click the link in the email
							to complete verification and return to this page to complete your profile.
						</GlobalTypography.Text>
					</Grid>
				</AuthPageActionCardWrapper>
			)}
		</AuthPageWrapper>
	);
};

export default userProtectedPage(CreateProfile);
