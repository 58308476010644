/* eslint-disable react/no-unescaped-entities */
import React, {
	Attributes,
	Children,
	cloneElement,
	isValidElement,
	ReactChild,
	ReactChildren,
	useState
} from 'react';
import { Grid, Input } from '@mui/material';
import * as GlobalTypography from '../common/components/global-typography';
import * as Styled from './components/trusted-list.styled';
import { useToastData } from '../context/toast.context';
import { MainWrapper } from '../common/components';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import { TrustedToast } from '../common/components/toasts/trusted-toast.component';
import { FormFieldContainer } from '../common/components/form-field-container';

interface IProps {
	title: string;
	children: ReactChild | ReactChildren;
}

const TrustedListContainer: React.FC<IProps> = ({ title, children }) => {
	const { trustedToast } = useToastData();
	const [searchValue, setSearchValue] = useState('');

	return (
		<>
			{trustedToast.url && <TrustedToast type={trustedToast.type} url={trustedToast.url} />}
			<MainWrapper>
				<PageWrapper>
					<Grid container direction="column">
						<Grid container justifyContent="space-between" alignItems={'center'}>
							<Grid container direction={'column'} xs={6}>
								<Grid item>
									<GlobalTypography.Text
										variant="h4"
										colorVariant="primary"
										fontWeight="fontWeightMedium"
									>
										{title}
									</GlobalTypography.Text>
								</Grid>

								{window.location.pathname === '/trusted-list' && (
									<Grid item>
										<GlobalTypography.Text
											variant="body1"
											mt={'0.5rem'}
											// color={'red'}
											colorVariant="secondary"
										>
											Note: The Nighthawk team does not review your Personal Trust List. If you want
											the item to undergo review and be added to the Global Trust List, please reach
											out to us on Discord.
										</GlobalTypography.Text>
									</Grid>
								)}
							</Grid>
							<Grid item>
								<FormFieldContainer isBig>
									<Grid justifyContent="space-between" container alignItems="center">
										<Grid container width="85%" pl="15px" pt="1px">
											<Input
												fullWidth
												disableUnderline
												placeholder="Search"
												sx={{ fontSize: '18px' }}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													setSearchValue(e.target.value)
												}
											/>
										</Grid>
										<Styled.MySearch />
									</Grid>
								</FormFieldContainer>
							</Grid>
						</Grid>

						{Children.map(children, (child) => {
							if (isValidElement(child)) {
								return cloneElement(child, { searchValue } as Partial<unknown> & Attributes);
							}
							return null;
						})}
					</Grid>
				</PageWrapper>
			</MainWrapper>
		</>
	);
};

export default TrustedListContainer;
