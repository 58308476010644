import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { FormInput, HexagonBtn } from '../../../../common/components';
import { firebaseAuthService } from '../../../../common/services/firebase-auth.service';
import { Form, Link } from './sign-in-form.styled';
import { loginValidationSchema } from '../../utils/login-validator-schema';
import { APP_KEYS } from '../../../../common/consts';
import { profileService } from '../../../../profile/services/profile.service';
import { IUserInfo } from '../../../../profile/services/profile.types';
import { localStorageService } from '../../../../common/services/local-storage.service';
import { useAuthSyncData } from '../../../../context/auth-sync.context';
import { customErrorHandler } from '../../../../common/utils/custom-error-handler.util';
import { IUpdateRequest } from '../../../../../services/user.types';

const initialValues = {
	email: '',
	password: ''
};

interface IRequest {
	guardianPoints?: number;
}

export const SignInForm = () => {
	const { push } = useHistory();
	const { onSetUserToken, removeGuardianPoints } = useAuthSyncData();

	const { mutate: updateUser } = useMutation<any, AxiosError, IRequest>(
		(userInfo) => profileService.updateUserProfile(userInfo as IUserInfo),
		{
			onSuccess: () => {
				localStorageService.removeSharingDataFromStorage();
				removeGuardianPoints();
			},
			onError: customErrorHandler
		}
	);

	const onSuccessAuth = (token: string) => {
		let args: IUpdateRequest = {};
		const guardianPoints = Number(localStorageService.getGuardianPointsFromStorage()) || 0;
		const isSharingData = localStorageService.getSharingDataFromStorage();
		if (guardianPoints) {
			args = { ...args, guardianPoints };
		}
		if (isSharingData !== null) {
			args = { ...args, isSharingData };
		}
		if (Object.keys(args).length) {
			updateUser(args);
		}
		onSetUserToken(token);
		push(APP_KEYS.ROUTER_KEYS.TRUSTED_LIST);
	};

	const formik = useFormik({
		initialValues,
		onSubmit: async ({ email, password }) => {
			try {
				const result = await firebaseAuthService.signInWithEmailAndPassword(email, password);
				if (!result) throw new Error('smth went wrong');

				onSetUserToken(result.token);
				onSuccessAuth(result.token);
			} catch (err: any) {
				customErrorHandler(err);
			}
		},
		validationSchema: loginValidationSchema
	});

	const isValidButton =
		!Object.keys(formik.errors).length && !!formik.values.email && !!formik.values.password;

	return (
		<FormikProvider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Grid container direction="column" alignItems="center" sx={{ width: 'auto' }}>
					<FormInput
						name="email"
						type="email"
						passedValue=""
						placeholder="Enter your email address"
						label="Email"
					/>
					<FormInput
						name="password"
						passedValue=""
						type="password"
						placeholder="Enter your password"
						label="Password"
					/>
					<Grid item alignSelf="start" pl="4rem">
						<Link to={APP_KEYS.ROUTER_KEYS.RESET}>Forgot your password?</Link>
					</Grid>
					<Grid item mt="1.25rem">
						<HexagonBtn title="Sign In" width="230px" variant="filled" disabled={!isValidButton} />
					</Grid>
				</Grid>
			</Form>
		</FormikProvider>
	);
};
