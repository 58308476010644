/* eslint-disable max-len */
export const DiscordBigIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="53" height="60.571" viewBox="0 0 53 60.571">
		<path
			id="Icon_awesome-discord"
			data-name="Icon awesome-discord"
			d="M35.162,28.771a3.1,3.1,0,1,1-3.089-3.362A3.227,3.227,0,0,1,35.162,28.771ZM21.018,25.41a3.374,3.374,0,0,0,0,6.723,3.227,3.227,0,0,0,3.089-3.362A3.207,3.207,0,0,0,21.018,25.41ZM53,6.239V60.571c-7.63-6.743-5.19-4.511-14.053-12.75l1.605,5.6H6.209A6.224,6.224,0,0,1,0,47.185V6.239A6.224,6.224,0,0,1,6.209,0H46.791A6.224,6.224,0,0,1,53,6.239ZM44.369,34.95a40.557,40.557,0,0,0-4.361-17.657,14.976,14.976,0,0,0-8.51-3.18l-.424.485a20.156,20.156,0,0,1,7.541,3.846,25.734,25.734,0,0,0-22.623-.878c-1.121.515-1.787.878-1.787.878s2.514-2.393,7.965-3.967l-.3-.363a14.976,14.976,0,0,0-8.51,3.18A40.557,40.557,0,0,0,8.995,34.95s2.544,4.391,9.237,4.6c0,0,1.121-1.363,2.029-2.514a9.419,9.419,0,0,1-5.3-3.574c.446.312,1.18.716,1.242.757a22.076,22.076,0,0,0,18.9,1.06A17.331,17.331,0,0,0,38.584,33.5,9.557,9.557,0,0,1,33.1,37.1c.909,1.151,2,2.453,2,2.453C41.794,39.341,44.369,34.95,44.369,34.95Z"
			fill="#6c0c8b"
		/>
	</svg>
);
