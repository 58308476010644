/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Grid } from '@mui/material';
import NighthawkTrustTable from './nichthawk-trust-table.component';

interface IProps {
	searchValue?: string;
}

const NighthawkTrustedListBody: React.FC<IProps> = ({ searchValue }) => (
	<Grid item flexGrow={1} mt="2rem" mb="1rem">
		<NighthawkTrustTable filter={searchValue} />
	</Grid>
);

export default NighthawkTrustedListBody;
