import * as React from 'react';
import { useState } from 'react';
import * as Styled from './Danger-warning';
import { Grid } from '@mui/material';
import PhishFortLogo from '../../assets/icons/phishfortLogo';
import * as GlobalTypography from '../common/components/global-typography';
import { TopLogoSvg } from '../../assets/icons/top-logo';
import { useHistory } from 'react-router';

const isValidUrl = (url: string) => {
	try {
		new URL(url);
		return true;
	} catch (e) {
		return false;
	}
};

const NighthawkWarning: React.FC = () => {
	const [url, setUrl] = React.useState('');
	const history = useHistory();
	React.useEffect(() => {
		const _url = new URLSearchParams(window.location.search).get('url')!;
		if (isValidUrl(_url)) {
			const validUrl = new URL(_url).origin;
			setUrl(validUrl);
		} else {
			history.push('/');
		}
	}, []);
	const [dangerousAgree, setDangerousAgree] = useState(false);
	if (dangerousAgree && url) {
		window.location.href = url;
	}
	const handleDangerousAgree = () => {
		window.postMessage(
			{
				url,
				type: process.env.REACT_APP_DANGER_AGREE
			},
			'*'
		);
		setDangerousAgree(true);
	};

	return (
		<div className="dangerous-content-page">
			<Styled.Image src={'assets/images/bg-dangerous.png'} />
			<Styled.DangerousContainer container direction="column">
				<Grid item container justifyContent="start" mt="2rem" ml="2rem">
					<TopLogoSvg width="170" height="28" />
				</Grid>

				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					flexGrow={1}
				>
					<GlobalTypography.Text variant="h2" colorVariant="common.white" fontWeight="bold">
						WARNING
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="h4" colorVariant="common.white" fontWeight="bold">
						THIS SITE IS DANGEROUS
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="h5" colorVariant="common.white" mt="2rem" mb="1rem">
						It is recommended that you do not visit this site:
					</GlobalTypography.Text>
					<Styled.BorderBox item container justifyContent="center" alignItems="center">
						<GlobalTypography.Text variant="h4" colorVariant="common.white">
							{url ? new URL(url).host.replace('www.', '') : 'placeholder-site.com'}
						</GlobalTypography.Text>
					</Styled.BorderBox>
					<GlobalTypography.Text
						variant="subtitle1"
						colorVariant="common.white"
						align="center"
						mt="1rem"
						mb="1rem"
						maxWidth="36rem"
						fontWeight="fontWeightMedium"
					>
						The website that you were about to visit has been identified as potentially dangerous by
						Nighthawk.
					</GlobalTypography.Text>
					<Styled.PseudoLink onClick={handleDangerousAgree}>
						<GlobalTypography.Text
							variant="h6"
							colorVariant="common.white"
							fontWeight="fontWeightMedium"
							align="center"
							mt="1rem"
						>
							I know what I’m doing, proceed to this site.
						</GlobalTypography.Text>
					</Styled.PseudoLink>
					<GlobalTypography.Text
						colorVariant="common.white"
						fontWeight="fontWeightMedium"
						align="center"
						my="1rem"
						variant="h6"
						maxWidth="36rem"
					>
						<a
							href={`${process.env.REACT_APP_FALSE_POSITIVE_FORM}`}
							target="_blank"
							style={{ color: 'white', textDecorationColor: 'white' }}
						>
							Is this false positive? Let us know.
						</a>{' '}
					</GlobalTypography.Text>
				</Grid>

				<Grid
					item
					container
					justifyContent="center"
					sx={{
						fontSize: '1.5rem'
					}}
					mb="2rem"
				>
					<PhishFortLogo />
				</Grid>
			</Styled.DangerousContainer>
		</div>
	);
};

export default NighthawkWarning;
