/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import TrustedListContainer from './trusted-list-container.component';
import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import NighthawkTrustedListBody from './components/nighthawk-trusted-list-body.component';

const NighthawkTrustedList: React.FC = () => (
	<TrustedListContainer title="Nighthawk Trust List">
		<NighthawkTrustedListBody />
	</TrustedListContainer>
);

export default guestProtectedPage(NighthawkTrustedList);
