import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as Styled from './trusted-list.styled';
import { EType, IWhiteList } from '../../../services/trusted-list.types';
import { trustedListService } from '../../../services/trusted-list.service';
import StyledPreloader from '../../common/components/preloader/preloader';
import * as GlobalTypography from '../../common/components/global-typography';
import { EFields, ISortOptions } from '../types';
import { getSortedList, renderTrustedItemIcon, sortHelper, typesOrder } from '../trust-list.util';
import TableSortIcon from './table-sort-icon.component';
import { IPagination } from '../../common/utils/react-query.util';

interface IProps {
	filter?: string;
}

const NighthawkTrustTable: React.FC<IProps> = ({ filter = '' }) => {
	const { data, refetch, isLoading } = trustedListService.useListQuery<IWhiteList[]>(
		{ filter },
		{} as IPagination
	);

	const [currentSortOptions, setCurrentSortOptions] = useState<ISortOptions<IWhiteList>>({
		field: null,
		type: null
	});
	const [currentType, setCurrentType] = useState<EType | null>(null);

	useEffect(() => {
		refetch();
	}, [filter]);

	const handleSort = (field: keyof IWhiteList) =>
		sortHelper<IWhiteList>(field, setCurrentSortOptions);
	const handleCurrentType = (filteredList: IWhiteList[]) => {
		handleSort(EFields.TYPE)();
		setCurrentType((prev) => {
			if (!prev) return EType.WEBSITE;
			const currentIdx = typesOrder.indexOf(prev);
			const leftTypes = typesOrder.slice(currentIdx + 1);
			const nextCurrentType = leftTypes.reduce(
				(acc, el) => (!acc && filteredList.filter((item) => item.type === el).length ? el : acc),
				'' as EType
			);
			if (nextCurrentType) {
				return nextCurrentType;
			}
			return typesOrder[0];
		});
	};

	if (isLoading) {
		return <StyledPreloader />;
	}

	const whiteList = data || [];
	const sortedList = getSortedList<IWhiteList>(currentSortOptions, whiteList, currentType as EType);

	return (
		<Styled.TableContainer>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<Styled.SortTableCell onClick={() => handleCurrentType(whiteList)}>
							<Styled.TableHeadContainer>
								<GlobalTypography.Text
									variant="inherit"
									colorVariant="secondary.light"
									fontSize="0.875rem"
								>
									Type
								</GlobalTypography.Text>
								<TableSortIcon fieldName={EFields.TYPE} sortOptions={currentSortOptions} />
							</Styled.TableHeadContainer>
						</Styled.SortTableCell>
						<Styled.SortTableCell onClick={handleSort(EFields.NAME)}>
							<Styled.TableHeadContainer>
								<GlobalTypography.Text
									variant="inherit"
									colorVariant="secondary.light"
									fontSize="0.875rem"
								>
									Name
								</GlobalTypography.Text>
								<TableSortIcon fieldName={EFields.NAME} sortOptions={currentSortOptions} />
							</Styled.TableHeadContainer>
						</Styled.SortTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedList.map((el) => (
						<TableRow key={el.url}>
							<TableCell width="50px">
								<Box display="flex" alignItems="center" justifyContent="center" width="36px">
									{renderTrustedItemIcon(el.type)}
								</Box>
							</TableCell>
							<TableCell>
								<Box display="flex">
									<GlobalTypography.Text variant="subtitle2" colorVariant="secondary.dark">
										{el.url}
									</GlobalTypography.Text>
								</Box>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Styled.TableContainer>
	);
};

export default NighthawkTrustTable;
