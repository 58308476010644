import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import MUICloseIcon from '@mui/icons-material/Close';

export const PopoverContainer = styled.div<{ theme?: Theme }>`
	border: 1px solid ${({ theme }) => theme.palette.primary.light};
	position: fixed;
	border-radius: 6px;
	top: ${({ theme }) => theme.spacing(2)};
	right: ${({ theme }) => theme.spacing(10)};
	max-width: 460px;
	background-color: ${({ theme }) => theme.palette.common.white};
	z-index: 9999;
`;

export const PopoverArrow = styled.div<{ theme?: Theme }>`
	height: 1rem;
	width: 1rem;
	background-color: ${({ theme }) => theme.palette.common.white};
	position: absolute;
	left: 50%;
	top: -1px;
	border-left: 1px solid ${({ theme }) => theme.palette.primary.light};
	border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
	transform: translate(-50%, -50%) rotate(45deg);
`;

export const CloseICon = styled(MUICloseIcon)<{ theme?: Theme }>`
	color: ${({ theme }) => theme.palette.primary.light};
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	cursor: pointer;
`;
