import { IconContainer } from './icon.styled';

export const LightThemeIcon = () => (
	<IconContainer mt="1px">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16.102"
			height="17.438"
			viewBox="0 0 16.102 17.438"
		>
			<g id="Group_2305" data-name="Group 2305" transform="translate(1.366 1)">
				<g
					id="Ellipse_12"
					data-name="Ellipse 12"
					transform="translate(3.597 4.533)"
					fill="none"
					stroke="#fdfdfd"
					strokeWidth="2"
				>
					<circle cx="3.088" cy="3.088" r="3.088" stroke="none" />
					<circle cx="3.088" cy="3.088" r="2.088" fill="none" />
				</g>
				<g id="Group_2302" data-name="Group 2302" transform="translate(6.685 0)">
					<path
						id="Path_1337"
						data-name="Path 1337"
						d="M0,2.47V0"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						id="Path_1338"
						data-name="Path 1338"
						d="M0,2.47V0"
						transform="translate(0 12.967)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
				<g id="Group_2303" data-name="Group 2303" transform="translate(13.369 3.859) rotate(60)">
					<path
						id="Path_1337-2"
						data-name="Path 1337"
						d="M0,2.47V0"
						transform="translate(0)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						id="Path_1338-2"
						data-name="Path 1338"
						d="M0,2.47V0"
						transform="translate(0 12.968)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
				<g id="Group_2304" data-name="Group 2304" transform="translate(13.369 11.578) rotate(120)">
					<path
						id="Path_1337-3"
						data-name="Path 1337"
						d="M0,2.47V0"
						transform="translate(0 0)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						id="Path_1338-3"
						data-name="Path 1338"
						d="M0,2.47V0"
						transform="translate(0 12.968)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
			</g>
		</svg>
	</IconContainer>
);
