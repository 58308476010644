/* eslint-disable max-len */
export const DangerousIconBig = () => (
	<svg
		id="ic-nighthawk-dangerous"
		xmlns="http://www.w3.org/2000/svg"
		width="28"
		height="28"
		viewBox="0 0 28 28"
	>
		<circle id="Ellipse_7" data-name="Ellipse 7" cx="14" cy="14" r="14" fill="#c30303" />
		<path
			id="Union_28"
			data-name="Union 28"
			d="M11.578,11.888v0l-1.289.48.249-3.917L6.338,6.26,0,10.1,1.8,6.951,5.776,0,10.3,2.528l.1-.508.023-.121.118-.616L11.578.433l1.039.849.142.737.1.508L17.38,0l3.975,6.951,1.8,3.146L16.817,6.26l-4.2,2.191.249,3.917Z"
			transform="translate(2.422 7.816)"
			fill="#fff"
			stroke="rgba(0,0,0,0)"
			strokeWidth="1"
		/>
	</svg>
);
