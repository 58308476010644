import { toastError } from '../components/toasts/toasts.component';

export function customErrorHandler(error: any): void {
	if (error.message.includes('FirebaseError')) {
		if (error.message.includes('popup-closed') || error.message.includes('cancelled-popup')) {
			return;
		}

		const err = error.message.split('FirebaseError:')[1].split(':')[1].split('-').join(' ');

		toastError(err);
	} else if (error.response?.data?.message) {
		const err = error.response?.data?.message;

		if (Array.isArray(err)) {
			for (const i in err) {
				toastError(err[i]);
			}
		} else {
			toastError(err);
		}
	} else {
		toastError('Something went wrong!');
	}
}
