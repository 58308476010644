import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as Styled from './trusted-list.styled';
import { EType, ITrustedList, Iuuid } from '../../../services/trusted-list.types';
import { trustedListService } from '../../../services/trusted-list.service';
import StyledPreloader from '../../common/components/preloader/preloader';
import { formatTime } from '../../common/utils/format-date.util';
import * as GlobalTypography from '../../common/components/global-typography';
import { EFields, ISortOptions } from '../types';
import { getSortedList, renderTrustedItemIcon, sortHelper, typesOrder } from '../trust-list.util';
import TableSortIcon from './table-sort-icon.component';
import { customErrorHandler } from '../../common/utils/custom-error-handler.util';

interface IProps {
	filter?: string;
}

const TrustedTable: React.FC<IProps> = ({ filter = '' }) => {
	const { data, refetch, isLoading } = trustedListService.useAllQuery<ITrustedList[]>();

	const [currentSortOptions, setCurrentSortOptions] = useState<ISortOptions<ITrustedList>>({
		field: null,
		type: null
	});
	const [currentType, setCurrentType] = useState<EType | null>(null);

	const { mutate: remove } = useMutation<any, AxiosError, Iuuid>(
		(req: Iuuid) => trustedListService.removeTrustedItem(req),
		{
			onSuccess: () => {
				window.postMessage(
					{
						type: process.env.REACT_APP_TRUSTED_LIST_WEB,
						shouldUpdateCache: true
					},
					'*'
				);
				refetch();
			},
			onError: customErrorHandler
		}
	);
	const handleSort = (field: EFields) => sortHelper<ITrustedList>(field, setCurrentSortOptions);
	const handleCurrentType = (filteredList: ITrustedList[]) => {
		handleSort(EFields.TYPE)();
		setCurrentType((prev) => {
			if (!prev) return EType.WEBSITE;
			const currentIdx = typesOrder.indexOf(prev);
			const leftTypes = typesOrder.slice(currentIdx + 1);
			const nextCurrentType = leftTypes.reduce(
				(acc, el) => (!acc && filteredList.filter((item) => item.type === el).length ? el : acc),
				'' as EType
			);
			if (nextCurrentType) {
				return nextCurrentType;
			}
			return typesOrder[0];
		});
	};

	if (isLoading) {
		return <StyledPreloader />;
	}

	const trustList = data || [];
	const filteredList = trustList.filter((el) => el.url.includes(filter || ''));
	const sortedList = getSortedList<ITrustedList>(
		currentSortOptions,
		filteredList,
		currentType as EType
	);

	return (
		<Styled.TableContainer maxHeight="500px">
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<Styled.SortTableCell onClick={() => handleCurrentType(filteredList)}>
							<Styled.TableHeadContainer>
								<GlobalTypography.Text
									variant="inherit"
									colorVariant="secondary.light"
									fontSize="0.875rem"
								>
									Type
								</GlobalTypography.Text>
								<TableSortIcon fieldName={EFields.TYPE} sortOptions={currentSortOptions} />
							</Styled.TableHeadContainer>
						</Styled.SortTableCell>
						<Styled.SortTableCell onClick={handleSort(EFields.NAME)}>
							<Styled.TableHeadContainer>
								<GlobalTypography.Text
									variant="inherit"
									colorVariant="secondary.light"
									fontSize="0.875rem"
								>
									Name
								</GlobalTypography.Text>
								<TableSortIcon fieldName={EFields.NAME} sortOptions={currentSortOptions} />
							</Styled.TableHeadContainer>
						</Styled.SortTableCell>
						<Styled.SortTableCell onClick={handleSort(EFields.DATE)}>
							<Styled.TableHeadContainer>
								<GlobalTypography.Text
									variant="inherit"
									colorVariant="secondary.light"
									fontSize="0.875rem"
								>
									Date Added
								</GlobalTypography.Text>
								<TableSortIcon fieldName={EFields.DATE} sortOptions={currentSortOptions} />
							</Styled.TableHeadContainer>
						</Styled.SortTableCell>
						<TableCell>
							<GlobalTypography.Text
								variant="inherit"
								fontSize="0.875rem"
								colorVariant="secondary.light"
								pb="1px"
							>
								Comment
							</GlobalTypography.Text>
						</TableCell>
						<TableCell align="right" />
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedList.map((el) => (
						<TableRow key={el.id}>
							<TableCell width="50px">
								<Box display="flex" alignItems="center" justifyContent="center" width="36px">
									{renderTrustedItemIcon(el.type)}
								</Box>
							</TableCell>
							<Styled.TableCellLink>
								<Box display="flex">
									<GlobalTypography.Text variant="subtitle2" colorVariant="secondary.dark">
										{el.url}
									</GlobalTypography.Text>
								</Box>
							</Styled.TableCellLink>
							<TableCell width="180px">
								<Box display="flex">
									<GlobalTypography.Text variant="body2" colorVariant="secondary.light">
										{formatTime(el.createdAt)}
									</GlobalTypography.Text>
								</Box>
							</TableCell>
							<TableCell>
								<Box display="flex">
									<GlobalTypography.Text variant="body2" colorVariant="secondary.light">
										{el.comment}
									</GlobalTypography.Text>
								</Box>
							</TableCell>
							<TableCell align="right">
								<Styled.Cross onClick={() => remove({ id: el.id })} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Styled.TableContainer>
	);
};

export default TrustedTable;
