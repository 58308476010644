export class FileService {
	public async convertBase64(file: File) {
		return new Promise<string>((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(file);
		});
	}
}

export const fileService = new FileService();
