export const TopLogoSvg = ({ width = "130.3", height = "22.477 " }) => {
  return (
    <svg
      id="Group_2123"
      data-name="Group 2123"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 170.3 27.477"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1017"
            data-name="Rectangle 1017"
            width="170.3"
            height="27.477"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Path_966"
        data-name="Path 966"
        d="M814.77,42.892l-2.517-4.174h2.517Z"
        transform="translate(-696.981 -33.223)"
        fill="#fff"
      />
      <path
        id="Path_967"
        data-name="Path 967"
        d="M865.78,42.892V38.718h2.5Z"
        transform="translate(-742.911 -33.223)"
        fill="#fff"
      />
      <path
        id="Path_968"
        data-name="Path 968"
        d="M814.77,84.784l-2.517,4.19h2.517Z"
        transform="translate(-696.981 -72.752)"
        fill="#fff"
      />
      <path
        id="Path_969"
        data-name="Path 969"
        d="M865.78,84.784l2.5,4.19h-2.5Z"
        transform="translate(-742.911 -72.752)"
        fill="#fff"
      />
      <path
        id="Path_970"
        data-name="Path 970"
        d="M815.482,44.072l-3.228-5.354V49.446Z"
        transform="translate(-696.982 -33.224)"
        fill="#fff"
      />
      <path
        id="Path_971"
        data-name="Path 971"
        d="M860.66,44.072l3.228-5.354V49.446Z"
        transform="translate(-738.518 -33.224)"
        fill="#fff"
      />
      <path
        id="Path_972"
        data-name="Path 972"
        d="M834.342,76.457H830.7l-.71,1.182h5.064Z"
        transform="translate(-712.199 -65.606)"
        fill="#fff"
      />
      <path
        id="Path_973"
        data-name="Path 973"
        d="M834.342,69.322H830.7l-.71-1.182h5.064Z"
        transform="translate(-712.199 -58.47)"
        fill="#fff"
      />
      <path
        id="Path_974"
        data-name="Path 974"
        d="M815.482,44.072l-3.228-5.354V49.446Z"
        transform="translate(-696.982 -33.224)"
        fill="#fff"
      />
      <path
        id="Path_975"
        data-name="Path 975"
        d="M194.136,0,184.087,5.615l-.531-2.767L181.246.963V19.979l11.641-6.073,14.08,8.526Z"
        transform="translate(-155.524 0)"
        fill="#fff"
      />
      <path
        id="Path_976"
        data-name="Path 976"
        d="M183.87,10.309l-.314-1.639-2.311-1.886v6.849Z"
        transform="translate(-155.523 -5.821)"
        fill="#fff"
      />
      <path
        id="Path_977"
        data-name="Path 977"
        d="M181.246,123.119V132l2.861,1.066-.638-10.013Z"
        transform="translate(-155.524 -105.591)"
        fill="#fff"
      />
      <g id="Group_2122" data-name="Group 2122">
        <g id="Group_2121" data-name="Group 2121" clip-path="url(#clip-path)">
          <path
            id="Path_978"
            data-name="Path 978"
            d="M182.495,10.432l1.238-6.661.354,1.844L194.135,0l-1.249,13.906-11.641,6.073v-9.2c.7-.193,1.25-.348,1.25-.348"
            transform="translate(-155.523)"
            fill="#fff"
          />
          <path
            id="Path_979"
            data-name="Path 979"
            d="M181.245,6.784v.751l2.311,1.134Z"
            transform="translate(-155.523 -5.821)"
            fill="#fff"
          />
          <path
            id="Path_980"
            data-name="Path 980"
            d="M181.245,177.5v1.221l2.861,1.066Z"
            transform="translate(-155.523 -152.307)"
            fill="#fff"
          />
          <path
            id="Path_981"
            data-name="Path 981"
            d="M184.087,22.838l-2.842,2.224,2.311-4.991Z"
            transform="translate(-155.523 -17.223)"
            fill="#fff"
          />
          <path
            id="Path_982"
            data-name="Path 982"
            d="M184.087,39.566l1.364,6.388-1.9,6.772-2.307,1.2V41.763Z"
            transform="translate(-155.523 -33.951)"
            fill="#fff"
          />
          <path
            id="Path_983"
            data-name="Path 983"
            d="M206.836,86.484l-7.436-1.9-1.9,6.772Z"
            transform="translate(-169.473 -72.578)"
            fill="#fff"
          />
          <path
            id="Path_984"
            data-name="Path 984"
            d="M218.794,60.3l-.48,5.348-7.436-1.9Z"
            transform="translate(-180.95 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_985"
            data-name="Path 985"
            d="M181.245,133.5l2.861,7.5-.554-8.7Z"
            transform="translate(-155.523 -113.522)"
            fill="#fff"
          />
          <path
            id="Path_986"
            data-name="Path 986"
            d="M263.755,60.3l13.6,13.874-14.08-8.526Z"
            transform="translate(-225.911 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_987"
            data-name="Path 987"
            d="M185.45,84.581l-4.205,7.975,2.307-1.2Z"
            transform="translate(-155.523 -72.577)"
            fill="#fff"
          />
          <path
            id="Path_988"
            data-name="Path 988"
            d="M268.747,65.391l-5.473.255.48-5.348Z"
            transform="translate(-225.911 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_989"
            data-name="Path 989"
            d="M306.446,97.978l-4.61-1.792,8.608,8.781Z"
            transform="translate(-259.001 -82.536)"
            fill="#fff"
          />
          <path
            id="Path_990"
            data-name="Path 990"
            d="M14.081,13.906,0,22.432,12.832,0,22.88,5.615l.531-2.767L25.722.963V19.979Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_991"
            data-name="Path 991"
            d="M162.746,10.309l.314-1.639,2.311-1.886v6.849Z"
            transform="translate(-139.65 -5.821)"
            fill="#fff"
          />
          <path
            id="Path_992"
            data-name="Path 992"
            d="M163.944,123.119V132l-2.861,1.066.638-10.013Z"
            transform="translate(-138.222 -105.591)"
            fill="#fff"
          />
          <path
            id="Path_993"
            data-name="Path 993"
            d="M102.057,10.432l-1.238-6.661-.354,1.844L90.417,0l1.249,13.906,11.641,6.073v-9.2c-.7-.193-1.25-.348-1.25-.348"
            transform="translate(-77.585)"
            fill="#fff"
          />
          <path
            id="Path_994"
            data-name="Path 994"
            d="M167.272,6.784v.751L164.962,8.67Z"
            transform="translate(-141.551 -5.821)"
            fill="#fff"
          />
          <path
            id="Path_995"
            data-name="Path 995"
            d="M163.944,177.5v1.221l-2.861,1.066Z"
            transform="translate(-138.222 -152.307)"
            fill="#fff"
          />
          <path
            id="Path_996"
            data-name="Path 996"
            d="M161.221,22.838l2.842,2.224-2.311-4.991Z"
            transform="translate(-138.341 -17.223)"
            fill="#fff"
          />
          <path
            id="Path_997"
            data-name="Path 997"
            d="M99.215,86.484l7.436-1.9,1.9,6.772Z"
            transform="translate(-85.135 -72.578)"
            fill="#fff"
          />
          <path
            id="Path_998"
            data-name="Path 998"
            d="M95.831,60.3l.48,5.348,7.436-1.9Z"
            transform="translate(-82.231 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_999"
            data-name="Path 999"
            d="M163.944,133.5l-2.861,7.5.554-8.7Z"
            transform="translate(-138.222 -113.522)"
            fill="#fff"
          />
          <path
            id="Path_1000"
            data-name="Path 1000"
            d="M13.6,60.3,0,74.172l14.08-8.526Z"
            transform="translate(0 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_1001"
            data-name="Path 1001"
            d="M151.613,84.581l4.205,7.975-2.307-1.2Z"
            transform="translate(-130.096 -72.577)"
            fill="#fff"
          />
          <path
            id="Path_1002"
            data-name="Path 1002"
            d="M60.653,65.391l5.473.255-.48-5.348Z"
            transform="translate(-52.045 -51.741)"
            fill="#fff"
          />
          <path
            id="Path_1003"
            data-name="Path 1003"
            d="M4,97.978l4.61-1.792L0,104.967Z"
            transform="translate(0 -82.536)"
            fill="#fff"
          />
          <path
            id="Path_1004"
            data-name="Path 1004"
            d="M185.465,45.954l-4.205-1.223V41.762l2.842-2.2Z"
            transform="translate(-155.536 -33.951)"
            fill="#fff"
          />
          <path
            id="Path_1005"
            data-name="Path 1005"
            d="M185.452,77.187l-4.205-1.223,0,9.2Z"
            transform="translate(-155.523 -65.183)"
            fill="#fff"
          />
          <path
            id="Path_1006"
            data-name="Path 1006"
            d="M151.636,77.187l4.205-1.223,0,9.2Z"
            transform="translate(-130.116 -65.183)"
            fill="#fff"
          />
          <path
            id="Path_1007"
            data-name="Path 1007"
            d="M90.417,0l10.048,5.615L99.1,12,91.185,8.557Z"
            transform="translate(-77.585 0)"
            fill="#fff"
          />
          <path
            id="Path_1008"
            data-name="Path 1008"
            d="M151.613,45.954l4.205-1.223V41.762l-2.842-2.2Z"
            transform="translate(-130.096 -33.951)"
            fill="#fff"
          />
          <path
            id="Path_1009"
            data-name="Path 1009"
            d="M394.449,42.969l-2.517-4.251h1.918Z"
            transform="translate(-336.311 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1010"
            data-name="Path 1010"
            d="M444.81,84.244l.584,4.266h1.918Z"
            transform="translate(-381.684 -72.289)"
            fill="#fff"
          />
          <path
            id="Path_1011"
            data-name="Path 1011"
            d="M444.81,38.718h2.5V49.445l-2.5-4.266Z"
            transform="translate(-381.684 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1012"
            data-name="Path 1012"
            d="M394.449,68.67v6.477h-2.517Z"
            transform="translate(-336.311 -58.924)"
            fill="#fff"
          />
          <path
            id="Path_1013"
            data-name="Path 1013"
            d="M444.81,45.179l2.5-6.461h-2.5Z"
            transform="translate(-381.684 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1014"
            data-name="Path 1014"
            d="M409.667,68.67l4.987,2.21.584,4.266Z"
            transform="translate(-351.528 -58.924)"
            fill="#fff"
          />
          <path
            id="Path_1015"
            data-name="Path 1015"
            d="M405.45,38.718l.6,4.251,4.987,2.21Z"
            transform="translate(-347.91 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1016"
            data-name="Path 1016"
            d="M492.23,104.008l-1.259-1.7-1.258,1.7Z"
            transform="translate(-420.215 -87.786)"
            fill="#fff"
          />
          <path
            id="Path_1017"
            data-name="Path 1017"
            d="M490.972,47.742V40.421l-1.259-1.7V49.445Z"
            transform="translate(-420.216 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1018"
            data-name="Path 1018"
            d="M499.836,38.718l-1.258,1.7v7.321l1.259,1.7Z"
            transform="translate(-427.821 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1019"
            data-name="Path 1019"
            d="M1014.644,89.107l-1.32,3.576h2.563Z"
            transform="translate(-869.517 -76.461)"
            fill="#fff"
          />
          <path
            id="Path_1020"
            data-name="Path 1020"
            d="M1067.014,89.107l-1.243,3.576h2.563Z"
            transform="translate(-914.52 -76.461)"
            fill="#fff"
          />
          <path
            id="Path_1021"
            data-name="Path 1021"
            d="M1040.97,42.263l-1.258-3.545h2.517Z"
            transform="translate(-892.159 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1022"
            data-name="Path 1022"
            d="M1022.623,45.869l3.683-3.606-1.258-3.545Z"
            transform="translate(-877.496 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1023"
            data-name="Path 1023"
            d="M989.319,38.718l4.726,7.151-2.286-7.151Z"
            transform="translate(-848.918 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1024"
            data-name="Path 1024"
            d="M989.319,38.718l4.726,7.151-1.32,3.576Z"
            transform="translate(-848.918 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1025"
            data-name="Path 1025"
            d="M1022.623,67.3l3.683-3.606-2.44,7.182Z"
            transform="translate(-877.496 -54.657)"
            fill="#fff"
          />
          <path
            id="Path_1026"
            data-name="Path 1026"
            d="M1049.835,38.718l-1.259,3.545,3.683,3.606Z"
            transform="translate(-899.766 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1027"
            data-name="Path 1027"
            d="M1052.26,67.3l-3.683-3.606,2.44,7.182Z"
            transform="translate(-899.766 -54.657)"
            fill="#fff"
          />
          <path
            id="Path_1028"
            data-name="Path 1028"
            d="M1079.257,38.718l-4.726,7.151,1.32,3.576Z"
            transform="translate(-922.036 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1029"
            data-name="Path 1029"
            d="M1079.257,38.718H1076.8l-2.271,7.151Z"
            transform="translate(-922.036 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1030"
            data-name="Path 1030"
            d="M1128.875,43.23V38.719h-2.517Z"
            transform="translate(-966.509 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1031"
            data-name="Path 1031"
            d="M1163.556,43.629l1.52-4.911h3.13Z"
            transform="translate(-998.428 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1032"
            data-name="Path 1032"
            d="M1144.093,73.823V70.508l1.135,2.118Z"
            transform="translate(-981.727 -60.502)"
            fill="#fff"
          />
          <path
            id="Path_1033"
            data-name="Path 1033"
            d="M1146.855,43.629l-2.762-.4,4.282-4.512Z"
            transform="translate(-981.727 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1034"
            data-name="Path 1034"
            d="M1145.228,72.627l1.627-1.719-2.762-.4Z"
            transform="translate(-981.727 -60.502)"
            fill="#fff"
          />
          <path
            id="Path_1035"
            data-name="Path 1035"
            d="M1128.875,70.509l-2.517,6.216h2.517Z"
            transform="translate(-966.509 -60.502)"
            fill="#fff"
          />
          <path
            id="Path_1036"
            data-name="Path 1036"
            d="M1152.093,85.434l6.8,4.1h-3.161Z"
            transform="translate(-988.592 -73.309)"
            fill="#fff"
          />
          <path
            id="Path_1037"
            data-name="Path 1037"
            d="M1158.891,79.138l-5.172-5.816-1.627,1.719Z"
            transform="translate(-988.591 -62.916)"
            fill="#fff"
          />
          <path
            id="Path_1038"
            data-name="Path 1038"
            d="M1126.359,38.718l2.517,4.512-2.517,6.216Z"
            transform="translate(-966.51 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1039"
            data-name="Path 1039"
            d="M933.083,41.158l-1.535-2.44h3.07Z"
            transform="translate(-799.346 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1040"
            data-name="Path 1040"
            d="M932.9,41.158l-1.535-2.44-.031,6.323Z"
            transform="translate(-799.161 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1041"
            data-name="Path 1041"
            d="M905.538,45.041l-4.266,4.4,4.3-10.727Z"
            transform="translate(-773.366 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1042"
            data-name="Path 1042"
            d="M901.271,87.675h2.517l1.75-4.4Z"
            transform="translate(-773.366 -71.453)"
            fill="#fff"
          />
          <path
            id="Path_1043"
            data-name="Path 1043"
            d="M943.914,45.041l-.015-6.323-1.535,2.44Z"
            transform="translate(-808.628 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1044"
            data-name="Path 1044"
            d="M953.178,38.718l4.281,10.727-4.266-4.4Z"
            transform="translate(-817.906 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1045"
            data-name="Path 1045"
            d="M955.049,87.675h2.5l-4.266-4.4Z"
            transform="translate(-817.997 -71.453)"
            fill="#fff"
          />
          <path
            id="Path_1046"
            data-name="Path 1046"
            d="M931.333,83.27l1.566,2.19,1.55-2.19Z"
            transform="translate(-799.161 -71.453)"
            fill="#fff"
          />
          <path
            id="Path_1047"
            data-name="Path 1047"
            d="M927.634,85.46H925.2l.871-2.19Z"
            transform="translate(-793.897 -71.453)"
            fill="#fff"
          />
          <path
            id="Path_1048"
            data-name="Path 1048"
            d="M944.789,85.45l-2.425.011,1.55-2.19Z"
            transform="translate(-808.627 -71.453)"
            fill="#fff"
          />
          <path
            id="Path_1049"
            data-name="Path 1049"
            d="M722.477,38.718l3.607,2.332h-3.607Z"
            transform="translate(-619.945 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1050"
            data-name="Path 1050"
            d="M765.624,41.051l3.606-2.332v2.332Z"
            transform="translate(-656.969 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1051"
            data-name="Path 1051"
            d="M732.206,38.718h-9.729l4.865,3.146Z"
            transform="translate(-619.945 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1052"
            data-name="Path 1052"
            d="M750.407,104.008l-1.259-1.7-1.258,1.7Z"
            transform="translate(-641.752 -87.786)"
            fill="#fff"
          />
          <path
            id="Path_1053"
            data-name="Path 1053"
            d="M749.148,61.846V55.968l-1.258-.814v8.395l1.258-1.7Z"
            transform="translate(-641.752 -47.327)"
            fill="#fff"
          />
          <path
            id="Path_1054"
            data-name="Path 1054"
            d="M756.758,55.967v5.878l1.258,1.7V55.154Z"
            transform="translate(-649.361 -47.326)"
            fill="#fff"
          />
          <path
            id="Path_1055"
            data-name="Path 1055"
            d="M632.623,42.892l-2.517-4.174h2.517Z"
            transform="translate(-540.684 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1056"
            data-name="Path 1056"
            d="M683.633,42.892V38.718h2.5Z"
            transform="translate(-586.614 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1057"
            data-name="Path 1057"
            d="M632.623,84.784l-2.517,4.19h2.517Z"
            transform="translate(-540.684 -72.752)"
            fill="#fff"
          />
          <path
            id="Path_1058"
            data-name="Path 1058"
            d="M683.633,84.784l2.5,4.19h-2.5Z"
            transform="translate(-586.614 -72.752)"
            fill="#fff"
          />
          <path
            id="Path_1059"
            data-name="Path 1059"
            d="M633.335,44.072l-3.228-5.354V49.446Z"
            transform="translate(-540.684 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1060"
            data-name="Path 1060"
            d="M678.513,44.072l3.228-5.354V49.446Z"
            transform="translate(-582.22 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1061"
            data-name="Path 1061"
            d="M652.2,76.457h-3.644l-.71,1.182h5.064Z"
            transform="translate(-555.902 -65.606)"
            fill="#fff"
          />
          <path
            id="Path_1062"
            data-name="Path 1062"
            d="M652.2,69.322h-3.644l-.71-1.182h5.064Z"
            transform="translate(-555.902 -58.47)"
            fill="#fff"
          />
          <path
            id="Path_1063"
            data-name="Path 1063"
            d="M633.335,44.072l-3.228-5.354V49.446Z"
            transform="translate(-540.684 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1064"
            data-name="Path 1064"
            d="M489.714,38.718l1.259,1.7,1.258-1.7Z"
            transform="translate(-420.215 -33.223)"
            fill="#fff"
          />
          <path
            id="Path_1065"
            data-name="Path 1065"
            d="M391.932,38.718V49.445l2.517-6.476Z"
            transform="translate(-336.311 -33.224)"
            fill="#fff"
          />
          <path
            id="Path_1066"
            data-name="Path 1066"
            d="M588.019,47.253l-.951-1.11-1.535,1.7.538.6Z"
            transform="translate(-502.436 -39.595)"
            fill="#fff"
          />
          <path
            id="Path_1067"
            data-name="Path 1067"
            d="M583.454,41.89l-.976-.7-1.157,2,.6.4Z"
            transform="translate(-498.822 -35.342)"
            fill="#fff"
          />
          <path
            id="Path_1068"
            data-name="Path 1068"
            d="M578.451,38.4l-1.108-.461-.715,2.2.666.265Z"
            transform="translate(-494.795 -32.553)"
            fill="#fff"
          />
          <path
            id="Path_1069"
            data-name="Path 1069"
            d="M573.108,36.433l-1.17-.249-.245,2.33.7.119Z"
            transform="translate(-490.56 -31.049)"
            fill="#fff"
          />
          <path
            id="Path_1070"
            data-name="Path 1070"
            d="M557.909,36.185l.247,2.352-.687.146-.731-2.25Z"
            transform="translate(-477.728 -31.049)"
            fill="#fff"
          />
          <path
            id="Path_1071"
            data-name="Path 1071"
            d="M544.223,43.438l-1.2-2.071-.994.675,1.636,1.816Z"
            transform="translate(-465.11 -35.497)"
            fill="#fff"
          />
          <path
            id="Path_1072"
            data-name="Path 1072"
            d="M534.744,53.954l-1.985-1.442-.613,1.034,2.279,1.015Z"
            transform="translate(-456.625 -45.059)"
            fill="#fff"
          />
          <path
            id="Path_1073"
            data-name="Path 1073"
            d="M531.28,68.423l-2.419-.514-.147,1.19h2.505Z"
            transform="translate(-453.68 -58.272)"
            fill="#fff"
          />
          <path
            id="Path_1074"
            data-name="Path 1074"
            d="M532.184,81.292l-2.432.481.347,1.138,2.272-1Z"
            transform="translate(-454.572 -69.755)"
            fill="#fff"
          />
          <path
            id="Path_1075"
            data-name="Path 1075"
            d="M538.482,89.877l-1.989,1.437.812.889,1.617-1.8Z"
            transform="translate(-460.356 -77.122)"
            fill="#fff"
          />
          <path
            id="Path_1076"
            data-name="Path 1076"
            d="M550.237,96.563l-1.2,2.08,1.093.487.736-2.264Z"
            transform="translate(-471.119 -82.859)"
            fill="#fff"
          />
          <path
            id="Path_1077"
            data-name="Path 1077"
            d="M565.234,99.766l-.248,2.361h1.2l-.247-2.347Z"
            transform="translate(-484.805 -85.608)"
            fill="#fff"
          />
          <path
            id="Path_1078"
            data-name="Path 1078"
            d="M576.628,97.4l.715,2.2,1.093-.487-1.156-2Z"
            transform="translate(-494.795 -83.326)"
            fill="#fff"
          />
          <path
            id="Path_1079"
            data-name="Path 1079"
            d="M585.282,90.787l1.527,1.719.782-.889-1.879-1.387Z"
            transform="translate(-502.221 -77.425)"
            fill="#fff"
          />
          <path
            id="Path_1080"
            data-name="Path 1080"
            d="M590.292,84.864l2.179.923.34-1.107Z"
            transform="translate(-506.52 -72.663)"
            fill="#fff"
          />
          <path
            id="Path_1081"
            data-name="Path 1081"
            d="M592.931,76.521l-2.64,1.342,2.519-.184Z"
            transform="translate(-506.519 -65.661)"
            fill="#fff"
          />
          <path
            id="Path_1082"
            data-name="Path 1082"
            d="M588.589,85.988l0-.013,2.179.923-.581,1.068-1.879-1.387Z"
            transform="translate(-504.821 -73.773)"
            fill="#fff"
          />
          <path
            id="Path_1083"
            data-name="Path 1083"
            d="M581.794,94.154l1.527,1.719-.947.7-1.157-2Z"
            transform="translate(-498.733 -80.792)"
            fill="#fff"
          />
          <path
            id="Path_1084"
            data-name="Path 1084"
            d="M572.382,99.15l.715,2.2-1.17.249-.247-2.347Z"
            transform="translate(-490.549 -85.079)"
            fill="#fff"
          />
          <path
            id="Path_1085"
            data-name="Path 1085"
            d="M558.157,98.848l-.248,2.361-1.171-.249.736-2.264Z"
            transform="translate(-477.728 -84.689)"
            fill="#fff"
          />
          <path
            id="Path_1086"
            data-name="Path 1086"
            d="M544.384,94.034l-1.2,2.08-.968-.7,1.617-1.8Z"
            transform="translate(-465.266 -80.33)"
            fill="#fff"
          />
          <path
            id="Path_1087"
            data-name="Path 1087"
            d="M534.795,86.244l-1.989,1.437-.61-1.036,2.272-1Z"
            transform="translate(-456.669 -73.489)"
            fill="#fff"
          />
          <path
            id="Path_1088"
            data-name="Path 1088"
            d="M531.292,77l-2.432.481-.148-1.19h2.505Z"
            transform="translate(-453.679 -65.466)"
            fill="#fff"
          />
          <path
            id="Path_1089"
            data-name="Path 1089"
            d="M532.171,61.463l.2-.651L530.092,59.8l-.34,1.151Z"
            transform="translate(-454.571 -51.311)"
            fill="#fff"
          />
          <path
            id="Path_1090"
            data-name="Path 1090"
            d="M538.452,48.472l.44-.533-1.635-1.816-.79.907Z"
            transform="translate(-460.333 -39.577)"
            fill="#fff"
          />
          <path
            id="Path_1091"
            data-name="Path 1091"
            d="M550.232,40.5l.629-.308-.731-2.25-1.093.487Z"
            transform="translate(-471.119 -32.553)"
            fill="#fff"
          />
          <path
            id="Path_1092"
            data-name="Path 1092"
            d="M565.233,38.536l.7-.022.245-2.33h-1.2Z"
            transform="translate(-484.805 -31.049)"
            fill="#fff"
          />
          <path
            id="Path_1093"
            data-name="Path 1093"
            d="M592.916,70.834l-2.624,2.149,2.639-1.342Z"
            transform="translate(-506.52 -60.781)"
            fill="#fff"
          />
          <path
            id="Path_1094"
            data-name="Path 1094"
            d="M572.453,70.834l2.532,2.149,2.624-2.149Z"
            transform="translate(-491.212 -60.781)"
            fill="#fff"
          />
          <path
            id="Path_1095"
            data-name="Path 1095"
            d="M572.453,70.834l2.532,2.149h-2.532Z"
            transform="translate(-491.212 -60.781)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
