import React from 'react';
import { useHistory } from 'react-router';
import { APP_KEYS } from '../consts';
import { useAuthSyncData } from '../../context/auth-sync.context';
import { localStorageService } from '../services/local-storage.service';

const userPublicPage = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
	// Try to create a nice displayName for React Dev Tools.
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const Component = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
		const history = useHistory();
		const { userToken, guestToken } = useAuthSyncData();

		if (typeof window !== 'undefined') {
			if (userToken) {
				localStorageService.removeAuthRedirectUrlFromStorage();
				history.push(APP_KEYS.ROUTER_KEYS.TRUSTED_LIST);
				return null;
			}
			// if (guestToken) {
			// 	localStorageService.removeAuthRedirectUrlFromStorage();
			// 	history.push(APP_KEYS.ROUTER_KEYS.USER_GUIDE);
			// 	return null;
			// }

			// @ts-ignore
			return <WrappedComponent {...(props as T)} />;
		}

		return null;
	};

	Component.displayName = `${displayName}`;

	return Component;
};

export default userPublicPage;
