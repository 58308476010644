import React from 'react';
import { Grid } from '@mui/material';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import { EnterEmail } from './components/enter-email.component';
import { APP_KEYS } from '../../common/consts';
import { LinkTemplate } from '../../common/components/styled/link-template.styled';
import * as GlobalTypography from '../../common/components/global-typography';

const ResetPasswordForm = () => {
	const title =
		// eslint-disable-next-line max-len
		'Enter the email address associated with your account, and we’ll send you an email with the option to sign in or reset your password.';

	return (
		<AuthPageWrapper>
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
				maxWidth="27.5rem"
			>
				<AuthPageActionCardWrapper title="Forgot your password?" subSmallText={title}>
					<Grid container direction="column" alignItems="center" justifyContent="center">
						<EnterEmail />
						<LinkTemplate to={APP_KEYS.ROUTER_KEYS.SIGN_IN}>
							<GlobalTypography.Text
								variant="body1"
								colorVariant="primary.contrastText"
								fontWeight="fontWeightMedium"
								mb="2rem"
							>
								Back to Sign In
							</GlobalTypography.Text>
						</LinkTemplate>
					</Grid>
				</AuthPageActionCardWrapper>
			</Grid>
		</AuthPageWrapper>
	);
};

export default ResetPasswordForm;
