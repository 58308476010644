import React from 'react';
import * as GlobalTypography from '../../global-typography';
import * as Styled from './footer.styled';
import { FooterLink } from './components/footer-link';
import { ROUTER_KEYS } from '../../../consts/app-keys.const';

export const Footer = () => (
	<Styled.FooterContainer container alignItems="center" justifyContent="end">
		<GlobalTypography.Text variant="body2" colorVariant="secondary" fontWeight="fontWeightMedium">
			<FooterLink to={ROUTER_KEYS.TERMS_AND_CONDITIONS} text="Terms and Conditions" /> |{' '}
			<FooterLink to={ROUTER_KEYS.PRIVACY_POLICY} text="Privacy Policy" /> |{' '}
			<span>{`© ${new Date().getFullYear()} PhishFort`}</span>
		</GlobalTypography.Text>
	</Styled.FooterContainer>
);
