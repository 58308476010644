import { useHistory } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { Grid } from '@mui/material';
import { FormInput, HexagonBtn } from '../../../../common/components';
import { validationSchema } from '../../utils/create-profile.validator-schema';
import { toastSuccess } from '../../../../common/components/toasts/toasts.component';
import { APP_KEYS } from '../../../../common/consts';
import { profileService } from '../../../../profile/services/profile.service';
import { IUserInfo } from '../../../../profile/services/profile.types';
import { customErrorHandler } from '../../../../common/utils/custom-error-handler.util';

interface ICreateProfileUserInfo {
	username: string;
}

export const CreateProfileForm = () => {
	const { push } = useHistory();

	const { mutate: updateUser } = useMutation<any, AxiosError, ICreateProfileUserInfo>(
		({ username }) =>
			profileService.updateUserProfile({
				username
			} as IUserInfo),
		{
			onSuccess: () => {
				toastSuccess('Welcome!');
				push(`${APP_KEYS.ROUTER_KEYS.USER_GUIDE}?popover=true`);
			},
			onError: customErrorHandler
		}
	);

	const formik = useFormik({
		initialValues: { username: '' },
		onSubmit: ({ username }) => {
			updateUser({
				username
			});
		},
		validationSchema
	});

	const isValidButton =
		!Object.keys(formik.errors).length && !!Object.values(formik.values).filter(Boolean).length;

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<FormInput
						name="username"
						type="text"
						passedValue=""
						placeholder="Create a unique username"
						label="Username"
					/>
					<Grid item mt="1.25rem">
						<HexagonBtn
							disabled={!isValidButton}
							variant="filled"
							width="200px"
							title="Save Username"
						/>
					</Grid>
				</Grid>
			</form>
		</FormikProvider>
	);
};
