import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import img from '../../../../assets/images/bg-low-poly-purple-small.png';
import { COLORS, FONTS, SPACES } from '../../../theme';

export const NavContainer = styled(Grid)<{ theme?: Theme }>`
	position: fixed;
	background-image: url(${img});
	background-size: cover;
	max-width: 280px;
	padding: ${({ theme }) => theme.spacing(4)};
	min-height: 100vh;
`;
export const Logo = styled.img<{ theme?: Theme }>`
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
export const MenuItems = styled(Grid)<{ theme?: Theme }>`
	margin-top: ${({ theme }) => theme.spacing(3)};
	margin-left: ${({ theme }) => theme.spacing(3)};
`;
export const BottomBlock = styled(Grid)<{ theme?: Theme }>`
	margin: ${({ theme }) => `0 ${theme.spacing(2)}`};
`;
export const SignButton = styled(Grid)`
	cursor: pointer;
	font-size: ${FONTS.SIZES.m};
	font-weight: ${FONTS.WEIGHTS.medium};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.white};
	margin-top: ${SPACES.xxxxxs};
`;
