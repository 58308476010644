import styled from '@emotion/styled';
import { TextField, Theme } from '@mui/material';

export const Input = styled(TextField)<{ theme?: Theme }>`
	& .MuiInputBase-root {
		min-width: 396px;
		min-height: 46px;
		padding-top: 0;
		padding-bottom: 3px;
		padding-left: 4px;
		margin-left: -8px;
		font-size: 17px;
		font-family: 'IBM Plex Sans', sans-serif;
	}

	::placeholder {
		color: ${({ theme }) => theme.palette.secondary.contrastText};
		opacity: 0.5;
	}
	&:-webkit-autofill {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:hover {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:focus {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
`;
