import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './toast.styled';
import * as GlobalTypography from '../global-typography';
import { useToastData } from '../../../context/toast.context';
import { EType } from '../../../../services/trusted-list.types';
import { renderToastIcon } from './utils/toast-icon.util';
import { getValidUrl } from '../../../utils';

interface IProps {
	url: string;
	type: EType | null;
}

export const TrustedToast: FC<IProps> = ({ url, type }) => {
	const { setTrustedToast } = useToastData();

	const handleClose = () => {
		setTrustedToast({ type: null, url: '' });
	};

	useEffect(() => {
		setTimeout(() => setTrustedToast({ type: null, url: '' }), 3000);
	}, []);

	const shortUrl = url ? getValidUrl(url) : '';

	return (
		<Styled.ToastContainer container direction="row">
			<Styled.CloseIcon onClick={handleClose} />
			<Grid item container xs={1} justifyContent="center" pt="0.875rem">
				{renderToastIcon(type)}
			</Grid>
			<Grid item container xs={11} pl="1.25rem" pt="0.75rem">
				<GlobalTypography.Text variant="body1" colorVariant="common.white">
					<Styled.Url>{shortUrl}</Styled.Url>
					&nbsp; has been added to your Trust List
				</GlobalTypography.Text>
			</Grid>
		</Styled.ToastContainer>
	);
};
