import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import * as Styled from './guard-points.styled';
import HexagonPoints from '../../common/components/hexagon-points';
import * as GlobalTypography from '../../common/components/global-typography';
import { useAuthSyncData } from '../../context/auth-sync.context';
import { APP_KEYS } from '../../common/consts';
import { queryClient } from '../../app';

interface IGuardPoints {
	points?: string;
	isLogin?: boolean;
}

const MIN_WINDOW_WIDTH = 1480;

export const GuardPoints: React.FC<IGuardPoints> = ({ points, isLogin }) => {
	const { removeToken } = useAuthSyncData();
	const { push } = useHistory();
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const element = (ref.current ? ref.current : null) as HTMLElement | null;
	const right = element ? element.getBoundingClientRect().right : null;
	const windowWidth = window.innerWidth;
	const rightOffset = windowWidth < MIN_WINDOW_WIDTH && right ? windowWidth - right : null;

	const handleSignIn = () => {
		removeToken();
		queryClient.clear();
		push(APP_KEYS.ROUTER_KEYS.SIGN_IN);
	};

	const handleCreateAccount = () => {
		removeToken();
		queryClient.clear();
		push(APP_KEYS.ROUTER_KEYS.SIGN_UP);
	};

	const handleOpenTooltip = () => {
		setOpen(true);
	};

	const handleCloseTooltip = () => {
		setOpen(false);
	};

	// todo show guardian points status
	return (
		<Grid item container xs={5} direction="column">
			<Grid item container direction="column" ml="3rem">
				<HexagonPoints title={points} large />
				<Styled.HexagonLabel>
					Guardian Point
					<Styled.Tooltip rightOffset={rightOffset}>
						<span ref={ref} onMouseOver={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
							<Styled.QuestionMarkIcon />
						</span>
						<div className="tooltip-body">
							<p>
								This is a tool that keeps track of your personal contributions towards fighting
								online scams. For every report you submit, you earn one point. Your efforts are
								greatly appreciated and make a difference in the fight against online bad actors.
								Thank you for being a Guardian!
							</p>
						</div>
					</Styled.Tooltip>
				</Styled.HexagonLabel>
			</Grid>

			{!isLogin && (
				<Grid item container justifyContent="start" alignItems="center" mt="1rem">
					<GlobalTypography.Text
						variant="body1"
						align="center"
						colorVariant="secondary"
						fontFamily="IBM Plex Sans"
						mt="0.25rem"
						maxWidth="19.75rem"
					>
						You are not signed into an account. Guardian Points earned without an account are at
						risk of being lost.&nbsp;
						<Styled.InlineLink onClick={handleSignIn}>Sign in</Styled.InlineLink>
						&nbsp;or&nbsp;
						<Styled.InlineLink onClick={handleCreateAccount}>create an account</Styled.InlineLink>
						&nbsp;to keep track of your points.
					</GlobalTypography.Text>
				</Grid>
			)}
		</Grid>
	);
};
