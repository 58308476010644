import * as React from 'react';
import { DangerousIcon } from '../../../common/components/icons/ic-nighthawk-dangerous';
import { TrustedIcon } from '../../../common/components/icons/ic-nighthawk-trusted';
import { UnknownIcon } from '../../../common/components/icons/ic-nighthawk-unknown';
import * as GlobalTypography from '../../../common/components/global-typography';

export const Panel1 = () => (
	<>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			<TrustedIcon /> <b>GREEN</b> - the website is safe to browse.
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			<UnknownIcon /> <b>GRAY</b> - an unknown website is unknown, the NightHawk team recommends
			staying alert using these types of URLs.
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			<DangerousIcon /> <b>RED</b> - the website is malicious, do not interact with these types of
			URLs.
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			NightHawk also marks posts/threads posted by TRUSTED and DANGEROUS accounts.
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			Supported platforms: Twitter, Facebook, Youtube, LinkedIn.
		</GlobalTypography.Text>
	</>
);
