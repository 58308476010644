import { FC } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Styled from './trusted-list.styled';
import { EFields, ESortTypes, IAllSortKeys, ISortOptions } from '../types';

interface IProps {
	fieldName: EFields;
	sortOptions: ISortOptions<IAllSortKeys>;
}

const TableSortIcon: FC<IProps> = ({ fieldName, sortOptions }) => {
	const isSortByAsc = sortOptions.field === fieldName && sortOptions.type === ESortTypes.ASC;
	const isSortByDesc = sortOptions.field === fieldName && sortOptions.type === ESortTypes.DESC;

	return (
		<Styled.TableSortIconContainer>
			<Styled.ArrowContainer isActive={isSortByAsc}>
				<ArrowDropUpIcon />
			</Styled.ArrowContainer>
			<Styled.ArrowContainer isActive={isSortByDesc}>
				<ArrowDropDownIcon />
			</Styled.ArrowContainer>
		</Styled.TableSortIconContainer>
	);
};
export default TableSortIcon;
