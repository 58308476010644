import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import { Link } from '../redirect-btns/redirect.styled';

import img from '../../../../assets/images/bg-low-poly-purple-col-bg@2x.png';

export const FormCardGridContainer = styled(Grid)`
	background-image: url(${img});
	background-attachment: fixed;
	background-size: cover;
	min-height: 100vh;
`;

export const Logo = styled.img<{ theme?: Theme }>`
	margin-top: ${({ theme }) => theme.spacing(2)};
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const PhishPortLogo = styled.img``;

export const LogoContainer = styled.div`
	max-width: 207px;
	min-height: 33px;
`;

export const ContentBox = styled(Grid)<{ theme?: Theme }>`
	width: auto;
	max-width: 40rem;
	padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(9)}`};
	background: ${({ theme }) => theme.palette.common.white};
	border-radius: 6px;
`;

export const StyledLink = styled(Link)<{ theme?: Theme }>`
	margin-top: ${({ theme }) => theme.spacing(2)};
	text-align: center;
`;

export const FooterContent = styled(Grid)<{ theme?: Theme; filled?: boolean }>`
	height: 74px;
	background-color: ${({ theme, filled }) =>
		filled ? theme.palette.primary.contrastText : 'none'};
	margin-top: ${({ theme }) => theme.spacing(2)};
`;
