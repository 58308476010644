/* eslint-disable max-len */
import { IconContainer } from './icon.styled';

export const DarkThemeIcon = () => (
	<IconContainer mt="2px">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17.116"
			height="17.116"
			viewBox="0 0 17.116 17.116"
		>
			<path
				id="Subtraction_1"
				data-name="Subtraction 1"
				d="M6.062,12.119A6.06,6.06,0,1,1,7.329.134a3.789,3.789,0,1,0,4.658,4.654,6.194,6.194,0,0,1,.132,1.271A6.065,6.065,0,0,1,6.062,12.119Z"
				transform="translate(4.145 1.213) rotate(14)"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				opacity="0.5"
			/>
		</svg>
	</IconContainer>
);
