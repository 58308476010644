import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { FONTS, SPACES } from '../../../theme';

export const ToggleContainer = styled.div<{ theme?: Theme; color?: string }>`
  display: flex;

  label {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 20px;

    & input {
      opacity: 0;
      width: 0;
      height: 0;

      & + span {
        box-shadow: 0 0 2px ${({ theme, color }) =>
					color ? color : theme.palette.primary.contrastText};
      }

      &:checked + span:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
      }
    }
  }
}
`;

export const Slider = styled.span<{ theme?: Theme; color?: string }>`
  position: absolute;
  cursor: pointer;
  border-radius: 12px;
  border: solid 2px ${({ theme, color }) => color || theme.palette.primary.contrastText};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    top: 1px;
    left: 1px;
    height: 14px;
    width: 14px;
    background-color: ${({ theme, color }) => color || theme.palette.primary.contrastText};
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
`;
export const ToggleLabel = styled.span<{ theme?: Theme; color?: string }>`
	margin-left: ${SPACES.m};
	line-height: ${FONTS.SIZES.xxl};
	font-size: ${FONTS.SIZES.l};
	color: ${({ theme, color }) => color || theme.palette.primary.contrastText};
	font-weight: ${FONTS.WEIGHTS.bold};
`;
