/* eslint-disable max-len */
export const PinIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="26.171" height="36.75" viewBox="0 0 26.171 36.75">
		<defs>
			<clipPath id="clip-path">
				<rect
					id="Rectangle_1055"
					data-name="Rectangle 1055"
					width="26.171"
					height="36.75"
					fill="none"
					stroke="#ababab"
					strokeWidth="3"
				/>
			</clipPath>
		</defs>
		<g id="Group_2228" data-name="Group 2228" transform="translate(0 0)">
			<g
				id="Group_2227"
				data-name="Group 2227"
				transform="translate(0 0)"
				clipPath="url(#clip-path)"
			>
				<line
					id="Line_7"
					data-name="Line 7"
					x2="19.498"
					transform="translate(3.336 1.47)"
					fill="none"
					stroke="#ababab"
					strokeWidth="3"
				/>
				<path
					id="Path_1315"
					data-name="Path 1315"
					d="M6.1.5V16.266l-5.6,5.6H12.739"
					transform="translate(0.969 0.97)"
					fill="none"
					stroke="#ababab"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="3"
				/>
				<path
					id="Path_1316"
					data-name="Path 1316"
					d="M10.879.5V16.266l5.6,5.6H4.241"
					transform="translate(8.222 0.97)"
					fill="none"
					stroke="#ababab"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="3"
				/>
				<line
					id="Line_8"
					data-name="Line 8"
					y2="12.445"
					transform="translate(13.085 22.835)"
					fill="none"
					stroke="#ababab"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="3"
				/>
			</g>
		</g>
	</svg>
);
