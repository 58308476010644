import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import { ScamReportForm } from './components/scam-report-form/index';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import { MainWrapper } from '../common/components';
import * as GlobalTypography from '../common/components/global-typography';
import { Grid } from '@mui/material';

const phishfortMail = 'hello@phishfort.com';

const ScamReport = () => (
	<MainWrapper>
		<PageWrapper title="Report a Scam" plTitle="0.75rem">
			<ScamReportForm />
			<Grid item alignSelf={'end'} width={'100%'}>
				<GlobalTypography.Text
					colorVariant="secondary"
					sx={{
						'& a': {
							fontSize: 'inherit'
						},
						mt: '2.5rem',
						fontSize: '14px',
						maxWidth: '460px',
						textAlign: 'justify'
					}}
				>
					Note: Kindly be aware that if a reported item is placed on the blocklist, it will be
					displayed as a warning banner in the NH extension. However, we do not take further action
					to remove the item. Additionally, we are not accountable for the blocklisting done by
					Google Safe Browsing, Virustotal, or any other institution. If you require takedown
					services, please get in touch with our partner at{' '}
					<a href={`mailto:${phishfortMail}`}>{phishfortMail}</a>.
				</GlobalTypography.Text>
			</Grid>
		</PageWrapper>
	</MainWrapper>
);

export default guestProtectedPage(ScamReport);
