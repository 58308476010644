import * as Styled from './hexagon-points.styled';

interface IProps {
	title?: string;
	large?: boolean;
}

const HexagonPoints: React.FC<IProps> = ({ title = '0', large }) => {
	const { length } = title;

	return (
		<Styled.HexagonContainer large={large} length={length}>
			<span className="title">{title && title}</span>
		</Styled.HexagonContainer>
	);
};

export default HexagonPoints;
