import React from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { APP_KEYS } from '../../consts';
import { Container, SignInBox, Link, Title, GuestLogin } from './redirect.styled';
import { authService } from '../../../../services/user.service';
import StyledPreloader from '../preloader/preloader';
import { useAuthSyncData } from '../../../context/auth-sync.context';
import { customErrorHandler } from '../../utils/custom-error-handler.util';

export const RedirectButtons = ({
	isVisibleWithoutAcc = true,
	isSignUp,
	marginTop
}: {
	isVisibleWithoutAcc?: boolean;
	isSignUp: boolean;
	marginTop?: string;
}) => {
	const { push } = useHistory();
	const { onSetGuestToken } = useAuthSyncData();

	const { mutate, isLoading } = useMutation<any, AxiosError>(() => authService.userGuestAuth(), {
		onSuccess: (res) => {
			onSetGuestToken(res.token);
			push(APP_KEYS.ROUTER_KEYS.USER_GUIDE);
		},
		onError: customErrorHandler
	});
	return (
		<Container>
			<SignInBox marginTop={marginTop}>
				<Title>{isSignUp ? 'Already have an account?' : 'Don’t have an account?'}</Title>
				<Link to={isSignUp ? APP_KEYS.ROUTER_KEYS.SIGN_IN : APP_KEYS.ROUTER_KEYS.SIGN_UP}>
					{isSignUp ? ' Sign in.' : 'Create an Account.'}
				</Link>
			</SignInBox>
			{isLoading && <StyledPreloader />}
			{isVisibleWithoutAcc && (
				<GuestLogin onClick={() => mutate()}>Continue Without an Account</GuestLogin>
			)}
		</Container>
	);
};
