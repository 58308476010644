import { EType } from '../../../services/trusted-list.types';

export const formatTime = (date: Date) => {
	if (!date) return 'No Date';
	return new Date(date).toLocaleString('en-Us', {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	});
};

export const formatURL = (url: string, type: EType) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const isValidUrl = url?.match(urlRegex)?.[0];

	if (!isValidUrl) return 'Not Valid Url';

	let splitUrl = isValidUrl;

	if (type === EType.WEBSITE) {
		splitUrl = splitUrl?.split(/(?<=com)\//)[0];
	}

	return splitUrl.split(/https?:\/\//)[1];
};
