import { STORAGE_KEYS } from '../consts/app-keys.const';
import { ETheme } from '../types/main-container.types';

export class LocalStorageService {
	public setTokenToStorage(token: string) {
		localStorage.setItem(STORAGE_KEYS.TOKEN, token);
		return token;
	}

	public getTokenFromStorage() {
		return localStorage.getItem(STORAGE_KEYS.TOKEN);
	}

	public removeTokenFromStorage() {
		localStorage.removeItem(STORAGE_KEYS.TOKEN);
		localStorage.removeItem(STORAGE_KEYS.GUEST_TOKEN);
	}

	public removeUserTokenFromStorage() {
		localStorage.removeItem(STORAGE_KEYS.TOKEN);
	}

	public removeGuestTokenFromStorage() {
		localStorage.removeItem(STORAGE_KEYS.GUEST_TOKEN);
	}

	public getGuestTokenFromStorage() {
		return localStorage.getItem(STORAGE_KEYS.GUEST_TOKEN);
	}

	public setGuestTokenToStorage(token: string) {
		localStorage.setItem(STORAGE_KEYS.GUEST_TOKEN, token);
		return token;
	}

	public setThemeToStorage(theme: ETheme) {
		localStorage.setItem(STORAGE_KEYS.THEME, theme);
	}

	public getThemeFromStorage() {
		return localStorage.getItem(STORAGE_KEYS.THEME) as ETheme;
	}

	public setSharingDataToStorage(isSharingData: boolean) {
		localStorage.setItem(STORAGE_KEYS.SHARING_DATA, JSON.stringify(isSharingData));
	}

	public getSharingDataFromStorage(): boolean | null {
		const item = localStorage.getItem(STORAGE_KEYS.SHARING_DATA);
		if (item) {
			return JSON.parse(item);
		}
		return null;
	}

	public removeSharingDataFromStorage() {
		return localStorage.removeItem(STORAGE_KEYS.SHARING_DATA);
	}

	public getAuthRedirectUrlFromStorage() {
		return localStorage.getItem(STORAGE_KEYS.AUTH_REDIRECT_URL);
	}

	public removeAuthRedirectUrlFromStorage() {
		return localStorage.removeItem(STORAGE_KEYS.AUTH_REDIRECT_URL);
	}

	public setGuardianPointsToStorage(points: string) {
		return localStorage.setItem(STORAGE_KEYS.GUARDIAN_POINTS, points);
	}

	public getGuardianPointsFromStorage() {
		return localStorage.getItem(STORAGE_KEYS.GUARDIAN_POINTS);
	}

	public removeGuardianPointsFromStorage() {
		return localStorage.removeItem(STORAGE_KEYS.GUARDIAN_POINTS);
	}
}

export const localStorageService = new LocalStorageService();
