import React from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@mui/material';
import * as Styled from './nav-bar.styled';
import NighthawkLogo from '../../../../assets/icons/logo.svg';
import { APP_KEYS } from '../../consts';
import { HexagonBtn } from '../hexagon-button';
import { SwitchToggle } from '../switch-toggle';
import { COLORS } from '../../../theme';
import { DarkThemeIcon } from '../icons/dark-theme-icon';
import { LightThemeIcon } from '../icons/light-theme-icon';
import { useThemeData } from '../../../context/theme.context';
import { ETheme } from '../../types/main-container.types';
import { LinkTemplate } from '../styled/link-template.styled';
import * as GlobalTypography from '../global-typography';
import { useAuthSyncData } from '../../../context/auth-sync.context';
import { queryClient } from '../../../app';

const renderMenuItems = (isLogin: boolean) => [
	{
		title: 'Trust List',
		link: isLogin ? APP_KEYS.ROUTER_KEYS.TRUSTED_LIST : APP_KEYS.ROUTER_KEYS.NIGHTHAWK_TRUSTED_LIST
	},
	{ title: 'Your Account', link: APP_KEYS.ROUTER_KEYS.PROFILE },
	{ title: 'User Guide', link: APP_KEYS.ROUTER_KEYS.USER_GUIDE },
	{ title: 'Connect With Us', link: APP_KEYS.ROUTER_KEYS.CONNECT_US },
	{ title: 'Support Nighthawk', link: APP_KEYS.ROUTER_KEYS.SUPPORT }
];

export const NavBar: React.FC = () => {
	const { theme, onSetTheme } = useThemeData();
	const { push } = useHistory();
	const { removeToken } = useAuthSyncData();
	const { userToken } = useAuthSyncData();
	const isLogin = !!userToken;

	function sign() {
		removeToken();
		queryClient.clear();
		push(APP_KEYS.ROUTER_KEYS.SIGN_UP);
		window.postMessage({ type: process.env.REACT_APP_WEB_LOGOUT });
	}

	return (
		<Styled.NavContainer container direction="column">
			<Styled.Logo src={NighthawkLogo} />
			<LinkTemplate to={APP_KEYS.ROUTER_KEYS.SCAM_REPORT}>
				<HexagonBtn title="Report a Scam" width="220px" />
			</LinkTemplate>
			<Styled.MenuItems item container direction="column" flexGrow={1}>
				{renderMenuItems(isLogin).map(({ title, link }) => (
					<LinkTemplate to={link} key={link}>
						<GlobalTypography.Text
							variant="subtitle2"
							colorVariant="common.white"
							mt="1.25rem"
							fontWeight="fontWeightMedium"
							fontFamily="IBM Plex Sans"
						>
							{title}
						</GlobalTypography.Text>
					</LinkTemplate>
				))}
				{/* visible only for not auth users (guests) */}
				{!isLogin && (
					<LinkTemplate to={APP_KEYS.ROUTER_KEYS.SIGN_UP}>
						<GlobalTypography.Text
							variant="subtitle2"
							colorVariant="common.white"
							mt="1.25rem"
							fontWeight="fontWeightMedium"
							fontFamily="IBM Plex Sans"
							onClick={sign}
						>
							Create An Account
						</GlobalTypography.Text>
					</LinkTemplate>
				)}
			</Styled.MenuItems>
			<Styled.BottomBlock item container justifyContent="space-between" alignItems="center">
				<Styled.SignButton item onClick={sign} xs={6}>
					{/* SIGN OUT or SIGN IN depends on auth user or guest */}
					{isLogin ? 'SIGN OUT' : 'SIGN IN'}
				</Styled.SignButton>
				<Grid item container alignItems="center" xs={6} mt="2px">
					<LightThemeIcon />
					<SwitchToggle
						color={COLORS.white}
						isChecked={theme === ETheme.DARK}
						onChange={onSetTheme}
					/>
					<DarkThemeIcon />
				</Grid>
			</Styled.BottomBlock>
		</Styled.NavContainer>
	);
};
