import React from 'react';
import { Grid } from '@mui/material';
import { DiscordBigIcon } from '../common/components/icons/discord-big-icon';
import { TwitterBigIcon } from '../common/components/icons/twitter-big-icon';
import { MainWrapper } from '../common/components/main-wrapper';
import guestProtectedPage from '../common/hocs/guest-protected.hoc';
import { LinkBlock } from './connect.styled';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import * as GlobalTypography from '../common/components/global-typography';

const ConnectPage = () => (
	<MainWrapper>
		<PageWrapper title="Connect With Us">
			<Grid>
				<GlobalTypography.Text
					variant="subtitle1"
					colorVariant="primary"
					fontWeight="fontWeightBold"
				>
					Have a Question?
				</GlobalTypography.Text>
				<GlobalTypography.Text variant="body1" colorVariant="primary">
					Ask us in our official Discord or Twitter accounts.
				</GlobalTypography.Text>
				<Grid container>
					<LinkBlock href="https://discord.gg/psb9b2FGu9">
						<DiscordBigIcon />
					</LinkBlock>
					<LinkBlock href="https://twitter.com/NighthawkPlugin">
						<TwitterBigIcon />
					</LinkBlock>
				</Grid>
			</Grid>
		</PageWrapper>
	</MainWrapper>
);

export default guestProtectedPage(ConnectPage);
