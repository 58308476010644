export const XIcon = () => (
	<svg
		id="ic-x-in-circle"
		xmlns="http://www.w3.org/2000/svg"
		width="23"
		height="23"
		viewBox="0 0 23 23"
	>
		<circle id="Ellipse_16" data-name="Ellipse 16" cx="11.5" cy="11.5" r="11.5" fill="#c30303" />
		<g id="ic-x-white" transform="translate(8 8)">
			<path
				id="Path_1321"
				data-name="Path 1321"
				d="M0,0H9.9"
				transform="translate(0 0.001) rotate(45)"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="2"
			/>
			<path
				id="Path_1322"
				data-name="Path 1322"
				d="M0,0H9.9"
				transform="translate(0 7) rotate(-45)"
				fill="none"
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="2"
			/>
		</g>
	</svg>
);
