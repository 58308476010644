import { ITrustedList, IWhiteList } from '../../services/trusted-list.types';

export enum EFields {
	TYPE = 'type',
	NAME = 'url',
	DATE = 'createdAt'
}

export enum ESortTypes {
	ASC = 'ASC',
	DESC = 'DESC'
}

export interface ISortOptions<T> {
	field: keyof T | null;
	type: ESortTypes | null;
}

export type IAllSortKeys = IWhiteList & ITrustedList;

export enum ESortDirection {
	BEFORE = 'before',
	AFTER = 'after'
}
