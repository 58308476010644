import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { toastError } from '../components/toasts/toasts.component';

export function useDecoratedQuery<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, TError> {
	const shouldRefetchOnWindowFocus = window.location.pathname.includes('create-profile');
	return useQuery(queryKey, queryFn, {
		onError: (error: any) => {
			toastError(`Something went wrong: ${error.message}`);
		},
		refetchOnWindowFocus: shouldRefetchOnWindowFocus,
		...options
	});
}
