import { Grid } from '@mui/material';
import { APP_KEYS } from '../../../../consts';
import * as Styled from './bottom-links.styled';
import * as GlobalTypography from '../../../global-typography';

export const WelcomeBottomLinks = () => (
	<Grid container maxWidth="30rem">
		<GlobalTypography.Text variant="body1" align="center" colorVariant="common.white">
			This data is aggregated and is therefore anonymous for the purposes of General Data Protection
			Regulation (EU) 2016/679. For more information in relation to our privacy practices, please
			see our&nbsp;
			<Styled.Link to={APP_KEYS.ROUTER_KEYS.PRIVACY_POLICY}>Privacy Policy</Styled.Link> and{' '}
			<Styled.Link to={APP_KEYS.ROUTER_KEYS.TERMS_AND_CONDITIONS}>Terms and Conditions</Styled.Link>
		</GlobalTypography.Text>
	</Grid>
);
