import { FC } from 'react';
import CombineComponents from '../common/components/combine-components';
import { ThemeContextProvider } from './theme.context';
import { AuthSyncContextProvider } from './auth-sync.context';
import { ToastContextProvider } from './toast.context';

const providers = [ThemeContextProvider, AuthSyncContextProvider, ToastContextProvider];
const ContextProvider = CombineComponents(...(providers as FC[]));

export default ContextProvider;
