import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import React from 'react';
import * as GlobalTypography from '../../../common/components/global-typography';
import * as Styled from './popover.styled';
import { PuzzleIcon } from '../../../common/components/icons/puzzle-icom';
import { PinIcon } from '../../../common/components/icons/pin-icom';
import { TrustedIconBig } from '../../../common/components/icons/big-ic-nighthawk-trusted';
import { DangerousIconBig } from '../../../common/components/icons/big-ic-nighthawk-dangerous';
import { UnknownIconBig } from '../../../common/components/icons/big-ic-nighthawk-unknown';
import { APP_KEYS } from '../../../common/consts';

const Popover = () => {
	const { push } = useHistory();

	return (
		<Styled.PopoverContainer>
			<Styled.CloseICon fontSize="large" onClick={() => push(APP_KEYS.ROUTER_KEYS.USER_GUIDE)} />
			<Grid container direction="column" p="3rem">
				<GlobalTypography.Text variant="h4" colorVariant="primary.contrastText" align="center">
					Pin to the browser
				</GlobalTypography.Text>
				<GlobalTypography.Text
					variant="body1"
					colorVariant="secondary.contrastText"
					align="center"
					mt="1rem"
				>
					Pinning Nighthawk to your browser will confirm when you are on trusted sites, and alert
					you when you are on dangerous sites or interacting with dangerous accounts.
				</GlobalTypography.Text>
				<Grid item container direction="column" aliign-items="center">
					<Grid
						item
						container
						alignItems="center"
						justifyContent="space-between"
						mt="1rem"
						columnSpacing={4}
					>
						<Grid item container xs={9}>
							<Grid item xs={2}>
								<GlobalTypography.Text
									variant="h4"
									colorVariant="primary.contrastText"
									align="center"
								>
									1.
								</GlobalTypography.Text>
							</Grid>
							<Grid item xs={10} mt="0.5rem">
								<GlobalTypography.Text variant="subtitle2" colorVariant="secondary.contrastText">
									Click the puzzle icon in the upper right of your browser window
								</GlobalTypography.Text>
							</Grid>
						</Grid>
						<Grid item container xs={3} justifyContent="center" alignItems="center">
							<PuzzleIcon />
						</Grid>
					</Grid>

					<Grid
						item
						container
						alignItems="center"
						justifyContent="space-between"
						mt="1rem"
						columnSpacing={4}
					>
						<Grid item container xs={9}>
							<Grid item xs={2}>
								<GlobalTypography.Text
									variant="h4"
									colorVariant="primary.contrastText"
									align="center"
								>
									2.
								</GlobalTypography.Text>
							</Grid>
							<Grid item xs={10} mt="0.5rem">
								<GlobalTypography.Text variant="subtitle2" colorVariant="secondary.contrastText">
									Click the pin button next to Nighthawk by Phishfort in the list of extensions
								</GlobalTypography.Text>
							</Grid>
						</Grid>
						<Grid item container xs={3} justifyContent="center" alignItems="center">
							<PinIcon />
						</Grid>
					</Grid>

					<Grid
						item
						container
						alignItems="center"
						justifyContent="space-between"
						mt="1rem"
						columnSpacing={4}
					>
						<Grid item container xs={9}>
							<Grid item xs={2}>
								<GlobalTypography.Text
									variant="h4"
									colorVariant="primary.contrastText"
									align="center"
								>
									3.
								</GlobalTypography.Text>
							</Grid>
							<Grid item xs={10} mt="0.5rem">
								<GlobalTypography.Text variant="subtitle2" colorVariant="secondary.contrastText">
									Browse the web with confidence! Navigate to a site and keep an eye your pinned
									Nighthawk icon.
								</GlobalTypography.Text>
							</Grid>
						</Grid>
						<Grid item container direction="column" xs={3} alignItems="center" rowSpacing={1}>
							<Grid item container alignItems="center" justifyContent="center">
								<TrustedIconBig />
								<GlobalTypography.Text
									variant="body2"
									colorVariant="#20C064"
									fontWeight="fontWeightBold"
								>
									Trusted
								</GlobalTypography.Text>
							</Grid>
							<Grid item container alignItems="center" justifyContent="center">
								<DangerousIconBig />
								<GlobalTypography.Text
									variant="body2"
									colorVariant="#C30303"
									fontWeight="fontWeightBold"
								>
									Dangerous
								</GlobalTypography.Text>
							</Grid>
							<Grid item container alignItems="center" justifyContent="center">
								<UnknownIconBig />
								<GlobalTypography.Text
									variant="body2"
									colorVariant="#B9BCBE"
									fontWeight="fontWeightBold"
								>
									Unknown
								</GlobalTypography.Text>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Styled.PopoverArrow />
		</Styled.PopoverContainer>
	);
};

export default Popover;
