import React from 'react';
import { Grid } from '@mui/material';
import { MainWrapper } from '../common/components';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import * as GlobalTypography from '../common/components/global-typography';
import { Link } from 'react-router-dom';
import { ROUTER_KEYS } from '../common/consts/app-keys.const';

const TermsAndConditions = () => (
	<MainWrapper>
		<PageWrapper title="Terms and Conditions">
			<Grid container mt="0.5rem">
				<Grid item xs={12}>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						Last updated: December 2022
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="2rem">
						Please read these Terms and Conditions (<b>"Terms"</b>, <b>"Terms and Conditions"</b>)
						carefully before using the NightHawk service (<b>“NightHawk”</b>) offered by PROTAKEDOWN
						PTE. LTD T/A PhishFort ( <b>“PhishFort”</b>, <b>"us"</b>,<b>"we"</b>, or <b>"our"</b>).
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						Your access to, installment, and use of NightHawk is conditioned on your acceptance of
						and compliance with these Terms. These Terms apply to all visitors, users and others who
						access or use NightHawk.
					</GlobalTypography.Text>
					<GlobalTypography.Text
						variant="body1"
						colorVariant="secondary"
						mt="1rem"
						fontWeight="fontWeightBold"
					>
						By accessing or using NightHawk you agree to be bound by these Terms. If you disagree
						with any part of the terms then you may not access NightHawk.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						I. HOW NIGHTHAWK WORKS
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. Description
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						1.1. NightHawk is a browser extension offered by PhishFort which is compatible with
						Chrome, FireFox and Brave.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						1.2. NightHawk enables users to easily identify and report malicious content which seeks
						to scam, harm, and defraud others as well as maintain a list of trusted sites and
						content.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						1.3. Among others, NightHawk will display when the user accesses websites, Twitter,
						YouTube, Facebook, and LinkedIn.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						1.4. There are three states reflected by NightHawk: valid, unknown, and dangerous. Valid
						content will display a green marking, dangerous content will display a red marking, and
						unknown content will display a grey marking.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						1.5. PhishFort uses the information submitted through NightHawk to maintain a list of
						suspicious content (“Blocklist”) and a list of trusted content (“Allowlist”). NightHawk
						will display content from the Blocklist as dangerous, whilst content contained in the
						Allowlist will display as valid. PhishFort reserves the right to share the Blocklist and
						Allowlist with our partnered platforms.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. User Profile
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						2.1. Users may opt to sign-up to the NightHawk platform using their Google account,
						Twitter account, or via email.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						2.2. Users may opt not to sign-up for an account but may rather continue to use
						NightHawk without registering an account. Should you choose to use NightHawk without an
						account you will not be able to add or maintain a list of your trusted websites.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						3. Reporting
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						3.1. Upon discovering malicious or harmful content, a User may report the content
						through NightHawk via a dropdown selection.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						3.2. Content reported via NightHawk does not form part of the PhishFort takedown
						services. You therefore acknowledge and agree that content reported via NightHawk will
						merely be assessed for placement on the Blocklist and PhishFort will not actively pursue
						the takedown process in relation to the content.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						3.3. By reporting malicious content using NightHawk you will be eligible to earn points
						as a NightHawk guardian (<b>“Guardian Points”</b>). Guardian Points will be displayed on
						your account page.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						3.4 When using NightHawk, PhishFort does not record your IP address, location, or any
						other data other than what has already been stated herein.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						4. Adding Trusted Sites
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						4.1. Users may add content which they subjectively trust through NightHawk via a
						dropdown selection. You therefore acknowledge and accept that trusted content is added
						at your discretion and will not be assessed for accuracy by a member of the PhishFort
						team.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						4.2. Your list of trusted content and the associated relevant data will be saved to your
						NightHawk account and stored on the Google Cloud based in the United States. For more
						information or any queries related to the gathering and storage of data, please see our{' '}
						<Link to={ROUTER_KEYS.PRIVACY_POLICY}>privacy policy</Link>.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						II. COLLECTED INFORMATION
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. Upon signing-up for a NightHawk account, you agree to provide us with the following
						information:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.1. A username
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.2. Email address
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.3. If you opt to sign-up using your Twitter details, Twitter account information
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.4. If you opt to sign-up using your Google account details, Google account information
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.5. A password
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. PhishFort will not collect, store, share, or process any other data other than
						described herein. For the avoidance of doubt, PhishFort does not collect or store data
						or information related to the user’s IP address, location, or other sensitive
						information not provided directly by the user.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						3. Please consult our <Link to={ROUTER_KEYS.PRIVACY_POLICY}>privacy policy</Link> should
						you have any further questions in this regard.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						III. LIMITATION OF LIABILITY AND INDEMNIFICATION
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. PhishFort makes no warranties, representations, or guarantees as to the accuracy or
						service availability of NightHawk. You acknowledge and agree that your use and reliance
						on NightHawk is at your own risk and discretion.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. PhishFort, its employees, management, contractors, or agents shall not be held liable
						or responsible for any issues resulting from the use of NightHawk. PhishFort makes no
						statement regarding the performance, function, or other technical aspect of NightHawk.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						3. You indemnify PhishFort against any and all expenses and losses, including all legal
						and attorneys’ fees and costs, as a result of your use of and reliance on NightHawk, or
						for any breach by you of these Terms.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						IV. ACCEPTABLE USE POLICY AND TERMINATION
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. Acceptable Use Policy (<b>“AUP”</b>)
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						NightHawk is intended to provide an extra layer of security for users to avoid
						malicious, suspicious, and harmful content on the internet. By utilizing NightHawk you
						acknowledge and agree:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.1. You will not abuse the NightHawk reporting system;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.2. You will only report content which you have a genuine, good faith belief to be
						malicious, suspicious, or harmful to other users;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.3. PhishFort will not accept reports which are purely defamatory in nature;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.4. You will not use the NightHawk reporting system for personal gain, profit, or with
						malicious intent;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.5. You will not copy, replicate, re-engineer, or in any way alter the functionality of
						NightHawk;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.6. You will respect the rights of other users;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1.7. You will not attempt to submit an entire platform for addition to the Blocklist
						(for example: www.facebook.com, www.google.com, etc.).
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. Termination
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						2.1. PhishFort reserves the right to terminate your NightHawk account without
						notification if in PhishFort’s sole discretion you are found to be violating these
						Terms, abusing the NightHawk service, promoting content which is illegal or infringing
						any copyright and/or trademark rights, or violate the AUP, or may subject PhishFort to
						any liability.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.5rem">
						2.2. PhishFort may inform the user of the reason for termination.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						V. INTELLECTUAL PROPERTY AND OWNERSHIP
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. PhishFort is the sole owner and operator of NightHawk. All materials, content,
						designs, photographs, images, logos and other trademarks displayed are owned by
						PhishFort.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. You agree and undertake not to copy, duplicate, or use our trademark, designs, source
						code, photographs, and/or designs directly or indirectly in any way.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						3. Should you violate this provision, you acknowledge that there are legal implications
						for infringing on our copyright and/or trademark rights.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						4. You acknowledge and understand that you will be liable for all legal costs we may
						incur should we pursue any necessary legal action as a result of such a violation.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						VI. CONFIDENTIALITY
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						PhishFort confirms that any information submitted to it (including all names, email
						addresses and login information), is strictly confidential. We will not share or sell
						any information provided to us with anyone not required for the purposes of NightHawk or
						as stated herein.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						VII. LINKS TO THIRD PARTIES
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						1. Our services may contain links to third-party services or web sites that are not
						owned or controlled by PhishFort.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						2. PhishFort has no control over, and assumes no responsibility for, the content,
						privacy policies, or practices of any third party services or web sites. You further
						acknowledge and agree that PhishFort shall not be responsible or liable, directly or
						indirectly, for any damage or loss caused or alleged to be caused by or in connection
						with the use of or reliance on any such content, goods or services available on or
						through any such web sites or services.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						VIII. CHANGES
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						We reserve the right, at our sole discretion, to modify or replace these Terms at any
						time. If a revision is material we will try to provide at least 10 days' notice prior to
						any new terms taking effect. What constitutes a material change will be determined at
						our sole discretion.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						IX. CONTACT US
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="1rem">
						If you have any questions about these Terms, please contact us at{' '}
						<a href="mailto:nighthawk@phishfort.com">nighthawk@phishfort.com</a>
					</GlobalTypography.Text>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						mt="1rem"
						fontWeight="fontWeightBold"
					>
						PhishFort makes no guarantees of service of any kind, whether expressed or implied, for
						the NightHawk services it is providing.
					</GlobalTypography.Text>
				</Grid>
			</Grid>
		</PageWrapper>
	</MainWrapper>
);

export default TermsAndConditions;
