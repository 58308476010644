import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import { HiQuestionMarkCircle } from 'react-icons/hi';

const TOOLTIP_WIDTH = 434;
const DEFAULT_OFFSET = TOOLTIP_WIDTH / 2;

export const Hexagon = styled(Grid)<{ theme?: Theme }>`
  width: 212px;
  height: 132px;
  background: ${({ theme }) => theme.palette.primary.contrastText};
  position: relative;
  transform: rotate(90deg);

  &::before {
    content: '';
    position: absolute;
    top: -50px;
    border-left: 106px solid transparent;
    border-right: 106px solid transparent;
    border-bottom: 50px solid ${({ theme }) => theme.palette.primary.contrastText};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -50px;
    border-left: 106px solid transparent;
    border-right: 106px solid transparent;
    border-top: 50px solid ${({ theme }) => theme.palette.primary.contrastText};
`;
export const PointsCount = styled.div<{ theme?: Theme }>`
	position: absolute;
	top: -8px;
	left: 80px;
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.h1.fontSize};
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	transform: rotate(270deg);
`;
export const HexagonLabel = styled(Grid)<{ theme?: Theme }>`
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	margin-left: ${({ theme }) => theme.spacing(2)};
	display: flex;
	align-items: center;
	z-index: 3;
`;
export const QuestionMarkIcon = styled(HiQuestionMarkCircle)<{ theme?: Theme }>`
	font-size: ${({ theme }) => theme.typography.h5.fontSize};
	margin-left: ${({ theme }) => theme.spacing(1)};
	margin-top: ${({ theme }) => theme.spacing(0.75)};

	&:hover {
		cursor: pointer;
	}
`;
export const Tooltip = styled.div<{ theme?: Theme; rightOffset?: number | null }>`
	position: relative;
	display: inline-block;

	& .tooltip-body {
		visibility: hidden;
		width: ${TOOLTIP_WIDTH}px;
		height: 192px;
		top: 100%;
		left: 50%;
		margin-left: ${({ rightOffset }) =>
			rightOffset && rightOffset < DEFAULT_OFFSET
				? `-${TOOLTIP_WIDTH - rightOffset}px`
				: `-${DEFAULT_OFFSET}px`};
		font-size: ${({ theme }) => theme.typography.body1.fontSize};
		font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
		color: ${({ theme }) => theme.palette.primary.light};
		text-align: justify;
		padding: ${({ theme }) => theme.spacing(3)};
		position: absolute;
		border: 1px solid ${({ theme }) => theme.palette.primary.light};
		background-color: ${({ theme }) =>
			theme.palette.mode === 'dark'
				? theme.palette.background.default
				: theme.palette.common.white};

		& p {
			margin-bottom: ${({ theme }) => theme.spacing(2)};
		}

		&::after {
			content: ' ';
			height: 0.5rem;
			width: 0.5rem;
			background-color: ${({ theme }) =>
				theme.palette.mode === 'dark'
					? theme.palette.background.default
					: theme.palette.common.white};
			position: absolute;
			left: ${({ rightOffset }) =>
				rightOffset && rightOffset < DEFAULT_OFFSET
					? `${TOOLTIP_WIDTH + 3 - rightOffset}px`
					: `${DEFAULT_OFFSET + 3}px`};
			top: -1px;
			border-left: 1px solid ${({ theme }) => theme.palette.primary.light};
			border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	&:hover .tooltip-body {
		visibility: visible;
	}
`;

export const InlineLink = styled.span<{ theme?: Theme }>`
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
	color: ${({ theme }) => theme.palette.primary.main};
`;
