import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import imgTop from '../../../../assets/images/btn-low-poly-purple-top.png';
import imgBottom from '../../../../assets/images/btn-low-poly-purple-bottom.png';
import { COLORS } from '../../../theme';

export const ButtonContainer = styled.div<{
	variant?: 'outlined' | 'filled';
	disabled?: boolean;
	width?: string;
	theme?: Theme;
	isSmallButton?: boolean;
}>`
	position: relative;
	button[type='submit'] {
		border: none;
		outline: none;
		background-color: transparent;
		width: 200px;
		height: 50px;
		position: absolute;
		left: 10px;
		top: 5px;
		cursor: pointer;
	}

	span {
		position: relative;
		background-image: none !important;
		display: block;
		cursor: pointer;
		width: ${({ width }) => width || '10rem'};
		height: ${({ variant }) => (variant === 'outlined' ? '52px' : '56px')};
		line-height: ${({ variant }) => (variant === 'outlined' ? '52px' : '56px')};
		text-align: center;
		vertical-align: center;
		font-family: 'IBM Plex Sans', sans-serif;
		font-size: ${({ theme, isSmallButton }) =>
			isSmallButton ? theme.typography.body2.fontSize : theme.typography.body1.fontSize};
		font-weight: ${({ theme, variant }) =>
			variant === 'outlined' ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium};
		text-decoration: none;
		color: ${({ variant, theme }) =>
			variant === 'outlined' ? theme.palette.primary.contrastText : theme.palette.common.white};
		z-index: 2;
	}

	span:before,
	span:after {
		width: ${({ width }) => width || '10rem'};
		content: '';
		position: absolute;
		border: 2px solid
			${({ variant, theme }) =>
				variant === 'outlined' ? theme.palette.primary.contrastText : theme.palette.common.white};
		height: 50%;
		left: 0;
		z-index: -1;
	}

	span:before {
		border-width: 2px 2px 0 2px;
		top: 0;
		transform-origin: center bottom;
		transform: perspective(${({ isSmallButton }) => (isSmallButton ? '0.5rem' : '1rem')})
			rotateX(${({ isSmallButton }) => (isSmallButton ? '2deg' : '3deg')});
		background: ${({ disabled, variant }) =>
			disabled ? COLORS.paleDarkGrey : variant === 'filled' ? `url(${imgTop})` : `none`};
		background-size: cover;
		background-position-y: bottom;
	}

	span:after {
		position: absolute;
		top: 49.5%;
		content: '';
		border-width: 0 2px 2px 2px;
		bottom: 0;
		transform-origin: center top;
		transform: perspective(${({ isSmallButton }) => (isSmallButton ? '0.5rem' : '1rem')})
			rotateX(${({ isSmallButton }) => (isSmallButton ? '-2deg' : '-3deg')});
		background: ${({ disabled, variant }) =>
			disabled ? COLORS.paleDarkGrey : variant === 'filled' ? `url(${imgBottom})` : 'none'};
		background-size: cover;
		background-position-y: top;
	}
`;
