import React, {
	createContext,
	ReactChild,
	ReactChildren,
	useContext,
	useMemo,
	useState
} from 'react';
import { localStorageService } from '../common/services/local-storage.service';

interface IContextValue {
	userToken: string | null;
	guestToken: string | null;
	onSetSyncToken: (e: MessageEvent) => void;
	onSetUserToken: (token: string) => void;
	onSetGuestToken: (token: string) => void;
	removeToken: () => void;
	guestGuardianPoints: string | null;
	onSetSyncGuardianPoints: (e: MessageEvent) => void;
	onSetGuardianPoints: (points: string) => void;
	removeGuardianPoints: () => void;
}

const AuthSyncContext = createContext<IContextValue>({} as IContextValue);

interface IAuthSyncContextProps {
	children: ReactChild | ReactChildren;
}

export const AuthSyncContextProvider: React.FC<IAuthSyncContextProps> = ({ children }) => {
	const [userToken, setUserToken] = useState<string | null>(
		localStorageService.getTokenFromStorage()
	);
	const [guestToken, setGuestToken] = useState<string | null>(
		localStorageService.getGuestTokenFromStorage()
	);
	const [guestGuardianPoints, setGuestGuardianPoints] = useState<string | null>(
		localStorageService.getGuardianPointsFromStorage()
	);

	const removeToken = () => {
		localStorageService.removeTokenFromStorage();
		setGuestToken(null);
		setUserToken(null);
	};

	const onSetUserToken = (token: string) => {
		setGuestToken(null);
		setUserToken(token);
		localStorageService.removeGuestTokenFromStorage();
		localStorageService.setTokenToStorage(token);
		window.postMessage({ type: process.env.REACT_APP_WEB_LOGIN, token });
	};

	const onSetGuestToken = (token: string) => {
		setUserToken(null);
		setGuestToken(token);
		localStorageService.removeUserTokenFromStorage();
		localStorageService.setGuestTokenToStorage(token);
		window.postMessage({ type: process.env.REACT_APP_WEB_LOGIN, token, isGuest: true });
	};

	const onSetSyncToken = (e: MessageEvent) => {
		if (e.data.type && e.data.type === process.env.REACT_APP_EXT_LOGIN) {
			if (e.data.isGuest) {
				onSetGuestToken(e.data.token);
			} else {
				onSetUserToken(e.data.token);
			}
		}
		if (e.data.type && e.data.type === process.env.REACT_APP_EXT_LOGOUT) {
			removeToken();
		}
	};

	const onSetSyncGuardianPoints = (e: MessageEvent) => {
		if (e.data.type && e.data.type === process.env.REACT_APP_EXT_GUARDIAN_POINTS) {
			setGuestGuardianPoints(e.data.points);
			localStorageService.setGuardianPointsToStorage(e.data.points);
		}
	};

	const onSetGuardianPoints = (points: string) => {
		localStorageService.setGuardianPointsToStorage(points);
		setGuestGuardianPoints(points);
		window.postMessage({ type: process.env.REACT_APP_WEB_GUARDIAN_POINTS, points });
	};
	const removeGuardianPoints = () => {
		localStorageService.removeGuardianPointsFromStorage();
		setGuestGuardianPoints(null);
	};

	const value = useMemo(
		() => ({
			userToken,
			guestToken,
			onSetSyncToken,
			onSetUserToken,
			onSetGuestToken,
			removeToken,
			guestGuardianPoints,
			onSetSyncGuardianPoints,
			onSetGuardianPoints,
			removeGuardianPoints
		}),
		[
			userToken,
			guestToken,
			onSetSyncToken,
			onSetUserToken,
			onSetGuestToken,
			removeToken,
			guestGuardianPoints,
			onSetSyncGuardianPoints,
			onSetGuardianPoints,
			removeGuardianPoints
		]
	);

	return <AuthSyncContext.Provider value={value}>{children}</AuthSyncContext.Provider>;
};

export const useAuthSyncData = () => useContext(AuthSyncContext);
