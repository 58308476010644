import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';

export const FooterContainer = styled(Grid)<{ theme?: Theme }>`
	height: 74px;
	padding-right: ${({ theme }) => theme.spacing(6)};
	& span {
		font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
		font-size: inherit;
		opacity: 0.5;
	}
`;
