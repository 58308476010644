import { EType } from '../../../../../services/trusted-list.types';
import { BrowserWhiteIcon } from '../../icons/ic-browser-white';
import { FacebookWhiteIcon } from '../../icons/ic-facebook-white';
import { LinkedinWhiteIcon } from '../../icons/ic-linkedin-white';
import { TwitterWhiteIcon } from '../../icons/ic-twitter-white';
import { YoutubeWhiteIcon } from '../../icons/ic-youtube-white';

export const renderToastIcon = (type?: EType | null) => {
	switch (type) {
		case EType.FACEBOOK:
			return <FacebookWhiteIcon />;
		case EType.YOUTUBE:
			return <YoutubeWhiteIcon />;
		case EType.TWITTER:
			return <TwitterWhiteIcon />;
		case EType.LINKEDIN:
			return <LinkedinWhiteIcon />;
		default:
			return <BrowserWhiteIcon />;
	}
};
