import { Grid } from '@mui/material';
import { APP_KEYS } from '../../../../consts';
import * as Styled from './bottom-links.styled';
import * as GlobalTypography from '../../../global-typography';

export const BottomLinks = () => (
	<Grid container direction="column" justifyContent="center" alignItems="center">
		<GlobalTypography.Text variant="body1" align="center" colorVariant="common.white">
			By creating an account, you agree to PhishFort’s&nbsp;
			<Styled.Link to={APP_KEYS.ROUTER_KEYS.TERMS_AND_CONDITIONS}>Terms and Conditions</Styled.Link>
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" align="center" colorVariant="common.white">
			and confirm that you have read PhishFort’s&nbsp;
			<Styled.Link to={APP_KEYS.ROUTER_KEYS.PRIVACY_POLICY}>Privacy Policy</Styled.Link>
		</GlobalTypography.Text>
	</Grid>
);
