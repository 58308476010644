import React from 'react';
import { RedirectButtons } from '../../common/components/redirect-btns';
import userPublicPage from '../../common/hocs/user-public.hoc';
import { SPACES } from '../../theme';
import { SignInForm } from './components/sign-in-form';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import { BottomLinks } from '../../common/components/auth-wrapper/components/bottom-links';

const SignInEmail = () => (
	<AuthPageWrapper footerContent={<BottomLinks />}>
		<AuthPageActionCardWrapper title="Sign In">
			<>
				<SignInForm />
				<RedirectButtons isSignUp={false} marginTop={SPACES.xxxs} isVisibleWithoutAcc={false} />
			</>
		</AuthPageActionCardWrapper>
	</AuthPageWrapper>
);

export default userPublicPage(SignInEmail);
