import React, {
	FC,
	ReactChild,
	ReactChildren,
	Children,
	isValidElement,
	cloneElement
} from 'react';
import { Grid, useTheme } from '@mui/material';
import * as GlobalTypography from '../global-typography';

interface IPageWrapperProps {
	title?: string;
	plTitle?: string;
	children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
}

const PageWrapper: FC<IPageWrapperProps> = ({ title, plTitle, children }) => {
	const { spacing } = useTheme();
	return (
		<Grid
			container
			direction="column"
			pl={spacing(10)}
			pt={spacing(10)}
			pr={spacing(10)}
			pb={spacing(3)}
		>
			{title && (
				<Grid item mb={spacing(3)}>
					<GlobalTypography.Text variant="h4" align="left" colorVariant="primary" pl={plTitle}>
						{title}
					</GlobalTypography.Text>
				</Grid>
			)}
			<Grid item container flexGrow={1}>
				{Children.map(children, (child) => {
					if (isValidElement(child)) {
						return cloneElement(child, {});
					}
					return null;
				})}
			</Grid>
		</Grid>
	);
};

export default PageWrapper;
