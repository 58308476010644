import { EnhancedWithAuthHttpService, HttpFactoryService } from '../modules/common/services';
import { ReactQueryFactory } from '../modules/common/utils/react-query.util';
import { EType, ICreateTrustedItem, ITrustedList, Iuuid, IWhiteList } from './trusted-list.types';
import axios from 'axios';

class TrustedListService extends ReactQueryFactory<{}> {
	constructor(private httpService: EnhancedWithAuthHttpService) {
		super('trusted-list');
	}

	async onDetailedRequest(): Promise<Iuuid | void | undefined> {
		throw new Error('onDetailedRequest not implemented!');
	}

	async onAllRequest(args?: any) {
		const data = await this.httpService.get<ITrustedList[]>('user/my-trusted');
		if (data) {
			return data.filter((el) => el.url.includes(args?.filter || ''));
		}
	}

	async onListRequest({ filter, type }: { filter?: string; type?: EType }) {
		const queryString = type ? `?type=${type}` : '';
		const response = await axios.get(process.env.REACT_APP_CDN_URL + queryString, {
			headers: {
				'x-api-key': process.env.REACT_APP_NIGHTHAWK_API_KEY!
			}
		});
		let data: IWhiteList[] = [];

		// format trustlist data as IWhiteList[]
		Object.entries(response.data.trustlist).forEach(([key, value]: any) => {
			value.forEach((item: any) =>
				data.push({
					type: key as EType,
					url: item
				})
			);
		});

		if (data) {
			return data.filter((el) => el.url.includes(filter || ''));
		}
	}

	async addNewTrustedItem(data: ICreateTrustedItem) {
		return this.httpService.post<Iuuid, ICreateTrustedItem>('user/trust-list', data);
	}

	async removeTrustedItem({ id }: Iuuid): Promise<Iuuid | void> {
		return this.httpService.delete<Iuuid>(`user/remove/${id}`);
	}
}

const factory = new HttpFactoryService();
export const trustedListService = new TrustedListService(factory.createAuthHttpService());
