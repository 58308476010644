import { ReactChild, ReactChildren } from 'react';

import { Grid } from '@mui/material';
import * as Styled from './auth-wrapper.styled';
import NighthawkLogo from '../../../../assets/icons/logo.svg';
import PhishPortLogoSVG from '../../../../assets/icons/phishfortLogo.svg';
import * as GlobalTypography from '../global-typography';

interface IFormCardWrapperProps {
	children: ReactChild | ReactChildren;
	titleContent: ReactChild | ReactChildren;
	subTitleContent?: ReactChild | ReactChildren;
	subTextContent?: ReactChild | ReactChildren;
	subSmallTextContent?: ReactChild | ReactChildren;
	className: string;
}

export const ActionCardWrapperAbstract = ({
	titleContent,
	subTitleContent,
	subTextContent,
	subSmallTextContent,
	children,
	className
}: IFormCardWrapperProps) => (
	<Styled.ContentBox
		container
		justifyContent="center"
		alignItems="center"
		direction="column"
		className={className}
	>
		<Grid item className="auth-card-wrapper-title" mb="1.5rem">
			{titleContent}
		</Grid>
		{subTitleContent && (
			<Grid item className="auth-card-wrapper-subtitle" mb="1.75rem">
				{subTitleContent}
			</Grid>
		)}
		{subTextContent && (
			<Grid item className="auth-card-wrapper-subtitle" mb="1.75rem">
				{subTextContent}
			</Grid>
		)}
		{subSmallTextContent && (
			<Grid item className="auth-card-wrapper-subtitle" mb="1.75rem">
				{subSmallTextContent}
			</Grid>
		)}

		<Grid item justifyContent="center" alignItems="center" className="auth-card-wrapper-content">
			{children}
		</Grid>
	</Styled.ContentBox>
);

interface IAuthPageActionCardWrapperProps {
	children: ReactChild | ReactChildren;
	title?: string;
	subTitle?: string;
	subText?: string;
	subSmallText?: string;
}

export const AuthPageActionCardWrapper = ({
	title,
	subTitle,
	subText,
	subSmallText,
	children
}: IAuthPageActionCardWrapperProps) => (
	<ActionCardWrapperAbstract
		titleContent={
			<GlobalTypography.Text
				variant="h4"
				align="center"
				colorVariant="primary.contrastText"
				sx={{ whiteSpace: 'nowrap' }}
			>
				{title}
			</GlobalTypography.Text>
		}
		subTitleContent={
			subTitle && (
				<GlobalTypography.Text
					variant="subtitle1"
					align="center"
					colorVariant="secondary.contrastText"
				>
					{subTitle}
				</GlobalTypography.Text>
			)
		}
		subTextContent={
			subText && (
				<GlobalTypography.Text
					variant="subtitle2"
					align="center"
					colorVariant="secondary.contrastText"
				>
					{subText}
				</GlobalTypography.Text>
			)
		}
		subSmallTextContent={
			subSmallText && (
				<GlobalTypography.Text variant="body2" align="center" colorVariant="secondary.contrastText">
					{subSmallText}
				</GlobalTypography.Text>
			)
		}
		className="auth-page-action-card-wrapper"
	>
		{children}
	</ActionCardWrapperAbstract>
);

interface IProps {
	children: ReactChild | ReactChildren;
	footerContent?: ReactChild | ReactChildren;
	filled?: boolean;
}

export const AuthPageWrapper = ({ children, footerContent, filled }: IProps) => (
	<Styled.FormCardGridContainer container maxWidth="100%">
		<Grid
			container
			item
			direction="column"
			alignItems="center"
			justifyContent="center"
			minHeight="100%"
			className="form-grid-container"
		>
			<Grid
				container
				item
				flexGrow={filled ? 1 : 0}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item>
					<Styled.Logo src={NighthawkLogo} />
				</Grid>
				{children}
				{footerContent && (
					<Grid item className="auth-card-wrapper-footer" mt="1rem">
						{footerContent}
					</Grid>
				)}
			</Grid>
			<Styled.FooterContent
				item
				container
				justifyContent="center"
				alignItems="center"
				filled={filled}
			>
				<Styled.PhishPortLogo src={PhishPortLogoSVG} />
			</Styled.FooterContent>
		</Grid>
	</Styled.FormCardGridContainer>
);
