import React, { Dispatch } from 'react';
import { EType, ITrustedList, IWhiteList } from '../../services/trusted-list.types';
import { EFields, ESortDirection, ESortTypes, ISortOptions } from './types';
import { BrowserGrayIcon } from '../common/components/icons/ic-browser-gray';
import { YoutubeGrayIcon } from '../common/components/icons/ic-youtube-gray';
import { LinkedinGrayIcon } from '../common/components/icons/ic-linkedin-gray';
import { TwitterGrayIcon } from '../common/components/icons/ic-twitter-gray';
import { FacebookGrayIcon } from '../common/components/icons/ic-facebook-gray';

export const typesOrder = [
	EType.WEBSITE,
	EType.TWITTER,
	EType.FACEBOOK,
	EType.YOUTUBE,
	EType.LINKEDIN
];

export function sortByAsc<T>(field: keyof T, data: T[]): T[] {
	const res = data.sort((a, b) => (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0));
	return res;
}

export function sortByDesc<T>(field: keyof T, data: T[]): T[] {
	const res = data.sort((a, b) => (a[field] > b[field] ? -1 : a[field] < b[field] ? 1 : 0));
	return res;
}

export function getSortedByType(
	data: ITrustedList[] | IWhiteList[],
	currentType: EType,
	direction: ESortDirection
) {
	const currentIdx = currentType ? typesOrder.indexOf(currentType) : 0;

	return typesOrder.reduce((acc: any, el: any, idx: number) => {
		const filteredByType = data.filter((item) => item.type === el);

		switch (direction) {
			case ESortDirection.BEFORE:
				return idx >= currentIdx ? [...acc, ...sortByAsc(EFields.TYPE, filteredByType)] : acc;
			case ESortDirection.AFTER:
				return idx < currentIdx ? [...acc, ...sortByAsc(EFields.TYPE, filteredByType)] : acc;
			default:
				return acc;
		}
	}, []);
}

export function getSortedList<T>(
	currentOptions: ISortOptions<T>,
	data: T[],
	currentType?: EType
): T[] {
	if (currentOptions.field === EFields.TYPE && currentType) {
		const startItems = getSortedByType(data as any, currentType, ESortDirection.BEFORE);
		const endItems = getSortedByType(data as any, currentType, ESortDirection.AFTER);
		return startItems.concat(endItems);
	}

	if (currentOptions.field && currentOptions.type) {
		return currentOptions.type === ESortTypes.ASC
			? sortByAsc<T>(currentOptions.field, data)
			: sortByDesc<T>(currentOptions.field, data);
	}
	return data;
}

export const renderTrustedItemIcon = (type?: EType) => {
	switch (type) {
		case EType.FACEBOOK:
			return <FacebookGrayIcon />;
		case EType.YOUTUBE:
			return <YoutubeGrayIcon />;
		case EType.TWITTER:
			return <TwitterGrayIcon />;
		case EType.LINKEDIN:
			return <LinkedinGrayIcon />;
		default:
			return <BrowserGrayIcon />;
	}
};

export const sortHelper =
	<T,>(field: keyof T, setSortOptions: Dispatch<React.SetStateAction<ISortOptions<T>>>) =>
	() => {
		setSortOptions((prev) =>
			prev.field === field
				? {
						field,
						type: prev.type === ESortTypes.ASC ? ESortTypes.DESC : ESortTypes.ASC
				  }
				: {
						field,
						type: ESortTypes.ASC
				  }
		);
	};
