/* eslint-disable max-len */
export const TwitterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="27.073" height="22" viewBox="0 0 27.073 22">
		<path
			id="Path_1339"
			data-name="Path 1339"
			d="M28.613,6.588a11.087,11.087,0,0,1-3.184.893A5.563,5.563,0,0,0,27.862,4.4a11.182,11.182,0,0,1-3.52,1.359A5.467,5.467,0,0,0,20.253,4a5.545,5.545,0,0,0-5.526,5.552,5.77,5.77,0,0,0,.142,1.268,15.781,15.781,0,0,1-11.44-5.8,5.53,5.53,0,0,0,1.721,7.389,5.49,5.49,0,0,1-2.524-.647V11.8a5.556,5.556,0,0,0,4.452,5.448,5.468,5.468,0,0,1-2.5.091A5.538,5.538,0,0,0,9.758,21.2a11.032,11.032,0,0,1-6.9,2.381A11.288,11.288,0,0,1,1.54,23.5,15.716,15.716,0,0,0,10.055,26a15.706,15.706,0,0,0,15.8-15.8c0-.246,0-.479-.013-.725A11.093,11.093,0,0,0,28.613,6.588Z"
			transform="translate(-1.54 -4)"
			fill="#6c0c8b"
		/>
	</svg>
);
