import styled from 'styled-components';
import { SPACES } from '../../../theme';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${SPACES.xxxxs};
`;
export const Image = styled.img`
	cursor: pointer;
`;
