import { useAuthSyncData } from '../context/auth-sync.context';
import { useHistory } from 'react-router';
import { APP_KEYS } from '../common/consts';

export default function RedirectPage() {
	const { userToken, guestToken } = useAuthSyncData();
	const history = useHistory();
	if (typeof window !== 'undefined') {
		if (userToken) {
			history.push(APP_KEYS.ROUTER_KEYS.TRUSTED_LIST);
			return null;
		}

		if (guestToken) {
			history.push(APP_KEYS.ROUTER_KEYS.USER_GUIDE);
			return null;
		}

		if (!userToken && !guestToken) {
			history.push(APP_KEYS.ROUTER_KEYS.SIGN_IN);
			return null;
		}
	}

	return null;
}
