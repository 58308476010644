/* eslint-disable max-len */
import { UserGuideIconContainer } from './icon.styled';

export const UnknownIcon = () => (
	<UserGuideIconContainer>
		<svg
			id="ic-nighthawk-unknown"
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<circle id="Ellipse_7" data-name="Ellipse 7" cx="10" cy="10" r="10" fill="#b9bcbe" />
			<path
				id="Union_28"
				data-name="Union 28"
				d="M8.27,8.491v0l-.921.343.178-2.8-3-1.565L0,7.213,1.285,4.965,4.126,0l3.23,1.805.07-.363.016-.086.085-.44L8.27.31l.742.607.1.526.069.363L12.415,0l2.84,4.965L16.54,7.213,12.012,4.471l-3,1.565.178,2.8Z"
				transform="translate(1.73 5.583)"
				fill="#fff"
				stroke="rgba(0,0,0,0)"
				strokeWidth="1"
			/>
		</svg>
	</UserGuideIconContainer>
);
