import styled from 'styled-components';

export const UserGuideIconContainer = styled.div`
	display: inline-block;
	position: relative;
	width: 22px;
	height: 20px;
	top: 3px;
`;

export const IconContainer = styled.div<{ mt?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: ${({ mt }) => mt || 0};
	width: 28px;
	height: 22px;
`;

export const GrayIconContainer = styled.div<{ ml?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: ${({ ml }) => ml || 0};
	width: 26px;
	height: 26px;
`;
