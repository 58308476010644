import { Search } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, TableCell, Theme } from '@mui/material';
import styled from '@emotion/styled';

export const Cross = styled(CloseRoundedIcon)<{ theme?: Theme }>`
	color: ${({ theme }) => theme.palette.primary.contrastText};
	stroke: ${({ theme }) => theme.palette.primary.contrastText};
	width: 25px;
	height: 25px;

	cursor: pointer;
`;

export const MySearch = styled(Search)<{ theme?: Theme }>`
	color: ${({ theme }) => theme.palette.primary.contrastText};
	margin-right: ${({ theme }) => theme.spacing(3)};
	font-size: 32px;
`;

export const TableContainer = styled(Box)<{ theme?: Theme }>`
	overflow-y: scroll;
	min-width: 100%;
	& td {
		color: ${({ theme }) => theme.palette.secondary.main};
	}
	& th {
		color: ${({ theme }) => theme.palette.secondary.main};
	}
`;
export const SortTableCell = styled(TableCell)`
	cursor: pointer;
`;

export const TableCellLink = styled(TableCell)`
	width: 30%;
	max-width: 400px;
	word-break: break-all;
`;

export const Link = styled.a`
	text-decoration: none;
`;

export const TableHeadContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const TableSortIconContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 6px;
	margin-top: 1px;
`;

export const ArrowContainer = styled.div<{ isActive?: boolean; theme?: Theme }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 7px;
	width: 9px;
	color: ${({ theme, isActive }) =>
		isActive ? theme.palette.primary.main : theme.palette.secondary.light};
`;
