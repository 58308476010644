import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { APP_KEYS } from '../common/consts';
import Profile from '../profile';
import SocialAuth from '../auth/social-auth';
import SignUpWithEmail from '../auth/sign-up-email';
import SignInEmail from '../auth/sign-in-email';
import CreateProfile from '../auth/create-profile';
import ResetPasswordForm from '../auth/reset-password/reset-password.component';
import TrustedList from '../trusted-list/trusted-list.component';
import ConnectPage from '../connect';
import UserGuide from '../user-guide';
import Support from '../support';
import AddTrusted from '../add-trusted';
import ScamReport from '../scam-report/scam-report.component';
import Welcome from '../auth/welcome/welcome.component';
import NighthawkTrustedList from '../trusted-list/nighthawk-trust-list.component';
import TermsAndConditions from '../terms-and-conditions';
import PrivacyPolicy from '../privacy-policy';
import RedirectPage from '../not-found-redirect';
import NighthawkWarning from '../nighthawk-warning';

export const MainRouter = () => (
	<Router>
		<Switch>
			<Route component={SocialAuth} exact path={APP_KEYS.ROUTER_KEYS.SIGN_UP} />
			<Route component={SignInEmail} exact path={APP_KEYS.ROUTER_KEYS.SIGN_IN} />
			<Route component={SignUpWithEmail} exact path={APP_KEYS.ROUTER_KEYS.SIGN_UP_WITH_EMAIL} />
			<Route component={Profile} exact path={APP_KEYS.ROUTER_KEYS.PROFILE} />
			<Route component={UserGuide} exact path={APP_KEYS.ROUTER_KEYS.USER_GUIDE} />
			<Route component={Support} exact path={APP_KEYS.ROUTER_KEYS.SUPPORT} />
			<Route component={Welcome} exact path={APP_KEYS.ROUTER_KEYS.WELCOME} />
			<Route component={ScamReport} exact path={APP_KEYS.ROUTER_KEYS.SCAM_REPORT} />
			<Route component={TrustedList} exact path={APP_KEYS.ROUTER_KEYS.TRUSTED_LIST} />
			<Route component={AddTrusted} exact path={APP_KEYS.ROUTER_KEYS.ADD_TRUSTED} />
			<Route component={CreateProfile} exact path={APP_KEYS.ROUTER_KEYS.CREATE_PROFILE} />
			<Route component={ResetPasswordForm} exact path={APP_KEYS.ROUTER_KEYS.RESET} />
			<Route component={ConnectPage} exact path={APP_KEYS.ROUTER_KEYS.CONNECT_US} />
			<Route
				component={TermsAndConditions}
				exact
				path={APP_KEYS.ROUTER_KEYS.TERMS_AND_CONDITIONS}
			/>
			<Route component={PrivacyPolicy} exact path={APP_KEYS.ROUTER_KEYS.PRIVACY_POLICY} />
			<Route
				component={NighthawkTrustedList}
				exact
				path={APP_KEYS.ROUTER_KEYS.NIGHTHAWK_TRUSTED_LIST}
			/>
			<Route component={NighthawkWarning} path={APP_KEYS.ROUTER_KEYS.NIGHTHAWK_WARNING} />
			<Route component={RedirectPage} path="*" />
		</Switch>
	</Router>
);
