import { Field } from 'formik';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { FormFieldContainer } from '../form-field-container';

export const InputContainer = styled(FormFieldContainer)<{
	disabled?: boolean | undefined;
	error?: boolean;
	isBig?: boolean;
	theme?: Theme;
}>`
	position: relative;
	min-height: 50px;
	margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const InputPrefix = styled.div<{
	theme?: Theme;
}>`
	align-items: center;
	background-color: transparent;
	display: flex;
	padding-left: ${({ theme }) => theme.spacing(1.25)};
`;
export const Input = styled(Field)<{ theme?: Theme }>`
	background-color: transparent;
	border: none;
	align-self: center;
	z-index: 0;
	min-width: 255px;
	min-height: 20px;
	padding-top: 3px;
	margin-left: ${({ theme }) => theme.spacing(0.5)};
	outline: none;
	font-size: 17px;
	font-weight: normal;
	font-family: 'IBM Plex Sans', sans-serif;
	::placeholder {
		color: ${({ theme }) => theme.palette.secondary.contrastText};
		opacity: 0.5;
	}
	&:-webkit-autofill {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:hover {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:focus {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
`;

export const LargeInput = styled(Field)<{ theme?: Theme }>`
	outline: none;
	border: 0;
	align-self: center;
	padding-top: 3px;
	bottom: ${({ theme }) => theme.spacing(5)};
	min-width: 376px;
	min-height: 20px;
	z-index: 0;
	font-size: 17px;
	font-weight: normal;
	font-family: 'IBM Plex Sans', sans-serif;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	::placeholder {
		color: ${({ theme }) => theme.palette.secondary.contrastText};
		opacity: 0.5;
	}
	&:-webkit-autofill {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:hover {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
	&:-webkit-autofill:focus {
		min-height: 35px;
		box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
	}
`;

export const ErrorInfoContainer = styled.div<{
	errorJustifyContent?: string;
	theme?: Theme;
	isBig?: boolean;
}>`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: ${({ isBig }) => (isBig ? '420px' : '300px')};
	color: ${({ theme }) => theme.palette.error.main};
	position: relative;
`;

export const ErrorInfoText = styled.div<{ theme?: Theme; isBig?: boolean }>`
	box-sizing: border-box;
	font-size: ${({ theme }) => theme.typography.body2.fontSize};
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	margin-right: ${({ theme }) => theme.spacing(1.25)};
	margin-left: ${({ theme }) => theme.spacing(1.25)};
	font-family: 'IBM Plex Sans', sans-serif;

	text-align: center;
	max-width: ${({ isBig }) => (isBig ? '390px' : '270px')};
`;

export const Styled = {
	InputContainer,
	InputPrefix,
	Input,
	LargeInput,
	ErrorInfoContainer,
	ErrorInfoText
};
