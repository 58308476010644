import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const HexagonContainer = styled.div<{
	large?: boolean;
	length: number;
	theme?: Theme;
}>`
	position: relative;
	max-height: ${({ large }) => (large ? '220px' : '80px')};

	span {
		position: relative;
		top: ${({ large }) => (large ? '-75px' : '-35px')};
		background-image: none !important;
		display: block;
		width: ${({ large }) => (large ? '210px' : '80px')};
		height: ${({ large }) => (large ? '360px' : '140px')};
		line-height: ${({ large }) => (large ? '360px' : '140px')};
		text-align: center;
		vertical-align: center;
		font-size: ${({ theme, length, large }) =>
			large && length > 2
				? theme.typography.h2.fontSize
				: large
				? theme.typography.h1.fontSize
				: length > 2
				? theme.typography.h4.fontSize
				: theme.typography.h3.fontSize};
		font-weight: ${({ theme }) => theme.typography.fontWeightBold};
		color: ${({ theme, large }) =>
			large ? theme.palette.common.white : theme.palette.primary.contrastText};
		z-index: 2;
	}

	span:before,
	span:after {
		width: ${({ large }) => (large ? '210px' : '80px')};
		content: '';
		position: absolute;
		height: 50%;
		left: 0;
		z-index: -1;
		background: ${({ theme, large }) =>
			large ? theme.palette.primary.contrastText : theme.palette.common.white};
	}

	span:before {
		border-width: 2px 2px 0 2px;
		top: 0;
		transform-origin: center bottom;
		transform: perspective(1rem) rotateX(${({ large }) => (large ? '5deg' : '12deg')});
	}

	span:after {
		position: absolute;
		top: 50%;
		content: '';
		bottom: 0;
		transform-origin: center top;
		transform: perspective(1rem) rotateX(${({ large }) => (large ? '-5deg' : '-12deg')});
	}
`;
