import React from 'react';
import { RedirectButtons } from '../../common/components/redirect-btns';
import userPublicPage from '../../common/hocs/user-public.hoc';
import { SPACES } from '../../theme';
import { SignUpForm } from './components/sign-up-form';
import { AuthPageActionCardWrapper, AuthPageWrapper } from '../../common/components/auth-wrapper';
import { BottomLinks } from '../../common/components/auth-wrapper/components/bottom-links';

const SignUpWithEmail = () => (
	<AuthPageWrapper footerContent={<BottomLinks />}>
		<AuthPageActionCardWrapper title="Sign Up With Email">
			<>
				<SignUpForm />
				<RedirectButtons isSignUp marginTop={SPACES.xxxs} isVisibleWithoutAcc={false} />
			</>
		</AuthPageActionCardWrapper>
	</AuthPageWrapper>
);

export default userPublicPage(SignUpWithEmail);
