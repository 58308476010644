import React from 'react';
import { Grid } from '@mui/material';
import { MainWrapper } from '../common/components';
import PageWrapper from '../common/components/page-wrapper/page-wrapper.component';
import * as GlobalTypography from '../common/components/global-typography';

const PrivacyPolicy = () => (
	<MainWrapper>
		<PageWrapper title="Privacy Policy">
			<Grid container mt="0.5rem">
				<Grid item xs={12}>
					<GlobalTypography.Text
						variant="subtitle2"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
					>
						Last updated: January 2023
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="2rem">
						PROTAKEDOWN PTE. LTD T/A PhishFort (<b>“PhishFort”</b>) is committed to maintaining
						robust privacy protections for its users. Our Privacy Policy (<b>“Privacy Policy”</b>)
						is designed to help you understand how we collect, use and safeguard the information you
						provide to us and to assist you in making informed decisions when using the NightHawk
						service.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						For purposes of this Privacy Policy:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						<b>“Site”</b> refers to PhishFort’s NightHawk browser extension which includes the
						NightHawk platform from which the NightHawk Service is provided. <b>“Service”</b> refers
						to PhishFort’s NightHawk services which include:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1. Reporting malicious and harmful content;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.3rem">
						2.Maintaining and updating a list of trusted sites;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.3rem">
						3. Request information or a follow-up communication with PhishFort’s team;
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.3rem">
						4. Register on and make use of a NightHawk account.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						“Our”, “we”, “us” refers to PhishFort.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary">
						“You” refers to you, as a user of our Site or our Service or NightHawk account.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						By accessing our Site or our Service or NightHawk account, you accept our Privacy Policy
						and Terms and Conditions, and you consent to our collection, storage, use and disclosure
						of your Personal Information as described in this Privacy Policy.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						I. INFORMATION WE COLLECT
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						We collect “Non-Personal Information” and “Personal Information”. You directly provide
						us with most of the information we collect. We collect information and process
						information when you:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1. Register for a NightHawk account.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						2. Report malicious content or update your list of trusted sites.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						3. Voluntarily complete a customer survey or provide feedback on any of our message
						boards or via email.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						4. Use or view our Site via your browser’s cookies.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						5. Complete our online contact form.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						6. Complete any enquiry or product form found on our Site.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						PhishFort does not receive your data indirectly from third parties or other sources.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						<b>Non-Personal Information</b> includes information that cannot be used to personally
						identify you, such as anonymous usage data, general demographic information we may
						collect, referring/exit pages and URLs, platform types, preferences you submit and
						preferences that are generated based on the data you submit and number of clicks.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						<b>Personal Information</b> is submitted by you when you complete one of the forms on
						our Site, use the NightHawk service, or register for a NightHawk account and includes
						the following:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1. Your name
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						2. Your email address
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="2rem">
						1. Cookies
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						PhishFort does not use cookies and will not collect any information via cookies.
						However, should you opt to login using your Google or Twitter account, Google and/or
						Twitter use cookies to authenticate your login. PhishFort is not responsible for the
						cookies collected by Google and/or Twitter. The cookies collected by Google and/or
						Twitter are unrelated to PhishFort and we will not see, use, interact with, or in any
						way store cookies collected by Google and/or Twitter when logging in to your NightHawk
						account. To find out more about how Google and/or Twitter use cookies please refer to
						their respective policies:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						Google -{' '}
						<a
							href="https://policies.google.com/technologies/cookies"
							rel="noreferrer"
							target="_blank"
						>
							https://policies.google.com/technologies/cookies
						</a>
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						Twitter -{' '}
						<a
							href="https://help.twitter.com/en/rules-and-policies/twitter-cookies"
							rel="noreferrer"
							target="_blank"
						>
							https://help.twitter.com/en/rules-and-policies/twitter-cookies
						</a>
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						Instead of cookies, we use an authentication token to help you stay logged into your
						account. The authentication token is strictly necessary to provide functionality for
						your NightHawk account, and is issued when you sign-up for an account and is locally
						saved on your side. The authentication token ensures you have easy access to your
						NightHawk account with the peace of mind that we aren’t collecting, tracking, or storing
						cookies on our end. We will not collect any Personal Information or Non-personal
						information through the authentication token. The authentication token will be valid for
						one year from the date of registration or until you cancel your account, whichever is
						earlier. By using the NighHawk service, you consent to the use of the authentication
						token to ensure the functionality of your NightHawk account.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="2rem">
						2. Information you provide us by registering on the NightHawk account
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						To register for a NightHawk account you will need to create a personal profile. You can
						create a profile by registering for a NightHawk account and entering your email address,
						and creating a password. By registering, you are authorizing us to collect, store and
						use your email address and username in accordance with this Privacy Policy.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="2rem">
						3. Children’s Privacy
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						The Site and the Service are not directed at or to anyone under the age of 13. The Site
						does not knowingly collect or solicit information from anyone under the age of 13, or
						allow anyone under the age of 13 to sign up for the Service. In the event that we learn
						that we have gathered personal information from anyone under the age of 13 without the
						consent of a parent or guardian, we will delete that information as soon as possible. If
						you believe we have collected such information, please contact us at{' '}
						<a href="mailto:nighthawk@phishfort.com">nighthawk@phishfort.com</a>.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						II. HOW WE USE AND SHARE INFORMATION
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="2rem">
						1. Personal Information:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or
						otherwise share for marketing purposes your Personal Information with third parties
						without your consent. We may share Personal Information with vendors who are performing
						services for PhishFort, such as the servers for our email communications who are
						provided access to the user\'s email address for purposes of sending emails from us.
						Those vendors use your Personal Information only at our direction and in accordance with
						our Privacy Policy.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						In general, the Personal Information you provide to us is used to help us communicate
						with you and for us to provide the Services. For example, we use Personal Information to
						contact users in response to questions, solicit feedback from users, provide technical
						support, and inform users about promotional offers.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						We may share Personal Information with outside parties if we have a good-faith belief
						that access, use, preservation or disclosure of the information is reasonably necessary
						to meet any applicable legal process or enforceable governmental request; to enforce
						applicable Terms and Conditions, including investigation of potential violations;
						address fraud, security or technical concerns; or to protect against harm to the rights,
						property, or safety of our users or the public as required or permitted by law; or it’s
						required in order to provide our takedown services.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle2" colorVariant="secondary" mt="2rem">
						2. Non-Personal Information:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						In general, we use Non-Personal Information to help us improve the Site and customize
						the user experience. We also aggregate Non-Personal Information in order to track trends
						and analyze use patterns on the Site. This Privacy Policy does not limit in any way our
						use or disclosure of Non-Personal Information and we reserve the right to use and
						disclose such Non-Personal Information to our partners, advertisers and other third
						parties at our discretion.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						In the event we undergo a business transaction such as a merger, acquisition by another
						company, or sale of all or a portion of our assets, your Personal Information may be
						among the assets transferred. You acknowledge and consent that such transfers may occur
						and are permitted by this Privacy Policy, and that any acquirer of our assets may
						continue to process your Personal Information as set forth in this Privacy Policy. If
						our information practices change at any time in the future, we will post the policy
						changes to the Site so that you may opt out of the new information practices. We suggest
						that you check the Site periodically if you are concerned about how your information is
						used.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						III. HOW WE PROTECT INFORMATION
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="2rem">
						PhishFort securely stores your data on the Google Cloud Platform (“GCP”). We encourage
						you to read about their data protection practices here:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						GCP -{' '}
						<a
							href="https://cloud.google.com/terms/data-processing-terms"
							rel="noreferrer"
							target="_blank"
						>
							https://cloud.google.com/terms/data-processing-terms
						</a>
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						We implement security measures designed to protect your information from unauthorized
						access. Your NightHawk account is protected by your account password, and we urge you to
						take steps to keep your personal information safe by not disclosing your password and by
						logging out of your account after each use. We further protect your information from
						potential security breaches by implementing certain technological security measures
						including encryption, firewalls and secure socket layer technology. However, these
						measures do not guarantee that your information will not be accessed, disclosed, altered
						or destroyed by breach of such firewalls and secure server software. By using our Site,
						Service, or NightHawk account you acknowledge that you understand and agree to assume
						these risks.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						PhishFort will keep your information only for the period in which we provide our
						Services. Once this time period has expired, we will delete your data by deleting your
						NightHawk user account. Any evidence uploaded to the NightHawk account for purposes of
						maintaining a list of trusted content is securely stored on the GCP for an indefinite
						period.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						PhishFort would like to make sure you are fully aware of all of your data protection
						rights. Every user is entitled to the following:
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						1. <b>The right to access</b>. You have the right to request PhishFort for copies of
						your personal data. We may charge you a small fee for this service.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						2. <b>The right to rectification</b>. You have the right to request that PhishFort
						correct any information you believe is inaccurate. You also have the right to request
						PhishFort to complete the information you believe is incomplete.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						3. <b>The right to erasure</b>. You have the right to request that PhishFort erase your
						personal data, under certain conditions.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						4. <b>The right to restrict processing</b>. You have the right to request that PhishFort
						restrict the processing of your personal data, under certain conditions.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						5. <b>The right to object to processing</b>. You have the right to object to PhishFort’s
						processing of your personal data, under certain conditions.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" ml="1rem" mt="0.2rem">
						6. <b>The right to data portability</b>. You have the right to request that PhishFort
						transfer the data that we have collected to another organization, or directly to you,
						under certain conditions.
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						If you make a request, we have one month to respond to you. If you would like to
						exercise any of these rights, please contact us at our email:{' '}
						<a href="mailto:nighthawk@phishfort.com">nighthawk@phishfort.com</a>
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						V. LINKS TO OTHER WEBSITES
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						We may provide links to or compatibility with other websites or applications. However,
						we are not responsible for the privacy practices employed by those websites or the
						information or content they contain. This Privacy Policy applies solely to information
						collected by us through the Site, the Service and your NightHawk account. Therefore,
						this Privacy Policy does not apply to your use of a third party website accessed by
						selecting a link on our Site or via our Service. To the extent that you access or use
						the Service through or on another website or application, then the privacy policy of
						that other website or application will apply to your access or use of that site or
						application. We encourage our users to read the privacy statements of other websites
						before proceeding to use them.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						VI. CHANGES TO OUR PRIVACY POLICY
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						PhishFort reserves the right to change this policy and our Terms and Conditions at any
						time. We will notify you of significant changes to our Privacy Policy by sending a
						notice to the primary email address specified in your account or by placing a prominent
						notice on our Site. Significant changes will go into effect 30 days following such
						notification. Non-material changes or clarifications will take effect immediately. You
						should periodically check the Site and this privacy page for updates.
					</GlobalTypography.Text>

					<GlobalTypography.Text variant="subtitle1" colorVariant="secondary" mt="2rem">
						VII. CONTACT US
					</GlobalTypography.Text>
					<GlobalTypography.Text variant="body1" colorVariant="secondary" mt="1rem">
						If you have any questions regarding this Privacy Policy or the practices of this Site,
						please contact us by sending an email to{' '}
						<a href="mailto:nighthawk@phishfort.com">nighthawk@phishfort.com</a>.
					</GlobalTypography.Text>
				</Grid>
			</Grid>
		</PageWrapper>
	</MainWrapper>
);

export default PrivacyPolicy;
