import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const LinkBlock = styled.a<{ theme?: Theme }>`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${({ theme }) => theme.spacing(2)};
`;
