import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { MainRouter } from '../navigation';
import * as Styled from './app.styled';
import '../../style.css';
import '../../__mocks__';
import { useThemeData } from '../context/theme.context';
import { ETheme } from '../common/types/main-container.types';
import { darkTheme, lightTheme } from '../theme/theme';
import { useAuthSyncData } from '../context/auth-sync.context';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			keepPreviousData: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: true,
			cacheTime: Infinity
		}
	}
});

const AppContainer = () => {
	const { onSetSyncToken, onSetSyncGuardianPoints, userToken, guestToken } = useAuthSyncData();
	const { theme: themeMode } = useThemeData();

	useEffect(() => {
		window.addEventListener('message', onSetSyncToken);
		window.addEventListener('message', onSetSyncGuardianPoints);
		return () => {
			window.removeEventListener('message', onSetSyncToken);
			window.removeEventListener('message', onSetSyncGuardianPoints);
		};
	}, []);

	const theme = themeMode === ETheme.DARK && (userToken || guestToken) ? darkTheme : lightTheme;

	return (
		<ThemeProvider theme={theme}>
			<Styled.GlobalStyles />
			<QueryClientProvider client={queryClient}>
				<Toaster />
				<MainRouter />
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</ThemeProvider>
	);
};

export default AppContainer;
