/* eslint-disable max-len */
export const TwitterBigIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="62.189"
		height="50.536"
		viewBox="0 0 62.189 50.536"
	>
		<path
			id="Path_1339"
			data-name="Path 1339"
			d="M63.729,9.945A25.469,25.469,0,0,1,56.416,12a12.779,12.779,0,0,0,5.589-7.075,25.687,25.687,0,0,1-8.086,3.121A12.559,12.559,0,0,0,44.525,4,12.738,12.738,0,0,0,31.832,16.753a13.254,13.254,0,0,0,.327,2.913A36.25,36.25,0,0,1,5.88,6.348,12.7,12.7,0,0,0,9.834,23.323a12.612,12.612,0,0,1-5.8-1.486v.089A12.762,12.762,0,0,0,14.263,34.441a12.56,12.56,0,0,1-5.737.208,12.722,12.722,0,0,0,11.891,8.859,25.342,25.342,0,0,1-15.845,5.47A25.93,25.93,0,0,1,1.54,48.8,36.1,36.1,0,0,0,21.1,54.536c23.425,0,36.3-19.442,36.3-36.3,0-.565,0-1.1-.03-1.665A25.481,25.481,0,0,0,63.729,9.945Z"
			transform="translate(-1.54 -4)"
			fill="#6c0c8b"
		/>
	</svg>
);
