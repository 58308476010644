import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { ButtonContainer } from './hexagon-button.styled';

interface IHexagonBtn {
	title: string;
	variant?: 'outlined' | 'filled';
	disabled?: boolean;
	click?: React.MouseEventHandler<HTMLDivElement> | any;
	width?: string;
	isSmallButton?: boolean;
	icon?: ReactNode;
}

export const HexagonBtn: React.FC<IHexagonBtn> = ({
	title,
	variant,
	disabled,
	click,
	width,
	isSmallButton = false,
	icon
}) => (
	<ButtonContainer
		disabled={disabled}
		variant={variant}
		onClick={click}
		width={width}
		isSmallButton={isSmallButton}
	>
		<span>
			<Grid container alignItems="center" justifyContent="center">
				{icon}
				<Grid item xs={11}>
					{title}
				</Grid>
			</Grid>
			<button type="submit" disabled={disabled} />
		</span>
	</ButtonContainer>
);
