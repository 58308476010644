/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import userProtectedPage from '../common/hocs/user-protected.hoc';
import TrustedListContainer from './trusted-list-container.component';
import TrustedListBody from './components/trusted-list-body.component';

const TrustedList: React.FC = () => (
	<TrustedListContainer title="Trust List">
		<TrustedListBody />
	</TrustedListContainer>
);

export default userProtectedPage(TrustedList);
