import * as React from 'react';
import * as GlobalTypography from '../../../common/components/global-typography';

export const Panel5 = () => (
	<>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			NightHawk is a non-profit product designed to expose malicious actors and prevent phishing
			attacks on crypto-users.
		</GlobalTypography.Text>
		<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
			Our values:
		</GlobalTypography.Text>
		<ul>
			<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
				<b>Openness</b> - the codebase, Blacklist, and Trusted List are open-sourced and accessible
				to everyone.
			</GlobalTypography.Text>
			<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
				<b>Security and Privacy</b> - your browsing data never leaves your browser. We ask for your
				consent to share usage data to improve our product, but you can deny it with one click.
			</GlobalTypography.Text>
			<GlobalTypography.Text variant="body1" colorVariant="secondary" m="0.75rem 0" pr="3rem">
				<b>Community</b> - the service is accessible to everyone and FREE to use.
			</GlobalTypography.Text>
		</ul>
	</>
);
