import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import { SwitchToggle } from '../../common/components';
import { APP_KEYS } from '../../common/consts';
import { ETheme } from '../../common/types/main-container.types';
import { IUserInfo } from '../services/profile.types';
import * as Styled from './acc-info.styled';
import * as GlobalTypography from '../../common/components/global-typography';
import { LinkTemplate } from '../../common/components/styled/link-template.styled';

import { useThemeData } from '../../context/theme.context';
import { useAuthSyncData } from '../../context/auth-sync.context';
import { guestLinks, ILinks, links } from '../consts';
import { queryClient } from '../../app';

interface IAccountInfo {
	user: IUserInfo;
	isLogin: boolean;
	onUpdateUser: (isSharingData: boolean) => void;
}

export const AccountInfo: React.FC<IAccountInfo> = ({ user, isLogin, onUpdateUser }) => {
	const { theme: themeMode } = useThemeData();
	const { palette } = useTheme();
	const { removeToken } = useAuthSyncData();
	const { push } = useHistory();

	const linkHandlers: { [key: string]: () => void } = {
		handleSignUp: () => {
			removeToken();
			queryClient.clear();
			push(APP_KEYS.ROUTER_KEYS.SIGN_UP_WITH_EMAIL);
		}
	};

	const renderLinks: ILinks[] = isLogin ? links : guestLinks;

	return (
		<Grid item container xs={7} direction="column" rowSpacing={2}>
			{isLogin && (
				<>
					<Grid item>
						<Styled.OpacityText
							variant="body1"
							colorVariant="secondary"
							fontWeight="fontWeightMedium"
							fontFamily="IBM Plex Sans"
						>
							Username
						</Styled.OpacityText>
						<GlobalTypography.Text
							variant="subtitle1"
							colorVariant="secondary.dark"
							fontFamily="IBM Plex Sans"
						>
							{user.username}
						</GlobalTypography.Text>
					</Grid>
					<Grid item>
						<Styled.OpacityText
							variant="body1"
							colorVariant="secondary"
							fontWeight="fontWeightMedium"
							fontFamily="IBM Plex Sans"
						>
							Email address
						</Styled.OpacityText>
						<GlobalTypography.Text
							variant="subtitle1"
							colorVariant="secondary.dark"
							fontFamily="IBM Plex Sans"
						>
							{user.email}
						</GlobalTypography.Text>
					</Grid>
				</>
			)}

			{renderLinks.map(({ label, title, link, clickHandler }) => (
				<Grid item key={label} width="24rem">
					<Styled.OpacityText
						variant="body1"
						colorVariant="secondary"
						fontWeight="fontWeightMedium"
						fontFamily="IBM Plex Sans"
					>
						{label}
					</Styled.OpacityText>
					{user[label.toLowerCase() as keyof IUserInfo] && !link ? (
						<GlobalTypography.Text
							variant="subtitle1"
							colorVariant="secondary.dark"
							fontFamily="IBM Plex Sans"
						>
							{user[label.toLowerCase() as keyof IUserInfo]}
						</GlobalTypography.Text>
					) : link ? (
						<LinkTemplate to={link}>
							<GlobalTypography.Text
								variant="subtitle2"
								colorVariant="primary.dark"
								fontWeight="fontWeightMedium"
								fontFamily="IBM Plex Sans"
							>
								{title}
							</GlobalTypography.Text>
						</LinkTemplate>
					) : (
						<Styled.PseudoLink
							variant="subtitle2"
							colorVariant="primary.dark"
							fontWeight="fontWeightMedium"
							fontFamily="IBM Plex Sans"
							onClick={clickHandler ? linkHandlers[clickHandler] : undefined}
						>
							{title}
						</Styled.PseudoLink>
					)}
				</Grid>
			))}

			<Styled.SwitchBlock item>
				<Styled.OpacityText
					variant="body1"
					colorVariant="secondary"
					fontWeight="fontWeightMedium"
					fontFamily="IBM Plex Sans"
					mb="0.25rem"
				>
					Sharing Data
				</Styled.OpacityText>
				<SwitchToggle
					isChecked={user?.isSharingData}
					withLabel
					onChange={onUpdateUser}
					color={themeMode === ETheme.DARK ? palette.common.white : undefined}
				/>
				<GlobalTypography.Text
					variant="body1"
					colorVariant="secondary"
					fontFamily="IBM Plex Sans"
					mt="0.25rem"
				>
					You are helping Nighthawk improve its service and keep users safe by sharing your data.
					You can turn this off at any time.
				</GlobalTypography.Text>
			</Styled.SwitchBlock>
		</Grid>
	);
};
