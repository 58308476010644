import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS, FONTS, SPACES } from '../../../../theme';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: ${SPACES.l};
`;

export const Link = styled(NavLink)`
	text-align: left;
	text-decoration: none;
	font-size: ${FONTS.SIZES.m};
	font-weight: ${FONTS.WEIGHTS.bold};
	font-family: ${FONTS.FAMILIES.ibmPlexSans};
	color: ${COLORS.linkViolet};
	position: relative;
	right: 50px;
`;
