/* eslint-disable max-len */
export const PuzzleIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32">
		<defs>
			<clipPath id="clip-path">
				<rect
					id="Rectangle_1054"
					data-name="Rectangle 1054"
					width="34"
					height="32"
					transform="translate(0.182 0)"
					fill="none"
				/>
			</clipPath>
		</defs>
		<g id="Group_2226" data-name="Group 2226" transform="translate(-0.137 0.075)">
			<g
				id="Group_2225"
				data-name="Group 2225"
				transform="translate(-0.045 -0.074)"
				clipPath="url(#clip-path)"
			>
				<path
					id="Path_1314"
					data-name="Path 1314"
					d="M23.227,32.22A1.237,1.237,0,0,0,24.473,31V24.327c.425-3.6,2-2.53,3.845-1.416,4.47,2.69,7.439-4.387,3.827-6.567-2.836-1.714-4.063,1.231-6.139.927-.8-.118-1.361-.967-1.536-2.256V10.192a1.235,1.235,0,0,0-1.244-1.218H17.508c-4.72-.586-.531-3.141-.531-5.518A3.72,3.72,0,0,0,13.035,0C10.859,0,8.861,1.547,8.861,3.456c0,2.242,4.336,4.932-.45,5.518H1.244A1.235,1.235,0,0,0,0,10.192V15c.365,2.053,1.783,2.527,3.6,1.174a4.8,4.8,0,0,1,2.394-.991,3.7,3.7,0,0,1,3.529,3.856c0,2.13-1.58,4.326-3.529,4.326A3.281,3.281,0,0,1,4.1,22.76c0,.164-3.406-3-4.1.87v7.378a1.235,1.235,0,0,0,1.244,1.218q5.756,0,11.505,0a3.278,3.278,0,0,0-.8-2.21c-3.632-5.5,10.5-6.053,6.865.172-.178.307-.356.6-.494.895a2.475,2.475,0,0,0-.264,1.143l5.173,0"
					transform="translate(0.033 0.054)"
					fill="#ababab"
					fillRule="evenodd"
				/>
			</g>
		</g>
	</svg>
);
