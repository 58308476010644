import React from 'react';
import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import Preloader from './index';

const StyledPreloaderStyles = styled(Preloader)<{ theme?: Theme }>`
	color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledPreloader = (style?: React.CSSProperties): JSX.Element => (
	<Grid container alignItems="center" justifyContent="center" minWidth="100%" minHeight="100%">
		<StyledPreloaderStyles style={style} size={128} />
	</Grid>
);

export default StyledPreloader;
