import { EnhancedWithAuthHttpService, HttpFactoryService } from '../modules/common/services';
import { HttpService } from '../modules/common/services/http.service';
import { IAuthResponse, IMailResponse } from '../modules/common/types';
import { ICredentials, IdResponse } from './user.types';

export class UserService {
	constructor(
		private httpService: HttpService,
		private httpEnhancedService: EnhancedWithAuthHttpService
	) {}

	async googleUserAuth(
		email: string,
		username: string,
		google: string
	): Promise<IAuthResponse | void> {
		return this.httpService.post('user-auth/sign-in/google', {
			email,
			username,
			google
		});
	}

	async twitterUserAuth(
		email: string,
		username: string,
		twitter: string
	): Promise<IAuthResponse | void> {
		return this.httpService.post('user-auth/sign-in/twitter', {
			email,
			username,
			twitter
		});
	}

	async userSignUp(email: string, username: string): Promise<IAuthResponse | void> {
		return this.httpService.post('user-auth/sign-up/credentials', {
			email,
			username
		});
	}

	async userSignIn(email: string): Promise<IAuthResponse | void> {
		return this.httpService.post('user-auth/sign-in/credentials', { email });
	}

	async userSendEmail(email: string): Promise<IMailResponse | void> {
		return this.httpService.post('user-auth/send-mail', { email });
	}

	async userNewPassword(data: ICredentials): Promise<IdResponse | void> {
		return this.httpService.put('user-auth/reset-password', data);
	}

	async userGuestAuth() {
		return this.httpService.post('user-auth/sign-in/guest', {});
	}
}

const factory = new HttpFactoryService();
export const authService = new UserService(
	factory.createHttpService(),
	factory.createAuthHttpService()
);
